import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { LoadingDialogComponent } from './loading-dialog/loading-dialog.component';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatProgressSpinnerModule],
  declarations: [ConfirmationDialogComponent, LoadingDialogComponent],
  exports: [MatDialogModule, ConfirmationDialogComponent, LoadingDialogComponent],
  entryComponents: [ConfirmationDialogComponent, LoadingDialogComponent]
})
export class SharedDialogComponentsModule {}
