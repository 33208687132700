import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService, OffsetHttpParameters } from '@shared/utility/api';
import { LoyaltyRewardModel } from '../model/loyalty-reward.model';

@Injectable({ providedIn: 'root' })
export class LoyaltyRewardService extends RestApiService<LoyaltyRewardModel> {
  basePath = '/loyalty/reward/';
  readonly uniqueIdentifier = 'uuid';

  constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }

  loadSnapshot<r extends OffsetHttpParameters>(params?: r) {
    const filteredParams = params ? { params: params.getFilteredParams(['search', 'timezone']) } : undefined;
    return this.http.get(this.getPath('snapshot'), filteredParams);
  }
}
