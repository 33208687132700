import { Injectable } from '@angular/core';
import { ConsumerService as LocationConsumerService } from '@shared/entity/consumer/service';
import {
  ConsumerInterestModel as LocationConsumerInterestModel,
  ConsumerInterestModel
} from '@shared/entity/consumer-interest/model';
import { ErrorModel } from '@shared/model/error/error.model';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as _locationConsumerInterest from './location-consumer-interest.actions';
import { LocationConsumerInterestActionTypes } from './location-consumer-interest.actions';

@Injectable()
export class LocationConsumerInterestEffects {
  @Effect()
  loadLocationConsumerInterests$ = this.actions$.pipe(
    ofType(LocationConsumerInterestActionTypes.LoadLocationConsumerInterests),
    map((action: _locationConsumerInterest.LoadLocationConsumerInterests) => action.payload),
    switchMap(data => {
      return this.locationConsumerService
        .getInterestService(data.consumer)
        .loadCollection(data.params)
        .pipe(
          map(
            collection =>
              new _locationConsumerInterest.LoadLocationConsumerInterestsSuccess({
                consumer: data.consumer,
                interests: collection,
                preserveRecords: data.preserveRecords
              })
          ),
          catchError((err: ErrorModel) => of(new _locationConsumerInterest.LoadLocationConsumerInterestsFailure(err)))
        );
    })
  );

  @Effect()
  loadLocationConsumerInterest$ = this.actions$.pipe(
    ofType(LocationConsumerInterestActionTypes.LoadLocationConsumerInterest),
    map((action: _locationConsumerInterest.LoadLocationConsumerInterest) => action.payload),
    switchMap(data => {
      return this.locationConsumerService
        .getInterestService(data.consumer)
        .load((data as unknown) as LocationConsumerInterestModel)
        .pipe(
          map(
            res =>
              new _locationConsumerInterest.LoadLocationConsumerInterestSuccess({
                consumer: data.consumer,
                interest: res as LocationConsumerInterestModel
              })
          ),
          catchError((err: ErrorModel) => of(new _locationConsumerInterest.LoadLocationConsumerInterestFailure(err)))
        );
    })
  );

  @Effect()
  upsertLocationConsumerInterest$ = this.actions$.pipe(
    ofType(LocationConsumerInterestActionTypes.UpsertLocationConsumerInterest),
    map((action: _locationConsumerInterest.UpsertLocationConsumerInterest) => action.payload),
    switchMap(data => {
      const method = data.interest.uuid ? 'update' : 'insert';
      return this.locationConsumerService
        .getInterestService(data.consumer)
        [method](data.interest)
        .pipe(
          map(
            res =>
              new _locationConsumerInterest.UpsertLocationConsumerInterestSuccess({
                consumer: data.consumer,
                interest: res as ConsumerInterestModel
              })
          ),
          catchError((err: ErrorModel) => of(new _locationConsumerInterest.UpsertLocationConsumerInterestFailure(err)))
        );
    })
  );

  @Effect()
  deleteLocationConsumerInterest$ = this.actions$.pipe(
    ofType(LocationConsumerInterestActionTypes.DeleteLocationConsumerInterest),
    map((action: _locationConsumerInterest.DeleteLocationConsumerInterest) => action.payload),
    switchMap(data => {
      return this.locationConsumerService
        .getInterestService(data.consumer)
        .delete({ uuid: data.id } as LocationConsumerInterestModel)
        .pipe(
          map(
            () =>
              new _locationConsumerInterest.DeleteLocationConsumerInterestSuccess({
                consumer: data.consumer,
                id: data.id
              })
          ),
          catchError((err: ErrorModel) => of(new _locationConsumerInterest.DeleteLocationConsumerInterestFailure(err)))
        );
    })
  );

  constructor(private actions$: Actions, private locationConsumerService: LocationConsumerService) {}
}
