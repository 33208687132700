import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PrinterSelectDialogComponent } from '../component/dialog/printer-select-dialog/printer-select-dialog.component';
import { QzConnectErrorDialogComponent } from '../component/dialog/qz-connect-error-dialog/qz-connect-error-dialog.component';
import { IQzTrayConfigs } from '../model/qz.interface';
import { QzModule } from '../qz.module';
import { QzService } from './qz.service';

const PRINT_WIDTH = 52;
const MAX_COL_A_WIDTH = 40;

@Injectable({
  providedIn: QzModule
})
export class PrintService {
  public defaultPrinter$: Observable<string>;
  public isQzConnected$: Observable<boolean>;
  pad_str: string = '    ';
  printData: any[] = [];

  constructor(protected matDialog: MatDialog, private qz: QzService) {
    this.isQzConnected$ = this.qz.isQzConnected$;
    this.defaultPrinter$ = this.qz.defaultPrinter$;
  }

  public openQzErrorDialog(): MatDialogRef<QzConnectErrorDialogComponent> {
    // check for dialog instance to prevent duplicate dialogs
    if (!this.matDialog.getDialogById('qz-connect-error-dialog')) {
      const dialogRef = this.matDialog.open<QzConnectErrorDialogComponent>(QzConnectErrorDialogComponent, {
        id: 'qz-connect-error-dialog',
        data: { qzService: this.qz }
      });

      dialogRef
        .afterClosed()
        .pipe(filter(connected => !!connected))
        .subscribe(() => this.qz.getDefaultPrinter().catch(this.openPrinterSelectDialog));

      return dialogRef;
    } else {
      return this.matDialog.getDialogById('qz-connect-error-dialog');
    }
  }

  public get defaultPrinterName$(): Observable<string | null> {
    return this.defaultPrinter$;
  }

  public get isQzConnected(): boolean {
    return this.qz.isQzConnected;
  }

  public retryConnection(): Promise<null> {
    return this.qz.initConnection();
  }

  public closeConnection(): Promise<null|Error> {
    return this.qz.closeConnection();
  }

  public openPrinterSelectDialog(): MatDialogRef<PrinterSelectDialogComponent> {
    if (!this.matDialog.getDialogById('qz-select-printer-dialog')) {
      const dialogRef = this.matDialog.open<PrinterSelectDialogComponent>(PrinterSelectDialogComponent, {
        data: { qzService: this.qz },
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(selectedPrinter => {
        selectedPrinter ? this.qz.setDefaultPrinter(selectedPrinter) : this.qz.clearDefaultPrinter();
      });
    } else {
      return this.matDialog.getDialogById('qz-select-printer-dialog');
    }
  }

  public repeat(str, n) {
    let strArr = new Array(n + 1);
    return strArr.join(str);
  }

  private chunkSubstr(str, n) {
    let numChunks = Math.ceil(str.length / n);
    let chunks = new Array(numChunks);

    for (var i = 0, j = 0; i < numChunks; ++i, j += n) {
      chunks[i] = str.substr(j, n);
    }

    return chunks;
  }

  public addTwoColumnChunkA(strA, strB) {
    let strAChunks = this.chunkSubstr(strA, MAX_COL_A_WIDTH);
    let i = 0;
    let padAmount;
    let chunk;

    while (i < strAChunks.length) {
      chunk = strAChunks[i].trim();

      if (i === 0) {
        padAmount = PRINT_WIDTH - (chunk.length + strB.length);
        this.addText(this.pad_str + chunk + this.repeat(' ', padAmount) + strB);
      } else {
        this.addText(this.pad_str + chunk);
      }

      i++;
    }
  }

  private addInit() {
    this.printData.push('\x1B\x40');
    this.printData.push('\x1B\x21\x01');
  }

  public addText(text) {
    this.printData.push(text + '\x0A');
  }

  public addLine() {
    this.printData.push(this.pad_str + this.repeat('-', PRINT_WIDTH) + '\x0A');
  }

  public startBold() {
    this.printData.push('\x1B\x45\x0D');
  }

  public endBold() {
    this.printData.push('\x1B\x45\x0A');
  }

  public startEm() {
    this.printData.push('\x1B\x21\x30');
  }

  public endEm() {
    this.printData.push('\x1B\x21\x0A\x1B\x45\x0A');
  }

  public lineBreak() {
    this.printData.push('\x0A');
  }

  public smallText() {
    this.printData.push('\x1B\x4D\x31');
  }

  public normalText() {
    this.printData.push('\x1B\x4D\x30');
  }

  public leftAlign() {
    this.printData.push('\x1B\x61\x30');
  }

  public rightAlign() {
    this.printData.push('\x1B\x61\x32');
  }

  public centerAlign() {
    this.printData.push('\x1B\x61\x31');
  }

  public cutPaper() {
    this.printData.push('\x1B\x69');
  }

  public sendPulse() {
    this.printData.push('\x10\x14\x01\x00\x05');
  }

  public print(configOverrides: Partial<IQzTrayConfigs> = {}): Promise<null> {
    return new Promise<null>((resolve, reject) => {
      if (!this.isQzConnected) {
        this.retryConnection()
          .then(() => {
            if (this.qz.defaultPrinter) {
              this.sendJob().then(resolve);
            } else {
              reject('Printer is not available');
              this.openPrinterSelectDialog()
                .afterClosed()
                .pipe(filter(printer => !!printer))
                .subscribe(() => this.sendJob(configOverrides));
            }
          })
          .catch(e => this.openQzErrorDialog().afterClosed().subscribe());
      } else {
        if (this.qz.defaultPrinter) {
          this.sendJob().then(resolve);
        } else {
          reject('Printer is not available');
          this.openPrinterSelectDialog()
            .afterClosed()
            .pipe(filter(printer => !!printer))
            .subscribe(() => this.sendJob(configOverrides));
        }
      }
    });
  }

  private sendJob(configOverrides: Partial<IQzTrayConfigs> = {}): Promise<null> {
    return this.qz
      .print(configOverrides, this.printData)
      .then(() => {
        this.reset();
        return null;
      })
      .catch(err => console.log(err));
  }

  reset() {
    this.printData = [];
    this.addInit();
  }

  get() {
    return this.printData;
  }
}
