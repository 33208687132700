export enum DiscountTypeEnum {
  Percent = 1,
  Amount = 2
}

export const PercentDiscountType = {
  id: DiscountTypeEnum.Percent,
  name: 'Percent Off'
};

export const AmountDiscountType = {
  id: DiscountTypeEnum.Amount,
  name: 'Dollars Off'
};

export const AllDiscountTypes = [PercentDiscountType, AmountDiscountType];
