import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import {
  CampaignFormInterface,
  CampaignListInterface,
  CampaignMessageDetailPageDataInterface,
  CampaignUsageGroupItemInterface,
  ListItemInterface,
  PagyCollectionInterface,
  TypedEntityInterface,
  UploadInterface
} from '../interface';

export interface CampaignPageDataInterface {
  locations: TypedEntityInterface[];
}

export interface CampaignComposingPageDataInterface {
  campaign: CampaignFormInterface;
  locations: TypedEntityInterface[];
  connect_types: string[];
  recipients: ListItemInterface[];
  recipient_count: 0;
}

export interface CampaignPreviewPageDataInterface {
  email_location_logo: UploadInterface | null;
  segment_count: number;
  recipients: number;
  segment_total: number;
}

export interface CampaignPerformanceRequestInterface {
  days_before_today: number;
  message_type: string;
  carrier_slug: string;
}

export interface CampaignPerformanceResponseInterface {
  line_chart: LineChartItemInterface[];
  carrier_pies: PieItemInterface[];
  sms_carries: Array<{ name: string, slug: string }>;
  email_carries: Array<{ name: string, slug: string }>;
}

export interface LineChartItemInterface {
  date: string;
  series: Array<{ name: string | Date, value: number }>;
}

export interface PieItemInterface {
  name: string;
  items: Array<{ name: string, value: number }>;
}

@Injectable({
  providedIn: 'root'
})
export class CampaignMessageApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/campaign-messages`;
  }

  public loadCampaignPageData(): Observable<CampaignPageDataInterface> {
    return this.http.get<CampaignPageDataInterface>(`${this.basePath}/page-data`);
  }

  public loadCampaignComposingPageData(uuid: string | null): Observable<CampaignComposingPageDataInterface> {
    return this.http.get<CampaignComposingPageDataInterface>(`${this.basePath}/form/composing-page-data`, { params: { uuid } });
  }

  public loadCampaignPreviewPageData(uuid: string): Observable<CampaignPreviewPageDataInterface> {
    return this.http.get<CampaignPreviewPageDataInterface>(`${this.basePath}/form/preview-page-data`, { params: { uuid } });
  }

  public list(params: any): Observable<PagyCollectionInterface<CampaignListInterface>> {
    return this.http.get<PagyCollectionInterface<CampaignListInterface>>(`${this.basePath}`, { params });
  }

  public upsert(params: any): Observable<any> {
    return this.http.post<null>(`${this.basePath}/form`, params);
  }

  public testCampaignMessage(campaign_uuid: string, employee_uuid: string, campaign_type: string): Observable<any> {
    return this.http.post<null>(`${this.basePath}/form/test-message`, { campaign_uuid, employee_uuid, campaign_type });
  }

  public stopCampaign(uuid: string) {
    return this.http.delete(`${this.basePath}/${uuid}/stop-campaign`);
  }

  public copyCampaign(uuid: string) {
    return this.http.post(`${this.basePath}/${uuid}/copy-campaign`, { uuid });
  }

  public delete(uuid: string) {
    return this.http.delete(`${this.basePath}/${uuid}`);
  }

  public loadDetailPageData(uuid: string): Observable<CampaignMessageDetailPageDataInterface> {
    return this.http.get<CampaignMessageDetailPageDataInterface>(`${this.basePath}/detail/${uuid}/page-data`);
  }

  public loadUsage(startDate: string, endDate: string): Observable<CampaignUsageGroupItemInterface[]> {
    return this.http.get<CampaignUsageGroupItemInterface[]>(`${this.basePath}/detail/usage`, {
      params: {
        startDate,
        endDate
      }
    });
  }

  public loadCampaignPerformance(params: CampaignPerformanceRequestInterface): Observable<CampaignPerformanceResponseInterface> {
    return this.http.get<CampaignPerformanceResponseInterface>(`${this.basePath}/campaign-performance`, { params: { ...params } });
  }
}
