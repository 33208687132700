import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import {
  InventoryBundleDetailInterface,
  InventoryBundleInterface,
  InventoryBundleListInterface, MenuSectionDetailInterface,
  UpsertInventoryBundleRequest
} from '../interface';

@Injectable({
  providedIn: 'root'
})
export class InventoryBundleApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/v2/inventory/bundle`;
  }

  public all(params: any): Observable<InventoryBundleInterface[]> {
    const query = JSON.stringify(params);
    return this.http.get<InventoryBundleInterface[]>(`${this.basePath}/all`, { params: { query: query } });
  }

  public listData(): Observable<InventoryBundleListInterface> {
    return this.http.get<InventoryBundleListInterface>(`${this.basePath}/list-data`);
  }

  public get(uuid: string): Observable<InventoryBundleDetailInterface> {
    return this.http.get<InventoryBundleDetailInterface>(`${this.basePath}/${uuid}`);
  }

  public setOrder(uuids: string[]): Observable<InventoryBundleInterface[]> {
    return this.http.post<InventoryBundleInterface[]>(`${this.basePath}/set-order`, { uuids });
  }

  public upsert(request: UpsertInventoryBundleRequest): Observable<InventoryBundleDetailInterface> {
    if (request.uuid) {
      return this.http.post<InventoryBundleDetailInterface>(`${this.basePath}/${request.uuid}`, request);
    }
    return this.http.post<InventoryBundleDetailInterface>(`${this.basePath}/`, request);
  }

  public delete(uuid: string): Observable<void> {
    return this.http.delete<void>(`${this.basePath}`, { params: { uuid: uuid } });
  }
}
