export const environment = {
  prod: true,
  staging: false,
  develop: false,
  intercomId: 'n15x3ile',
  logRocketId: 'f7sjha/myblackbird-app-production',
  url: 'https://app.myblackbird.com',
  apiUrl: 'https://maeve.myblackbird.com',
  doloresUrl: 'https://dolores.myblackbird.com',
  collectUrl: 'https://static-file-server.myblackbird.com/collect.min.js',
  brandMenuUrl: 'https://brands.menu',
  experienceAppUrl: 'https://checkin.myblackbird.com',
  dispensaryBaseUrl: 'https://dispensary.menu',
  dispensaryUrl: 'dispensary.menu',
  bbgoBaseUrl: 'https://blackbirdgo.com',
  bbgoShopUrl: 'https://shop.blackbirdgo.com',
  googleAnalyticsTrackingId: 'UA-72318114-7',
  googleTagManager: {
    id: 'GTM-TZM3RFV',
    options: {
      gtm_auth: '',
      gtm_preview: '',
      gtm_cookies_win: ''
    }
  },
  releaseStage: 'prod'
};
