import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable, of } from 'rxjs';
import {
  DiscountDetailInterface,
  DiscountInterface,
  ListItemInterface,
  OrderDiscountInterface,
  PagyCollectionInterface,
  TypedEntityInterface
} from '../interface';

export interface DiscountListDataInterface {
  discount_stack_rule: 'unlimied' | 'max_per_item' | 'max_per_order';
  locations: TypedEntityInterface[];
  me: TypedEntityInterface;
}

export interface DiscountFormDataInterface {
  can_edit: boolean;
  me: TypedEntityInterface;
  discount: DiscountDetailInterface | null;
  locations: TypedEntityInterface[];
}

export interface LocationProductGroupInterface {
  location: TypedEntityInterface;
  products: TypedEntityInterface[];
}

export interface LocationInventoryGroupInterface {
  location: TypedEntityInterface;
  inventory: TypedEntityInterface[];
}

@Injectable({
  providedIn: 'root'
})
export class DiscountApiService {
  baseUrl: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.baseUrl = `${this.config.baseUrl}/v2/discounts`;
  }

  public listData(): Observable<DiscountListDataInterface> {
    return this.http.get<DiscountListDataInterface>(`${this.baseUrl}/list-data`);
  }

  public list(params: any): Observable<PagyCollectionInterface<DiscountInterface>> {
    return this.http.get<PagyCollectionInterface<DiscountInterface>>(`${this.baseUrl}`, { params });
  }

  public formData(uuid: string | null): Observable<DiscountFormDataInterface> {
    return this.http.get<DiscountFormDataInterface>(`${this.baseUrl}/form-data`, { params: { uuid: uuid ? uuid : '' } });
  }

  public show(uuid: string | null): Observable<DiscountDetailInterface> {
    if (!uuid) {
      return of(null);
    }
    return this.http.get<DiscountDetailInterface>(`${this.baseUrl}/${uuid}`);
  }

  resolveFormProducts(params: { productUuids?: string[], discountUuid?: string }): Observable<LocationProductGroupInterface[]> {
    return this.http.post<LocationProductGroupInterface[]>(`${this.baseUrl}/resolve-form-products`, { ...params });
  }

  resolveFormInventory(params: { inventoryUuids?: string[], discountUuid?: string }): Observable<LocationInventoryGroupInterface[]> {
    return this.http.post<LocationInventoryGroupInterface[]>(`${this.baseUrl}/resolve-form-inventory`, { ...params });
  }

  public upsert(params: any): Observable<DiscountDetailInterface> {
    return this.http.post<DiscountDetailInterface>(`${this.baseUrl}`, params);
  }

  public delete(uuid: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${uuid}`);
  }

  public updateDiscountStackRule(discount_stack_rule: string): Observable<DiscountDetailInterface> {
    return this.http.patch<DiscountDetailInterface>(`${this.baseUrl}/discount-stack-rule`, { discount_stack_rule });
  }

  public dropdown(params: { object_type?: number }): Observable<ListItemInterface[]> {
    return this.http.get<ListItemInterface[]>(`${this.baseUrl}/dropdown`, { params });
  }

  public orderOptions(params: { start?: string, end?: string }): Observable<OrderDiscountInterface[]> {
    return this.http.get<OrderDiscountInterface[]>(`${this.baseUrl}/order-options`, { params });
  }
}
