import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import { CampaignMsMessageInterface, PagyCollectionInterface } from '../interface';

export interface CampaignMsMessageRequestInterface {
  uuid: string;
  status: number;
  carrier_name: string;
}

@Injectable({
  providedIn: 'root'
})
export class CampaignMsMessageApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/campaign-messages`;
  }

  public list(params: CampaignMsMessageRequestInterface): Observable<PagyCollectionInterface<CampaignMsMessageInterface>> {
    return this.http.get<PagyCollectionInterface<CampaignMsMessageInterface>>(`${this.basePath}/detail/ms-messages`, { params: { ...params } });
  }
}
