import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { RouterModule } from '@angular/router';
import { DatepickerModule } from '@frontend/component-lib';
import { ImageZoomModule } from '@shared/image-zoom';
import { LoaderModule as NewLoaderModule } from '@myblackbird/shared/component/loader';
import { InventoryTransferModule } from '@myblackbird/shared/inventory/transfer';
import { InventoryUploadStateModule } from '@shared/entity/inventory-upload/state';
import { PhoneInputModule } from '@shared/material-inputs/phone-input';
import { SelectSearchModule as FrontendSelectSearchModule } from '@shared/material-inputs/select-search';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MomentModule } from 'ngx-moment';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { StatusColorModule } from '../directive/status-color/status-color.module';
import { EntityStatusPipeModule } from '../pipe/status/_entity-status.module';
import { LoaderModule } from './shared/components/loader/loader.module';
import { SelectSearchModule } from './shared/components/select-search/select-search.module';
import { CustomDirectivesModule } from './shared/directives/directives.module';
import { CustomPipesModule } from './shared/pipes/pipes.module';
import { SharedComponentsModule } from './shared/shared-components.module';


// TODO verify these modules are all being referenced in their smaller counterparts before removing them at the app level
@NgModule({
  declarations: [],
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedComponentsModule,
    LoaderModule,
    NewLoaderModule,
    SelectSearchModule,
    VirtualScrollerModule,
    MomentModule,
    CustomPipesModule,
    CustomDirectivesModule,
    StatusColorModule,
    ImageZoomModule,
    MatDatepickerModule,
    MatProgressBarModule,
    EntityStatusPipeModule,
    MatDialogModule,
    InventoryUploadStateModule,
    InventoryTransferModule,
    FrontendSelectSearchModule,
    MatFormFieldModule,
    MatRadioModule,
    MatInputModule,
    MatButtonModule,
    PhoneInputModule,
    DatepickerModule,
    NgxMaterialTimepickerModule
  ],
})
export class ModalModule {}
