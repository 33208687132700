<ng-container *ngIf="(for?.touched && for?.invalid) || validationErrors" [ngSwitch]="error">
  <ng-container *ngSwitchCase="'emailPatternMatch'">Please enter a valid email</ng-container>
  <ng-container *ngSwitchCase="'email'">Please enter a valid email</ng-container>
  <ng-container *ngSwitchCase="'fileExtension'">Invalid file extenstion. Must be one of gif, png, jpeg, or jpg</ng-container>
  <ng-container *ngSwitchCase="'fileSize'">File exceeds maximum size of 50MB</ng-container>
  <ng-container *ngSwitchCase="'futureDateAndTime'">Send time cannot be in the past</ng-container>
  <ng-container *ngSwitchCase="'greaterThanZero'">Must be greater than zero</ng-container>
  <ng-container *ngSwitchCase="'invalidCharacters'">Password contains an invalid symbol, please use one of the following !@#$%&*(){{'{}'}}-+</ng-container>
  <ng-container *ngSwitchCase="'invalidPhoneNumber'">Phone number is invalid</ng-container>
  <ng-container *ngSwitchCase="'minlength'">Password must be at least 8 characters</ng-container>
  <ng-container *ngSwitchCase="'missingCharacters'">Missing one or more required characters</ng-container>
  <ng-container *ngSwitchCase="'missingLowercaseLetter'">Password must contain lowercase letter</ng-container>
  <ng-container *ngSwitchCase="'missingNumber'">Password must contain number</ng-container>
  <ng-container *ngSwitchCase="'missingUppercaseLetter'">Password must contain uppercase letter</ng-container>
  <ng-container *ngSwitchCase="'missingSymbol'">Password must contain a symbol !@#$%&*(){{'{}'}}-+</ng-container>
  <ng-container *ngSwitchCase="'orderNumber'">Invalid order number</ng-container>
  <ng-container *ngSwitchCase="'over18'">Must be over 18 years old</ng-container>
  <ng-container *ngSwitchCase="'passwordMatch'">Passwords do not match</ng-container>
  <ng-container *ngSwitchCase="'requireAccessControl'">Employee accounts must have at least one access level</ng-container>
  <ng-container *ngSwitchCase="'required'">This field is required</ng-container>
  <ng-container *ngSwitchCase="'requireLogo'">Logo upload required</ng-container>
  <ng-container *ngSwitchCase="'requirePoints'">Points quantity required</ng-container>
  <!--
    Hiding this one because showing it might get annoying
    <ng-container *ngSwitchCase="'smsMessageBodyPresent'">Please enter a message to send to your customers</ng-container>
  -->
  <ng-container *ngSwitchCase="'smsMessageDoesNotContainBannedWords'">Carriers have explicitly banned terms used in this message. Please replace them.</ng-container>
  <ng-container *ngSwitchCase="'tcpaCompliantTime'">Send time must be between 8 a.m. and 7:30 p.m. according to TCPA regulations. Please send or schedule later</ng-container>
  <ng-container *ngSwitchCase="'uniqueTriggerAction'">There is already a point trigger for this action</ng-container>
  <ng-container *ngSwitchCase="'weburlPatternMatch'">Please enter a valid URL</ng-container>
</ng-container>
