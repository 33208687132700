import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { DateRangeFieldInterface } from '@shared/model/field/date-range/date-range.interface';
import { OffsetHttpParameters } from '@shared/utility/api';
import { ConsumerSourceEnum } from '../../consumer/enumeration';

export enum CheckinOrderByEnum {
  CREATED_AT = 'created_at',
  RANDOM = 'random'
}

type CheckinOrderByTypes = 'created_at' | 'random';

export class CheckinParameters extends OffsetHttpParameters {
  protected status: number[];

  protected searchJSON: Partial<{
    has_station: boolean;
    drawing: { distinct: { uuid: string } };
    event: { distinct: { uuid: string } };
    station: { distinct: { uuid: string } };
    license_class: { distinct: { uuid: string } };
    loyalty_tier: { distinct: { uuid: string } };
    location: { distinct: { uuid: string } };
    consumer: { source: ConsumerSourceEnum };
    created_at: DateRangeFieldInterface;
    uuid: { not_in: string[] };
    timezone: string;
  }> = {};

  constructor(params?: Partial<CheckinParameters>) {
    super();
    if (params) Object.assign(this, params);
    if (!params || !params.getOrderBy) this.setOrderBy(CheckinOrderByEnum.CREATED_AT).setOrder('DESC');
  }

  setOrderBy(orderBy?: CheckinOrderByTypes): this {
    return super.setOrderBy(orderBy);
  }

  setWhereHasStation(has_station?: boolean): this {
    this.searchJSON = { ...this.searchJSON, has_station };
    return this;
  }

  setWhereDrawing(drawing?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, drawing: drawing ? { distinct: { uuid: drawing.uuid } } : undefined };
    return this;
  }

  setWhereEvent(event?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, event: event ? { distinct: { uuid: event.uuid } } : undefined };
    return this;
  }

  setWhereStation(station?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, station: station ? { distinct: { uuid: station.uuid } } : undefined };
    return this;
  }

  setWhereLicenseClass(licenseClass?: TypedEntityInterface): this {
    this.searchJSON = {
      ...this.searchJSON,
      license_class: licenseClass ? { distinct: { uuid: licenseClass.uuid } } : undefined
    };
    return this;
  }

  setWhereLoyaltyTier(loyaltyTier?: TypedEntityInterface): this {
    this.searchJSON = {
      ...this.searchJSON,
      loyalty_tier: loyaltyTier ? { distinct: { uuid: loyaltyTier.uuid } } : undefined
    };
    return this;
  }

  setWhereLocation(location?: TypedEntityInterface): this {
    this.searchJSON = { ...this.searchJSON, location: location ? { distinct: { uuid: location.uuid } } : undefined };
    return this;
  }

  setWhereConsumerSource(source?: ConsumerSourceEnum) {
    this.searchJSON = { ...this.searchJSON, consumer: source ? { source } : undefined };
    return this;
  }

  setWhereCreatedAt(created_at?: DateRangeFieldInterface) {
    this.searchJSON = { ...this.searchJSON, created_at };
    return this;
  }

  setWhereUuidNotIn(uuids?: string[]) {
    this.searchJSON = { ...this.searchJSON, uuid: uuids && uuids.length ? { not_in: uuids } : undefined };
    return this;
  }

  setWhereTimezone(timezone?: string) {
    this.searchJSON = { ...this.searchJSON, timezone };
    return this;
  }

  setWhereStatusIn(statuses?: number[]) {
    this.status = statuses && statuses.length > 0 ? statuses : undefined;
    return this;
  }

  getParams(): { [p: string]: any } {
    const params = {
      ...super.getParams(),
      'status[]': this.status,
      search: JSON.stringify(this.searchJSON)
    };
    return this.removeUndefinedValues(params);
  }
}
