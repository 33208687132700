import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  AnalyticsCartProductInterface,
  AnalyticsInterface,
  AnalyticsProductDetailInterface,
  AnalyticsProductImpressionInterface,
  AnalyticsPromoClickInterface,
  AnalyticsPromoImpressionInterface,
  AnalyticsProviderEnum,
  AnalyticsSubmitCartInterface
} from '../interface/analytics-tracker.interface';
import { GoogleTagManagerCredentialsInterface } from '../interface/gtm-options.interface';
import { GoogleAnalytics4 } from './google/google-analytics4';
import { GoogleTagManager } from './google/google-tag-manager';
import { GoogleUniversalAnalytics } from './google/google-universal-analytics';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService implements AnalyticsInterface {
  trackingInstances: (GoogleUniversalAnalytics | GoogleAnalytics4 | GoogleTagManager)[] = [];
  isBrowser: boolean;
  isEmbedded: boolean;

  constructor(@Inject(PLATFORM_ID) platformId: object) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isEmbedded = this.isBrowser && window.self !== window.top;
  }

  initializeTracker(
    analyticsProvider: AnalyticsProviderEnum,
    credentials: string | GoogleTagManagerCredentialsInterface,
    scope: string = ''
  ) {
    if (!this.isBrowser) return;

    switch (analyticsProvider) {
      case AnalyticsProviderEnum.GTM:
        this.trackingInstances.push(new GoogleTagManager(<GoogleTagManagerCredentialsInterface>credentials));
        break;

      case AnalyticsProviderEnum.GA:
        this.trackingInstances.push(
          (<string>credentials).includes('UA')
            ? new GoogleUniversalAnalytics(<string>credentials, scope)
            : new GoogleAnalytics4(<string>credentials)
        );
        break;
    }
  }

  trackPageView(url: string): void {
    this.notifyParentWindow('pageView', { url });
    this.trackingInstances.forEach(instance => instance.trackPageView(url));
  }

  addProductImpressions(impressions: AnalyticsProductImpressionInterface[], listName?: string): void {
    this.notifyParentWindow('productImpressions', { impressions, listName });
    this.trackingInstances.forEach(instance => instance.addProductImpressions(impressions, listName));
  }

  addPromoImpressions(promos: AnalyticsPromoImpressionInterface[]): void {
    this.notifyParentWindow('promoImpressions', promos);
    this.trackingInstances.forEach(instance => instance.addPromoImpressions(promos));
  }

  addPromoClick(promo: AnalyticsPromoClickInterface): void {
    this.notifyParentWindow('promoClick', promo);
    this.trackingInstances.forEach(instance => instance.addPromoClick(promo));
  }

  viewProductDetail(product: AnalyticsProductDetailInterface): void {
    this.notifyParentWindow('viewProductDetail', product);
    this.trackingInstances.forEach(instance => instance.viewProductDetail(product));
  }

  addToCart(products: AnalyticsCartProductInterface | AnalyticsCartProductInterface[]): void {
    this.notifyParentWindow('addToCart', products);
    this.trackingInstances.forEach(instance => instance.addToCart(products));
  }

  removeFromCart(products: AnalyticsCartProductInterface | AnalyticsCartProductInterface[]): void {
    this.notifyParentWindow('removeFromCart', products);
    this.trackingInstances.forEach(instance => instance.removeFromCart(products));
  }

  checkout(products: AnalyticsCartProductInterface[]): void {
    this.notifyParentWindow('checkout', products);
    this.trackingInstances.forEach(instance => instance.checkout(products));
  }

  purchase(cart: AnalyticsSubmitCartInterface): void {
    const getOrderNumber: (order: { uuid: string; created_at:string }) => string = (order: { uuid: string; created_at: string }) => {
      const randomInt = order.uuid.slice(-2).padStart(3, '0');
      const timestamp = new Date(order.created_at).getTime() / 1000;
      return timestamp + randomInt;
    }

    // stripping out customer PII when emitting externally for privacy reasons
    this.notifyParentWindow('purchase', { ...cart, customer : undefined, order_number: getOrderNumber(cart) });
    this.trackingInstances.forEach(instance => instance.purchase(cart));
  }

  identifyUser(id: string): void {
    this.notifyParentWindow('userId', id);
    this.trackingInstances.forEach(instance => instance.identifyUser(id));
  }

  private notifyParentWindow(eventName: string, data: any) {
    if (!this.isEmbedded) return;
    window.top!.postMessage({ blackbirdAnalytics: { [eventName]: data } }, '*');
  }
}
