import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CreateLocationPostInterface,
  LocationPostInterface,
  UpdateLocationPostInterface
} from '@myblackbird/api/interface';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LocationPostService {
  constructor(private config: ApiConfig, private http: HttpClient) {}

  public create(post: CreateLocationPostInterface): Observable<LocationPostInterface> {
    return this.http.post<LocationPostInterface>(`${this.config.baseUrl}/v2/location/post`, post);
  }

  public update(post: UpdateLocationPostInterface): Observable<LocationPostInterface> {
    return this.http.patch<LocationPostInterface>(`${this.config.baseUrl}/v2/location/post/${post.uuid}`, post);
  }

  public deleteImage(location_post_uuid: string): Observable<LocationPostInterface> {
    return this.http.delete<LocationPostInterface>(
      `${this.config.baseUrl}/v2/location/post/${location_post_uuid}/image`
    );
  }

  public delete(location_post_uuid: string): Observable<{ success: true }> {
    return this.http.delete<{ success: true }>(`${this.config.baseUrl}/v2/location/post/${location_post_uuid}`);
  }
}
