import { AbstractPolicy } from '../abstract.policy';
import { PolicyPermissions } from '../policy.permissions';

export class TransportationAddonPolicy extends AbstractPolicy {

  constructor(acl) {
    super(acl);

    if (this.isLocationAcl) {
      this.managerPermissions = [
        PolicyPermissions.WRITE_DELIVERY,
        PolicyPermissions.ADJUST_INVENTORY_SHIPPING_ZONE
      ];

      this.adminPermissions = [
        ...this.managerPermissions,
      ];
    }
  }

}
