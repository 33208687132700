import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { WINDOW } from '@shared/window';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportingGuard implements CanActivate {
  constructor(
    @Inject(WINDOW) private _window: Window,
    @Inject(ApiConfig) private apiConfig: ApiConfig,
    private http: HttpClient
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.http.get(`${this.apiConfig.baseUrl}/reporting/start`).pipe(
      tap((res: { url: string }) => (this._window.open(res.url, '_self'))),
      map(() => false),
      catchError(() => of(false))
    );
  }
}
