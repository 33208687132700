import { Action } from '@ngrx/store';
import { CollectionInterface } from '@shared/model/collection/collection.interface';
import { AddressModel } from '@shared/model/entity/address/address.model';
import { ErrorModel } from '@shared/model/error/error.model';
import { ConsumerModel, IConsumerData } from '../../model/consumer.model';
import { ConsumerParameters } from '../../service/consumer.parameters';

export enum ConsumerActionTypes {
  ClearConsumerState = '[Consumer] Clear Consumer State',
  SelectConsumer = '[Consumer] Select Consumer',
  DeselectConsumer = '[Consumer] Deselect Consumer',

  LoadConsumerCollection = '[Consumer] Load Consumer Collection',
  LoadConsumerCollectionSuccess = '[Consumer] Load Consumer Collection Success',
  LoadConsumerCollectionFailure = '[Consumer] Load Consumer Collection Failure',

  LoadConsumer = '[Consumer] Load  Consumer',
  LoadConsumerSuccess = '[Consumer] Load Consumer Success',
  LoadConsumerFailure = '[Consumer] Load Consumer Failure',

  UpsertConsumer = '[Consumer] Upsert Consumer',
  UpsertConsumerSuccess = '[Consumer] Upsert Consumer Success',
  UpsertConsumerFailure = '[Consumer] Upsert Consumer Failure',

  UpsertRelatedConsumerData = '[Consumer] Upsert Related Consumer Data',
  UpsertRelatedConsumerDataSuccess = '[Consumer] Upsert Related Consumer Data Success',
  UpsertRelatedConsumerDataFailure = '[Consumer] Upsert Related Consumer Data FAILURE',

  DeleteConsumer = '[Consumer] Delete  Consumer',
  DeleteConsumerSuccess = '[Consumer] Delete Consumer Success',
  DeleteConsumerFailure = '[Consumer] Delete Consumer Failure'
}

export class ClearConsumerState implements Action {
  readonly type = ConsumerActionTypes.ClearConsumerState;
}

export class SelectConsumer implements Action {
  readonly type = ConsumerActionTypes.SelectConsumer;
  constructor(public payload: { uuid: string }) {}
}

export class DeselectConsumer implements Action {
  readonly type = ConsumerActionTypes.DeselectConsumer;
}

export class LoadConsumerCollection implements Action {
  readonly type = ConsumerActionTypes.LoadConsumerCollection;

  constructor(public payload: { parameters: ConsumerParameters; preserveExistingRecords?: boolean }) {}
}

export class LoadConsumerCollectionSuccess implements Action {
  readonly type = ConsumerActionTypes.LoadConsumerCollectionSuccess;

  constructor(public payload: { collection: CollectionInterface<IConsumerData>; preserveExistingRecords?: boolean }) {}
}

export class LoadConsumerCollectionFailure implements Action {
  readonly type = ConsumerActionTypes.LoadConsumerCollectionFailure;

  constructor(public payload: { error: ErrorModel }) {}
}

export class LoadConsumer implements Action {
  readonly type = ConsumerActionTypes.LoadConsumer;

  constructor(public payload: { consumer: { uuid: string }; eagerLoadPendingRewards?: boolean }) {}
}

export class LoadConsumerSuccess implements Action {
  readonly type = ConsumerActionTypes.LoadConsumerSuccess;

  constructor(public payload: { consumer: ConsumerModel }) {
  }
}

export class LoadConsumerFailure implements Action {
  readonly type = ConsumerActionTypes.LoadConsumerFailure;

  constructor(public payload: { error: ErrorModel }) {
  }
}

export class UpsertConsumer implements Action {
  readonly type = ConsumerActionTypes.UpsertConsumer;

  constructor(
    public payload: {
      consumer: IConsumerData,
      deliveryAddress?: AddressModel,
      homeAddress?: AddressModel,
      uploads?: FormData[]
    }
  ) {}
}

export class UpsertConsumerSuccess implements Action {
  readonly type = ConsumerActionTypes.UpsertConsumerSuccess;

  constructor(public payload: { consumer: ConsumerModel }) {}
}

export class UpsertConsumerFailure implements Action {
  readonly type = ConsumerActionTypes.UpsertConsumerFailure;

  constructor(public payload: { error: ErrorModel }) {}
}

export class UpsertRelatedConsumerData implements Action {
  readonly type = ConsumerActionTypes.UpsertRelatedConsumerData;

  constructor(
    public payload: {
      consumer: IConsumerData,
      deliveryAddress?: AddressModel,
      homeAddress?: AddressModel,
      uploads?: FormData[]
    }
  ) {}
}

export class UpsertRelatedConsumerDataSuccess implements Action {
  readonly type = ConsumerActionTypes.UpsertRelatedConsumerDataSuccess;

  constructor(
    public payload: {
      consumer: ConsumerModel,
      deliveryAddress?: AddressModel,
      homeAddress?: AddressModel,
      uploads?: FormData[]
    }
  ) {}
}

export class UpsertRelatedConsumerDataFailure implements Action {
  readonly type = ConsumerActionTypes.UpsertRelatedConsumerDataFailure;

  constructor(public payload: { error: ErrorModel }) {}
}

export class DeleteConsumer implements Action {
  readonly type = ConsumerActionTypes.DeleteConsumer;

  constructor(public payload: { consumer: { uuid: string } }) {}
}

export class DeleteConsumerSuccess implements Action {
  readonly type = ConsumerActionTypes.DeleteConsumerSuccess;

  constructor(public payload: { consumer: { uuid: string } }) {}
}

export class DeleteConsumerFailure implements Action {
  readonly type = ConsumerActionTypes.DeleteConsumerFailure;

  constructor(public payload: { error: ErrorModel }) {}
}

export type ConsumerActions =
  | ClearConsumerState
  | SelectConsumer
  | DeselectConsumer
  | LoadConsumerCollection
  | LoadConsumerCollectionSuccess
  | LoadConsumerCollectionFailure
  | LoadConsumer
  | LoadConsumerSuccess
  | LoadConsumerFailure
  | UpsertConsumer
  | UpsertConsumerSuccess
  | UpsertConsumerFailure
  | UpsertRelatedConsumerData
  | UpsertRelatedConsumerDataSuccess
  | UpsertRelatedConsumerDataFailure
  | DeleteConsumer
  | DeleteConsumerSuccess
  | DeleteConsumerFailure;
