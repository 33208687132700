import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable, of } from 'rxjs';
import { CollectEmbedSettingsInterface, PagyCollectionInterface, TypedEntityInterface } from '../interface';

export interface PageDataResponseInterface {
  locations: TypedEntityInterface[];
}

@Injectable({
  providedIn: 'root'
})
export class CollectEmbedApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}`;
  }

  public list(params: any): Observable<PagyCollectionInterface<CollectEmbedSettingsInterface>> {
    return this.http.get<PagyCollectionInterface<CollectEmbedSettingsInterface>>(`${this.basePath}/collect-embeds`, { params: { ...params } });
  }

  public pageData(): Observable<PageDataResponseInterface> {
    return this.http.get<PageDataResponseInterface>(`${this.config.baseUrl}/collect-embeds/page-data`);
  }

  public show(uuid: string | null): Observable<CollectEmbedSettingsInterface | null> {
    if (!uuid) {
      return of(null);
    }
    return this.http.get<CollectEmbedSettingsInterface>(`${this.basePath}/collect-embeds/${uuid}`);
  }

  public showPublic(uuid: string): Observable<CollectEmbedSettingsInterface> {
    return this.http.get<CollectEmbedSettingsInterface>(`${this.basePath}/collect-embeds/${uuid}/showPublic`);
  }

  public createLocationConsumer(collectEmbedUuid: string, locationUuid: string, params: any): Observable<void> {
    return this.http.post<void>(`${this.basePath}/collect-embeds/${collectEmbedUuid}/location/${locationUuid}/consumer`, params);
  }

  public create(params: any): Observable<CollectEmbedSettingsInterface> {
    return this.http.post<CollectEmbedSettingsInterface>(`${this.basePath}/collect-embeds/`, params);
  }

  public upsert(params: any): Observable<CollectEmbedSettingsInterface> {
    if (params.uuid) {
      return this.update(params);
    }
    return this.create(params);
  }

  public update(params: any): Observable<CollectEmbedSettingsInterface> {
    return this.http.patch<CollectEmbedSettingsInterface>(`${this.basePath}/collect-embeds/${params.uuid}`, params);
  }

  public delete(uuid: string): Observable<void> {
    return this.http.delete<void>(`${this.basePath}/collect-embeds/${uuid}`);
  }
}
