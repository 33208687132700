/**
 * THIS FILE IS AUTOGENERATED
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { InventoryEffects } from '../effects/inventory.effects';
import * as fromInventory from '../reducers/inventory.reducer';
import { INVENTORY_KEY } from '../selectors/inventory.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(INVENTORY_KEY, fromInventory.reducer),
    EffectsModule.forFeature([InventoryEffects])
  ]
})
export class InventoryStateModule {}
