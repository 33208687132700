import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import { EmbedSettingInterface, PagyCollectionInterface } from '../interface';

export interface PageDataResponseInterface {
  is_location: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class EmbedSettingApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/v2/embed_setting`;
  }

  public list<T>(): Observable<PagyCollectionInterface<EmbedSettingInterface<T>>> {
    return this.http.get<PagyCollectionInterface<EmbedSettingInterface<T>>>(this.basePath);
  }

  public create<R>(data: EmbedSettingInterface<R>): Observable<EmbedSettingInterface<R>> {
    return this.http.post<EmbedSettingInterface<R>>(this.basePath, data);
  }

  public update<R>(data: EmbedSettingInterface<R>): Observable<EmbedSettingInterface<R>> {
    return this.http.patch<EmbedSettingInterface<R>>(`${this.basePath}/${data.uuid}`, data);
  }

  public upsert<R>(data: EmbedSettingInterface<R>): Observable<EmbedSettingInterface<R>> {
    return data.uuid ? this.update(data) : this.create(data);
  }

  public delete(uuid: string): Observable<void> {
    return this.http.delete<void>(`${this.basePath}/${uuid}`);
  }

  public pageData(): Observable<PageDataResponseInterface> {
    return this.http.get<PageDataResponseInterface>(`${this.basePath}/page-data`);
  }
}
