import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { PipesModule } from '@shared/pipes';
import { ClipboardModule } from 'ngx-clipboard';
import { ColorPickerModule } from 'ngx-color-picker';
import { AlertModule } from './alert/alert.module';
import { EntityStatusLabelComponent } from './entity-status-label/entity-status-label.component';
import { ThumbnailComponent } from './images/thumbnail/thumbnail.component';
import { CodeHighlightService } from './service/code-highlight.service';
import { SubNavigationTabsModule } from './sub-navigation-tabs/sub-navigation-tabs.module';
import { TabsModule } from './tabs/tabs.module';
import { TimeSelectModule } from './time-select/time-select.module';
import { LocationsDrawerModule } from './locations-drawer/locations-drawer.module';
import { MsVariableModule } from './ms-variable/ms-variable.module';

@NgModule({
  imports: [
    AlertModule,
    ClipboardModule,
    ColorPickerModule,
    CommonModule,
    FormsModule,
    MatTabsModule,
    PipesModule,
    ReactiveFormsModule,
    RouterModule,
    SubNavigationTabsModule,
    TabsModule,
    TimeSelectModule,
    LocationsDrawerModule,
    MsVariableModule
  ],
  declarations: [EntityStatusLabelComponent, ThumbnailComponent],
  exports: [EntityStatusLabelComponent, ThumbnailComponent, SubNavigationTabsModule],
  providers: [CodeHighlightService]
})
export class ComponentLibModule {}
