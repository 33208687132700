import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@myblackbird/auth';
import { MessagePollingService } from '@myblackbird/shared/service/message-polling';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SmsConversationModel, SmsMessageModel, SmsSubscriberModel } from '@shared/entity/sms-conversation/model';
import { PolicyPermissions } from '@shared/policy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

interface MessagingCenterDialogDataInterface {
  openedFromCart: boolean;
  location: { logo: { url: string } | null };
}

@Component({
  selector: 'frontend-messaging-center',
  templateUrl: './messaging-center-dialog.component.html',
  styleUrls: ['./messaging-center-dialog.component.scss']
})
export class MessagingCenterDialogComponent implements OnInit {
  searchMessagesCtrl: FormControl;
  searchConsumersCtrl: FormControl;

  selectedConversationMessages: SmsMessageModel[];
  conversationConsumer: SmsSubscriberModel;
  openedFromCart: boolean;
  accountDisabled: boolean;
  location: { logo: { url: string } | null };
  displayName: string;

  newMessageSearch: boolean = false;

  conversations: SmsSubscriberModel[];
  consumerSearchInput: string;

  permissions = PolicyPermissions;

  trackByFn = (index: number, item: { sid: string }) => (item ? item.sid : null);

  constructor(
    private matDialogRef: MatDialogRef<MessagingCenterDialogComponent>,
    private _store: Store<unknown>,
    private action$: Actions,
    private messagePollingService: MessagePollingService,
    authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: MessagingCenterDialogDataInterface
  ) {
    if (data) {
      const { openedFromCart } = data;
      this.openedFromCart = openedFromCart;
    }
    this.location = authService.currentLocationSnapshot;
  }

  ngOnInit(): void {
    this.matDialogRef.addPanelClass(['messaging-center']);
    this.matDialogRef.updatePosition({
      top: '0',
      left: '0',
      bottom: '0'
    });
    this.matDialogRef.updateSize('100vh', '100vh');

    this.searchMessagesCtrl = new FormControl();
    this.searchConsumersCtrl = new FormControl();

    this.initSubscriptions();
  }

  initSubscriptions() {
    this.searchMessagesCtrl.valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(query => {
      this.messagePollingService.selectConversation(null, false);
      this.messagePollingService.searchConversation(query);
    });

    this.searchConsumersCtrl.valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(query => {
      const phoneNumber = this.isPhoneNumber(query);
      this.consumerSearchInput = phoneNumber ? phoneNumber : query;
    });
  }

  isPhoneNumber(query: string) {
    const phoneNumber = query ? query.replace(/[()-\s+]/g, '') : null;
    if (!isNaN(parseInt(phoneNumber, 10))) return phoneNumber;
    else return null;
  }

  onChatSelected(conversation: SmsSubscriberModel): void {
    this.messagePollingService.selectConversation(conversation.uuid, false);
  }

  updateData(conversations: SmsConversationModel) {
    this.accountDisabled = conversations.account_disabled;
    if (conversations.results && conversations.results.length) {
      this.conversations = conversations.results;
      this.conversationConsumer = conversations.conversation_consumer;
      this.selectedConversationMessages = conversations.conversation_messages;
    } else {
      this.conversationConsumer = conversations.conversation_consumer;
      this.selectedConversationMessages = [];
      this.conversations = conversations.conversation_consumer ? [conversations.conversation_consumer] : null;
    }
  }

  chatArchived() {
    this.newMessageSearch = false;
    this.resetMessagePolling();

    if (this.openedFromCart) {
      this.matDialogRef.close();
    }
  }

  onNewMessageClick() {
    this.newMessageSearch = true;
    this.resetMessagePolling();
  }

  backToMessages() {
    this.newMessageSearch = false;
    this.resetMessagePolling();
  }

  resetMessagePolling() {
    this.searchMessagesCtrl.reset();
    this.searchConsumersCtrl.reset();
    this.messagePollingService.searchConversation(null);
    this.messagePollingService.selectConversation(null, false);
  }

  getDisplayName(conversation: SmsSubscriberModel) {
    if (conversation.first_name || conversation.last_name) {
      return `${conversation.first_name} ${conversation.last_name}`;
    }
  }
}
