import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval, Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

export interface IServiceWorkerAppData {
  forceReload: boolean;
  showUpdateDialog: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerUpdateService {

  private readonly _appIsStable$: Observable<boolean>;
  private _poll$: Observable<number | boolean>;
  private _updateActivated$: Subject<IServiceWorkerAppData> = new Subject<IServiceWorkerAppData>();
  private _updateAvailable$: Subject<IServiceWorkerAppData> = new Subject<IServiceWorkerAppData>();

  constructor(appRef: ApplicationRef, private _swUpdates: SwUpdate) {
    this._appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));

    if (this._swUpdates.isEnabled) {
      this._swUpdates.available.subscribe(event => {
        const appData = <IServiceWorkerAppData>event.available.appData;

        if (appData?.forceReload) {
          this._swUpdates.activateUpdate().then(() => document.location.reload());
        } else {
          this._updateAvailable$.next(appData);
        }
      });

      this._swUpdates.activated.subscribe(event => this._updateActivated$.next(<IServiceWorkerAppData>event.current.appData));
    }

  }

  public onUpdateActivation$(): Observable<IServiceWorkerAppData> {
    return this._updateActivated$.asObservable();
  }

  public onUpdateAvailable$(): Observable<IServiceWorkerAppData> {
    return this._updateAvailable$.asObservable();
  }

  pollForUpdates(intervalInMs: number = 5 * 60 * 1000) {
    if (this._poll$) return;

    if (!this._swUpdates.isEnabled) {
      console.warn('Service worker is not enabled');
      return;
    }

    const everyMinute$ = interval(intervalInMs);
    this._poll$ = concat(this._appIsStable$, everyMinute$);
    this._poll$.subscribe(() => this._swUpdates.checkForUpdate());
  }

}
