import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AlertComponent } from './alert.component';

@NgModule({
  imports: [CommonModule, RouterModule, PortalModule],
  declarations: [AlertComponent],
  exports: [AlertComponent]
})
export class AlertModule {}
