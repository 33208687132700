import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import { LocationOrderSurchargeTemplateInterface, PagyCollectionInterface, TypedEntityInterface } from '../interface';

export interface LocationOrderSurchargeTemplateFormDataInterface {
  locations: TypedEntityInterface[];
  surcharge: LocationOrderSurchargeTemplateInterface | null;
}

@Injectable({
  providedIn: 'root'
})
export class LocationOrderSurchargeTemplateApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/v2/location-order-surcharge-templates`;
  }

  public list(params: any): Observable<PagyCollectionInterface<LocationOrderSurchargeTemplateInterface>> {
    return this.http.get<PagyCollectionInterface<LocationOrderSurchargeTemplateInterface>>(`${this.basePath}`, { params });
  }

  public upsert(request: LocationOrderSurchargeTemplateInterface[]): Observable<{ surcharges: LocationOrderSurchargeTemplateInterface[] }> {
    return this.http.patch<{ surcharges: LocationOrderSurchargeTemplateInterface[] }>(this.basePath, request);
  }

  public formData(uuid: string | undefined): Observable<LocationOrderSurchargeTemplateFormDataInterface> {
    return this.http.get<LocationOrderSurchargeTemplateFormDataInterface>(`${this.basePath}/form-data`, { params: { uuid } });
  }

  public delete(uuid: string): Observable<void> {
    return this.http.delete<void>(`${this.basePath}/${uuid}`);
  }
}
