import { Injectable, OnDestroy } from '@angular/core';
import { CampaignRecipientListInterface } from '../interface';
import { BasePaginationService } from './base-pagination.service';
import { CampaignRecipientListApiService } from './campaign-recipient-list-api.service';

@Injectable()
export class CampaignRecipientListPaginatorService extends BasePaginationService<CampaignRecipientListInterface> implements OnDestroy {
  constructor(private api: CampaignRecipientListApiService) {
    super(api);
  }

  ngOnDestroy() {
    super.destroy();
  }
}
