import { HttpBackend, HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import { UploadInterface } from '../interface';

export interface PreSignedPublicAccessInterface {
  file_name: string;
  pre_signed_url: string;
  access_url: string;
}

@Injectable({
  providedIn: 'root'
})
export class S3ApiService {

  s3HttpClient: HttpClient;

  constructor(private config: ApiConfig,
              private http: HttpClient,
              private handler: HttpBackend
  ) {
    this.s3HttpClient = new HttpClient(handler);
  }

  public getPreSignedURL(file_name: string): Observable<{ filename: string; url: string; }> {
    return this.http.get<{ filename: string; url: string; }>(`${this.config.doloresUrl}/v2/upload/url`, { params: { file_name } });
  }

  public getPreSignedPublicAccessUrl(file_name: string): Observable<PreSignedPublicAccessInterface> {
    return this.http.get<PreSignedPublicAccessInterface>(`${this.config.doloresUrl}/v2/upload/access_url`, { params: { file_name } });
  }

  public uploadFile(file: File, url: string): Observable<HttpEvent<void>> {
    return this.s3HttpClient.put<void>(url, file, { reportProgress: true, observe: 'events' });
  }

  public uploadFileToPhp(file: File): Observable<HttpEvent<UploadInterface>> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<UploadInterface>(`${this.config.baseUrl}/v2/upload`, formData, { reportProgress: true, observe: 'events', params: { 'ngsw-bypass': true } });
  }

}
