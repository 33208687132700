import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorModel } from '@shared/model/error/error.model';
import { InventoryDataInterface } from '../../model/inventory.model';
import { InventoryActions, InventoryActionTypes } from '../actions/inventory.actions';

export interface State extends EntityState<InventoryDataInterface> {
  loading: boolean;
  updating: boolean;
  selected: string;
  currentPage: number;
  totalPages: number;
  totalRecords: number;
  error: ErrorModel;
}

export const adapter: EntityAdapter<InventoryDataInterface> = createEntityAdapter<InventoryDataInterface>({
  selectId: (inventoryModel: InventoryDataInterface) => inventoryModel.uuid
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  updating: false,
  selected: undefined,
  currentPage: undefined,
  totalPages: undefined,
  totalRecords: undefined,
  error: undefined
});

export function reducer(state = initialState, action: InventoryActions): State {
  switch (action.type) {
    case InventoryActionTypes.LoadInventoryCollection:
    case InventoryActionTypes.LoadInventory: {
      return {
        ...state,
        loading: true
      };
    }

    case InventoryActionTypes.UpsertInventory:
    case InventoryActionTypes.DeleteInventory: {
      return {
        ...state,
        updating: true
      };
    }

    case InventoryActionTypes.LoadInventoryCollectionSuccess: {
      const { collection, preserveExistingRecords } = action.payload;
      const method = preserveExistingRecords ? 'addMany' : 'setAll';
      return adapter[method](collection.records, {
        ...state,
        loading: false,
        totalRecords: collection.total_records,
        totalPages: collection.total_pages,
        currentPage: collection.current_page
      });
    }

    case InventoryActionTypes.LoadInventorySuccess: {
      const { inventory } = action.payload;
      return adapter.upsertOne(inventory as InventoryDataInterface, {
        ...state,
        selected: inventory.uuid,
        loading: false
      });
    }

    case InventoryActionTypes.UpsertInventorySuccess: {
      const { inventory } = action.payload;
      return adapter.upsertOne(inventory, { ...state, updating: false });
    }

    case InventoryActionTypes.DeleteInventorySuccess: {
      const { inventory } = action.payload;
      return adapter.removeOne(inventory.uuid, { ...state, updating: false });
    }

    case InventoryActionTypes.LoadInventoryCollectionFailure:
    case InventoryActionTypes.LoadInventoryFailure:
    case InventoryActionTypes.UpsertInventoryFailure:
    case InventoryActionTypes.DeleteInventoryFailure: {
      const { error } = action.payload;
      return { ...state, loading: false, updating: false, error };
    }


    case InventoryActionTypes.SelectInventory: {
      const { inventory } = action.payload;
      return { ...state, selected: inventory.uuid};
    }

    case InventoryActionTypes.DeselectInventory: {
      return { ...state, selected: null };
    }

    case InventoryActionTypes.ClearInventoryState: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
