import { NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, PreloadAllModules, Router, RouterModule } from '@angular/router';
import { ReportingGuard } from '@myblackbird/guard';
import { BillingPortal } from '@shared/entity/feature-flag/enumeration';
import { PolicyPermissions } from '@myblackbird/auth';
import { LazyErrorService } from '@shared/utility/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';
import { AuthGuard } from '@myblackbird/auth';

const routes = [
  { path: '', loadChildren: () => import('./_login/login.module').then(m => m.LoginModule) },
  {
    path: 'home',
    loadChildren: () => import('@myblackbird/lazy/home').then(m => m.HomeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'orders',
    loadChildren: () => import('@myblackbird/lazy/order').then(m => m.OrderModule),
    canLoad: [AuthGuard],
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [PolicyPermissions.READ_ORDER, PolicyPermissions.READ_REFUND],
        redirectTo: '/home'
      }
    }
  },
  {
    path: 'customer-center',
    loadChildren: () => import('@myblackbird/lazy/consumer').then(m => m.ConsumerModule),
    canLoad: [AuthGuard],
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [
          PolicyPermissions.READ_CHECK_IN,
          PolicyPermissions.READ_CONSUMER,
          PolicyPermissions.READ_EVENT,
          PolicyPermissions.READ_LOYALTY_REWARD
        ],
        redirectTo: '/home'
      }
    }
  },
  {
    path: 'product-management',
    loadChildren: () => import('@myblackbird/lazy/product-management').then(m => m.ProductManagementModule),
    canLoad: [AuthGuard],
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: PolicyPermissions.WRITE_PRODUCT,
        redirectTo: '/home'
      }
    }
  },
  {
    path: 'configuration',
    loadChildren: () => import('@myblackbird/lazy/configuration').then(m => m.ConfigurationModule),
    canLoad: [AuthGuard],
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [
          PolicyPermissions.WRITE_BUSINESS_INFO,
          PolicyPermissions.WRITE_DISCOUNT,
          PolicyPermissions.WRITE_EMPLOYEE,
          PolicyPermissions.WRITE_INTEGRATION_CONFIGURATION,
          PolicyPermissions.WRITE_LOYALTY_MULTIPLIER,
          PolicyPermissions.READ_LOYALTY_PROGRAM,
          PolicyPermissions.WRITE_LOYALTY_PROGRAM,
          PolicyPermissions.WRITE_LOYALTY_TRIGGER,
          PolicyPermissions.WRITE_MENU,
          PolicyPermissions.WRITE_MESSAGE_TRIGGER,
          PolicyPermissions.WRITE_SAFE,
          PolicyPermissions.WRITE_STATION,
          PolicyPermissions.WRITE_BRAND_EMBED_SETTINGS,
          PolicyPermissions.VIEW_STORE_LOCATOR_SETTINGS,
        ],
        redirectTo: '/home'
      }
    }
  },
  {
    path: 'accounting',
    loadChildren: () => import('@myblackbird/lazy/accounting').then(m => m.AccountingModule),
    canLoad: [AuthGuard],
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [
          PolicyPermissions.BALANCE_SAFE,
          PolicyPermissions.BALANCE_STATION,
          PolicyPermissions.TRANSFER_FUNDS,
          PolicyPermissions.VIEW_BILLING
        ],
        redirectTo: '/home'
      }
    }
  },
  {
    path: 'shipping',
    loadChildren: () => import('@myblackbird/lazy/shipping').then(m => m.ShippingModule),
    canLoad: [AuthGuard],
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [
          PolicyPermissions.READ_CASH_BALANCE,
          PolicyPermissions.READ_DELIVERY,
          PolicyPermissions.READ_PURCHASE_ORDER,
          PolicyPermissions.WRITE_CASH_BALANCE,
          PolicyPermissions.WRITE_DELIVERY,
          PolicyPermissions.WRITE_PURCHASE_ORDER
        ],
        redirectTo: '/home'
      }
    }
  },
  {
    path: 'dispatch',
    loadChildren: () => import('@myblackbird/lazy/dispatch').then(m => m.DispatchDashboardModule),
    canLoad: [AuthGuard],
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: PolicyPermissions.VIEW_DISPATCH,
        redirectTo: '/home'
      }
    }
  },
  {
    path: 'billing',
    loadChildren: () => import('@myblackbird/lazy/billing-portal').then(m => m.BillingPortalModule),
    canLoad: [AuthGuard],
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: PolicyPermissions.VIEW_CHARGEBEE_BILLING,
        redirectTo: '/home'
      },
      flags: BillingPortal.name
    }
  },
  { path: 'tracking/:id', loadChildren: () => import('@myblackbird/lazy/tracking').then(m => m.TrackingModule) },
  {
    path: 'brand-management',
    loadChildren: () =>
      import('@myblackbird/brand-management/feature').then(m => m.MyBlackbirdBrandManagementFeatureModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'reporting',
    canActivate: [AuthGuard, NgxPermissionsGuard, ReportingGuard],
    data: {
      permissions: {
        only: PolicyPermissions.VIEW_REPORTING,
        redirectTo: '/home'
      }
    },
    loadChildren: () => import('./_not-found/not-found.module').then(m => m.NotFoundModule)
  },
  {
    path: 'not-found',
    canActivate: [AuthGuard],
    loadChildren: () => import('./_not-found/not-found.module').then(m => m.NotFoundModule)
  },
  { path: 'configuration/integration-hub', redirectTo: 'integration-hub' },
  { path: 'auth', redirectTo: '' },
  { path: '**', redirectTo: 'not-found', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {
  constructor(activatedRoute: ActivatedRoute, router: Router, titleService: Title, lazyErrorService: LazyErrorService) {
    router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
        distinctUntilChanged(),
        filter(route => route !== {})
      )
      .subscribe(event => titleService.setTitle(event['title'] ? event['title'] : 'My Blackbird'));

    lazyErrorService.subscribeRouteError();
  }
}
