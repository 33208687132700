import { Injectable, OnDestroy } from '@angular/core';
import { CampaignListInterface } from '../interface';
import { BasePaginationService } from './base-pagination.service';
import { CampaignMessageApiService } from './campaign-message-api.service';
import { interval, Subscription } from 'rxjs';

@Injectable()
export class CampaignPaginatorService extends BasePaginationService<CampaignListInterface> implements OnDestroy {
  private subscription: Subscription | null;

  constructor(private api: CampaignMessageApiService) {
    super(api);

    this.startRefresh();
  }

  ngOnDestroy() {
    this.stopRefresh();
    super.destroy();
  }

  public startRefresh() {
    this.subscription = interval(30 * 1000).subscribe(() => (this.fetchAndUpdateCurrentPage()));
  }

  public stopRefresh() {
    this.subscription?.unsubscribe();
  }
}
