import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import {
  PagyCollectionInterface,
  StockImageInterface
} from '../interface';

@Injectable({
  providedIn: 'root'
})
export class StockImageApiService {
  baseUrl: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.baseUrl = `${this.config.baseUrl}/v2/stock-images`;
  }

  public list(params: any): Observable<PagyCollectionInterface<StockImageInterface>> {
    return this.http.get<PagyCollectionInterface<StockImageInterface>>(`${this.baseUrl}`, { params });
  }
}
