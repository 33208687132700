import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable, of } from 'rxjs';
import {
  ListItemInterface,
  LoyaltyProgramInterface,
  LoyaltyTriggerInterface,
  PagyCollectionInterface
} from '../interface';

interface PageDataInterface {
  canEdit: boolean;
  loyalty_program: LoyaltyProgramInterface | null;
}

@Injectable({
  providedIn: 'root'
})
export class LoyaltyTriggerApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/v2/loyalty-triggers`;
  }

  public list(params: any): Observable<PagyCollectionInterface<LoyaltyTriggerInterface>> {
    return this.http.get<PagyCollectionInterface<LoyaltyTriggerInterface>>(`${this.basePath}`, { params });
  }

  pageData(): Observable<PageDataInterface> {
    return this.http.get<PageDataInterface>(`${this.basePath}/page-data`);
  }

  public show(uuid: string | null): Observable<LoyaltyTriggerInterface> {
    if (!uuid) {
      return of(null);
    }
    return this.http.get<LoyaltyTriggerInterface>(`${this.basePath}/${uuid}`);
  }

  public upsert(params: any): Observable<LoyaltyTriggerInterface> {
    return this.http.post<LoyaltyTriggerInterface>(`${this.basePath}`, params);
  }

  public delete(uuid: string): Observable<void> {
    return this.http.delete<void>(`${this.basePath}/${uuid}`);
  }

  public dropdown(): Observable<ListItemInterface[]> {
    return this.http.get<ListItemInterface[]>(`${this.basePath}/dropdown`);
  }
}
