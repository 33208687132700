import { Injectable, OnDestroy } from '@angular/core';
import { StockImageInterface } from '../interface';
import { BasePaginationService } from './base-pagination.service';
import { StockImageApiService } from './stock-image-api.service';

@Injectable()
export class StockImagePaginatorService extends BasePaginationService<StockImageInterface> implements OnDestroy {
  constructor(private api: StockImageApiService) {
    super(api, { limit: 12 });
  }

  ngOnDestroy() {
    super.destroy();
  }
}
