import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';

export interface ConfirmationDialogDataInterface {
  title?: string;
  message?: string;
  confirmButtonCopy?: string;
  showConfirmButton?: boolean;
  cancelButtonCopy?: string;
  showCancelButton?: boolean;
  confirmButtonColor?: ThemePalette;
}

@Component({
  selector: 'shared-dialog-confirmation',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements ConfirmationDialogDataInterface {
  title: string;
  message: string;
  showConfirmButton: boolean;
  confirmButtonCopy: string;
  confirmButtonColor: ThemePalette;
  showCancelButton: boolean;
  cancelButtonCopy: string;

  constructor(@Inject(MAT_DIALOG_DATA) _data: ConfirmationDialogDataInterface) {
    this.title = _data.title;
    this.message = _data.message || 'Are you sure?';
    this.showCancelButton = _data.showCancelButton;
    this.showConfirmButton = _data.showConfirmButton;
    this.cancelButtonCopy = _data.cancelButtonCopy || 'Cancel';
    this.confirmButtonCopy = _data.confirmButtonCopy || 'Confirm';
    this.confirmButtonColor = _data.confirmButtonColor || 'primary';
  }
}
