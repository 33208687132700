import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MessagingCenterDialogComponent } from '../lib/component/dialog/messaging-center-dialog.component';
import { ChatMessageComponent } from '../lib/component/chat-message/chat-message.component';
import { ConsumerListComponent } from '../lib/component/dialog/consumer-list/consumer-list.component';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatIconModule } from '@angular/material/icon';
import { LocationStateModule } from '@shared/entity/location/state';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { PipesModule } from '@shared/pipes';
import { MomentModule } from 'ngx-moment';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PhoneInputModule } from '@shared/material-inputs/phone-input';
import { ValidationModule } from '@shared/validation';


@NgModule({
  imports: [
    CommonModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressBarModule,
    MomentModule,
    ScrollingModule,
    LocationStateModule,
    ReactiveFormsModule,
    PipesModule,
    PhoneInputModule,
    ValidationModule,
    NgxPermissionsModule
  ],
  declarations: [ChatMessageComponent, MessagingCenterDialogComponent, ConsumerListComponent],
  exports: [],
  entryComponents: [MessagingCenterDialogComponent]
})
export class MessagingCenterModule {}
