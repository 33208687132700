import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagStateService {
  private flags$ = new BehaviorSubject<{ [key: string]: string }>({});

  public setFlags(values: string[]) {
    const flags = {};
    for (const val of values) {
      flags[val] = val;
    }
    this.flags$.next(flags);
  }

  public enabled(value: any): boolean {
    return !!this.flags$.value[value];
  }
}
