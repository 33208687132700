import { Injectable } from '@angular/core';
import { LocalStorageService } from '@shared/local-storage';
import * as qz from 'qz-tray';
import { BehaviorSubject, Observable } from 'rxjs';
import { IQzTrayConfigs } from '../model/qz.interface';
import { QzModule } from '../qz.module';
import { QzSigningService } from './qz-signing.service';

@Injectable({
  providedIn: QzModule
})
export class QzService {
  private _connectionActiveSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _defaultPrinterSub$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private configs = {};

  constructor(qzSigningService: QzSigningService, private localStorageService: LocalStorageService) {

    qz.api.showDebug(false);

    qz.security.setCertificatePromise((resolve, reject) => {
      qzSigningService.getDigitalCertificate().subscribe(cert => resolve(cert));
    });

    qz.security.setSignaturePromise(toSign =>
      (resolve, reject) => qzSigningService.loadSignature(toSign).subscribe(hash => resolve(hash))
    );
  }

  private launchQZ(): Promise<null> {
    return qz.websocket.connect().then(() => {
      this._connectionActiveSub$.next(true);
      this.getDefaultPrinter().catch(e => {});
      this.setCallbacks();
      return null;
    }).catch(e => {});
  }

  private setCallbacks() {
    qz.websocket.setClosedCallbacks([this.onClosed.bind(this)]);
    qz.websocket.setErrorCallbacks([this.onError.bind(this)]);
  }

  public onClosed(event: CloseEvent): void {
    this._connectionActiveSub$.next(false);
  }

  public onError(event: Event): void {
    console.log(event);
  }

  public initConnection(): Promise<null> {
    if (!this.isQzConnected) {
      return this.launchQZ();
    } else {
      return Promise.resolve(null);
    }
  }

  public closeConnection(): Promise<null | Error> {
    return this.isQzConnected ? qz.websocket.disconnect() : Promise.resolve(null);
  }

  public get isQzConnected$(): Observable<boolean> {
    return this._connectionActiveSub$.asObservable();
  }

  public get isQzConnected(): boolean {
    return this._connectionActiveSub$.getValue();
  }

  public get defaultPrinter$(): Observable<string> {
    return this._defaultPrinterSub$.asObservable();
  }

  public get defaultPrinter(): string {
    return this._defaultPrinterSub$.getValue();
  }

  public setConfig(printer: string): void {
    this.configs = qz.configs.create(printer, { orientation: 'landscape', density: 100 });
  }

  public findPrinters(): Promise<string[] | string> {
    return qz.printers.find();
  }

  public async getDefaultPrinter(): Promise<string> {
    const selectedPrinter = this.localStorageService.getItem('default-printer');
    const printers = await this.findPrinters();
    if (printers && printers.includes(selectedPrinter)) {
      this.setConfig(selectedPrinter);
      this._defaultPrinterSub$.next(selectedPrinter);
      return selectedPrinter;
    } else {
      return Promise.reject('Cannot find default printer');
    }
  }

  public setDefaultPrinter(printer: string): void {
    this.setConfig(printer);
    this._defaultPrinterSub$.next(printer);
    this.localStorageService.store('default-printer', printer);
  }

  public clearDefaultPrinter(): void {
    this.localStorageService.clear('default-printer');
  }

  public print(configOverrides: Partial<IQzTrayConfigs> = {}, data): Promise<null> {
    const configs = {
      ...this.configs,
      ...configOverrides
    };
    return qz.print(configs, data).catch(err => {
      return err;
    });
  }
}
