import { Action } from '@ngrx/store';
import { CollectionInterface } from '@shared/model/collection/collection.interface';
import { ErrorModel } from '@shared/model/error/error.model';
import { InventoryDataInterface } from '../../model/inventory.model';
import { InventoryParameters } from '../../service/inventory.parameters';

export enum InventoryActionTypes {
  ClearInventoryState = '[Inventory] Clear Inventory State',

  SelectInventory = '[Inventory] Select Inventory',
  DeselectInventory = '[Inventory] Deselect Inventory',

  LoadInventoryCollection = '[Inventory] Load Inventory Collection',
  LoadInventoryCollectionSuccess = '[Inventory] Load Inventory Collection Success',
  LoadInventoryCollectionFailure = '[Inventory] Load Inventory Collection Failure',

  LoadInventory = '[Inventory] Load  Inventory',
  LoadInventorySuccess = '[Inventory] Load Inventory Success',
  LoadInventoryFailure = '[Inventory] Load Inventory Failure',

  UpsertInventory = '[Inventory] Upsert Inventory',
  UpsertInventorySuccess = '[Inventory] Upsert Inventory Success',
  UpsertInventoryFailure = '[Inventory] Upsert Inventory Failure',

  DeleteInventory = '[Inventory] Delete  Inventory',
  DeleteInventorySuccess = '[Inventory] Delete Inventory Success',
  DeleteInventoryFailure = '[Inventory] Delete Inventory Failure',

  DestroyAndArchiveProductInventory        = '[Product Inventory] Destroy and Archive Inventory',
  DestroyAndArchiveProductInventorySuccess = '[Product Inventory] Destroy and Archive Inventory Success',
  DestroyAndArchiveProductInventoryFailure = '[Product Inventory] Destroy and Archive Inventory FAILURE'
}

export class ClearInventoryState implements Action {
  readonly type = InventoryActionTypes.ClearInventoryState;
}

export class SelectInventory implements Action {
  readonly type = InventoryActionTypes.SelectInventory;
  constructor(public payload: { inventory: InventoryDataInterface} ){}
}

export class DeselectInventory implements Action {
  readonly type = InventoryActionTypes.DeselectInventory;
}

export class LoadInventoryCollection implements Action {
  readonly type = InventoryActionTypes.LoadInventoryCollection;

  constructor(public payload: { parameters: InventoryParameters; preserveExistingRecords?: boolean }) {}
}

export class LoadInventoryCollectionSuccess implements Action {
  readonly type = InventoryActionTypes.LoadInventoryCollectionSuccess;

  constructor(public payload: { collection: CollectionInterface<InventoryDataInterface>; preserveExistingRecords?: boolean }) {}
}

export class LoadInventoryCollectionFailure implements Action {
  readonly type = InventoryActionTypes.LoadInventoryCollectionFailure;

  constructor(public payload: { error: ErrorModel }) {}
}

export class LoadInventory implements Action {
  readonly type = InventoryActionTypes.LoadInventory;

  constructor(public payload: { inventory: InventoryDataInterface }) {}
}

export class LoadInventorySuccess implements Action {
  readonly type = InventoryActionTypes.LoadInventorySuccess;

  constructor(public payload: { inventory: InventoryDataInterface }) {}
}

export class LoadInventoryFailure implements Action {
  readonly type = InventoryActionTypes.LoadInventoryFailure;

  constructor(public payload: { error: ErrorModel }) {}
}

export class UpsertInventory implements Action {
  readonly type = InventoryActionTypes.UpsertInventory;

  constructor(public payload: { inventory: InventoryDataInterface }) {}
}

export class UpsertInventorySuccess implements Action {
  readonly type = InventoryActionTypes.UpsertInventorySuccess;

  constructor(public payload: { inventory: InventoryDataInterface }) {}
}

export class UpsertInventoryFailure implements Action {
  readonly type = InventoryActionTypes.UpsertInventoryFailure;

  constructor(public payload: { error: ErrorModel }) {}
}

export class DeleteInventory implements Action {
  readonly type = InventoryActionTypes.DeleteInventory;

  constructor(public payload: { inventory: InventoryDataInterface }) {}
}

export class DeleteInventorySuccess implements Action {
  readonly type = InventoryActionTypes.DeleteInventorySuccess;

  constructor(public payload: { inventory: InventoryDataInterface }) {}
}

export class DeleteInventoryFailure implements Action {
  readonly type = InventoryActionTypes.DeleteInventoryFailure;

  constructor(public payload: { error: ErrorModel }) {}
}

export class DestroyAndArchiveProductInventory implements Action {
  readonly type = InventoryActionTypes.DestroyAndArchiveProductInventory;

  constructor(public payload: { inventory: InventoryDataInterface[] }) {}
}

export class DestroyAndArchiveProductInventorySuccess implements Action {
  readonly type = InventoryActionTypes.DestroyAndArchiveProductInventorySuccess;

  constructor(public payload: { inventory: InventoryDataInterface }) {}
}

export class DestroyAndArchiveProductInventoryFailure implements Action {
  readonly type = InventoryActionTypes.DestroyAndArchiveProductInventoryFailure;

  constructor(public payload: { error: ErrorModel }) {}
}

export type InventoryActions =
  | ClearInventoryState
  | SelectInventory
  | DeselectInventory
  | LoadInventoryCollection
  | LoadInventoryCollectionSuccess
  | LoadInventoryCollectionFailure
  | LoadInventory
  | LoadInventorySuccess
  | LoadInventoryFailure
  | UpsertInventory
  | UpsertInventorySuccess
  | UpsertInventoryFailure
  | DeleteInventory
  | DeleteInventorySuccess
  | DeleteInventoryFailure
  | DestroyAndArchiveProductInventory
  | DestroyAndArchiveProductInventorySuccess
  | DestroyAndArchiveProductInventoryFailure
  ;
