import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import {
  ConsumerCampaignMessageInsightsInterface,
  ConsumerLoyaltyInsightsInterface,
  ConsumerOrderInsightsInterface
} from '../interface';

@Injectable({
  providedIn: 'root'
})
export class ConsumerApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/v2/consumer`;
  }

  public loadConsumerOrderInsights(uuid: string): Observable<ConsumerOrderInsightsInterface> {
    return this.http.get<ConsumerOrderInsightsInterface>(`${this.basePath}/${uuid}/order-insights`);
  }

  public loadConsumerLoyaltyInsights(uuid: string): Observable<ConsumerLoyaltyInsightsInterface> {
    return this.http.get<ConsumerLoyaltyInsightsInterface>(`${this.basePath}/${uuid}/loyalty-insights`);
  }

  public loadConsumerCampaignMessageInsights(uuid: string): Observable<ConsumerCampaignMessageInsightsInterface> {
    return this.http.get<ConsumerCampaignMessageInsightsInterface>(`${this.basePath}/${uuid}/campaign-insights`);
  }
}
