import { AbstractControl } from '@angular/forms';
import { Utility } from '@frontend/component-lib/localization';

export function PhoneNumber(control: AbstractControl) {
  if (!control.value) return null;

  const normalizedPhoneNumber = Utility.normalizePhoneNumber(control.value);

  if (normalizedPhoneNumber.length !== 12) return { invalidPhoneNumber: 'length' };

  const invalidPhoneNumber = !/^\+?[1-9]\d{1,14}$/.test(normalizedPhoneNumber);
  return invalidPhoneNumber ? { invalidPhoneNumber } : null;
}
