/**
 * THIS FILE IS AUTOGENERATED
 */

import { Action } from '@ngrx/store';
import { CollectionInterface } from '@shared/model/collection/collection.interface';
import { ErrorModel } from '@shared/model/error/error.model';
import { LocationModel } from '../../model/location.model';
import { LocationParameters } from '../../service/location.parameters';

export enum LocationActionTypes {
  ClearLocationState = '[Location] Clear Location State',

  LoadLocationCollection = '[Location] Load Location Collection',
  LoadLocationCollectionSuccess = '[Location] Load Location Collection Success',
  LoadLocationCollectionFailure = '[Location] Load Location Collection Failure',

  LoadLocation = '[Location] Load  Location',
  LoadLocationSuccess = '[Location] Load Location Success',
  LoadLocationFailure = '[Location] Load Location Failure',

  UpsertLocation = '[Location] Upsert Location',
  UpsertLocationSuccess = '[Location] Upsert Location Success',
  UpsertLocationFailure = '[Location] Upsert Location Failure',

  DeleteLocation = '[Location] Delete  Location',
  DeleteLocationSuccess = '[Location] Delete Location Success',
  DeleteLocationFailure = '[Location] Delete Location Failure'
}

export class ClearLocationState implements Action {
  readonly type = LocationActionTypes.ClearLocationState;
}

export class LoadLocationCollection implements Action {
  readonly type = LocationActionTypes.LoadLocationCollection;

  constructor(public payload: { parameters: LocationParameters, preserveExistingRecords?: boolean }) {}
}

export class LoadLocationCollectionSuccess implements Action {
  readonly type = LocationActionTypes.LoadLocationCollectionSuccess;

  constructor(public payload: { collection: CollectionInterface<LocationModel>, preserveExistingRecords?: boolean }) {}
}

export class LoadLocationCollectionFailure implements Action {
  readonly type = LocationActionTypes.LoadLocationCollectionFailure;

  constructor(public payload: { error: ErrorModel }) {}
}


export class LoadLocation implements Action {
  readonly type = LocationActionTypes.LoadLocation;

  constructor(public payload: { records: LocationModel | LocationModel[] }) {}
}

export class LoadLocationSuccess implements Action {
  readonly type = LocationActionTypes.LoadLocationSuccess;

  constructor(public payload: { records: LocationModel | LocationModel[] }) {}
}

export class LoadLocationFailure implements Action {
  readonly type = LocationActionTypes.LoadLocationFailure;

  constructor(public payload: { error: ErrorModel | ErrorModel[] }) {}
}


export class UpsertLocation implements Action {
  readonly type = LocationActionTypes.UpsertLocation;

  constructor(public payload: { records: LocationModel | LocationModel[] }) {}
}

export class UpsertLocationSuccess implements Action {
  readonly type = LocationActionTypes.UpsertLocationSuccess;

  constructor(public payload: { records: LocationModel | LocationModel[] }) {}
}

export class UpsertLocationFailure implements Action {
  readonly type = LocationActionTypes.UpsertLocationFailure;

  constructor(public payload: { error: ErrorModel | ErrorModel[] }) {}
}


export class DeleteLocation implements Action {
  readonly type = LocationActionTypes.DeleteLocation;

  constructor(public payload: { records: LocationModel | LocationModel[] }) {}
}

export class DeleteLocationSuccess implements Action {
  readonly type = LocationActionTypes.DeleteLocationSuccess;

  constructor(public payload: { records: LocationModel | LocationModel[] }) {}
}

export class DeleteLocationFailure implements Action {
  readonly type = LocationActionTypes.DeleteLocationFailure;

  constructor(public payload: { error: ErrorModel | ErrorModel[] }) {}
}


export type LocationActions =
   ClearLocationState
  | LoadLocationCollection
  | LoadLocationCollectionSuccess
  | LoadLocationCollectionFailure
  | LoadLocation
  | LoadLocationSuccess
  | LoadLocationFailure
  | UpsertLocation
  | UpsertLocationSuccess
  | UpsertLocationFailure
  | DeleteLocation
  | DeleteLocationSuccess
  | DeleteLocationFailure
   ;
