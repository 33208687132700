import { ConsumerInterestModel as LocationConsumerInterestModel } from '@shared/entity/consumer-interest/model';
import { ErrorModel } from '@shared/model/error/error.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  LocationConsumerInterestActions,
  LocationConsumerInterestActionTypes
} from './location-consumer-interest.actions';

export interface State extends EntityState<LocationConsumerInterestModel> {
  // additional entities state properties
  loading: boolean;
  updating: boolean;
  selected: string;
  currentPage: number;
  totalPages: number;
  totalRecords: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  error: ErrorModel;
}

export const adapter: EntityAdapter<LocationConsumerInterestModel> = createEntityAdapter<LocationConsumerInterestModel>(
  {
    selectId: (locationConsumerInterest: LocationConsumerInterestModel) => locationConsumerInterest.uuid
  }
);

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loading: false,
  updating: false,
  selected: null,
  currentPage: 1,
  totalPages: null,
  totalRecords: null,
  hasNextPage: null,
  hasPreviousPage: null,
  error: null
});

export function reducer(state = initialState, action: LocationConsumerInterestActions): State {
  switch (action.type) {
    case LocationConsumerInterestActionTypes.LoadLocationConsumerInterests:
    case LocationConsumerInterestActionTypes.LoadLocationConsumerInterest: {
      return { ...state, selected: null, loading: true };
    }

    case LocationConsumerInterestActionTypes.LoadLocationConsumerInterestsSuccess: {
      const collection = action.payload.interests;
      const method = action.payload.preserveRecords ? 'addMany' : 'setAll';

      return adapter[method](collection.records, {
        ...state,
        error: null,
        loading: false,
        currentPage: collection.current_page,
        totalPages: collection.total_pages,
        totalRecords: collection.total_records
      });
    }

    case LocationConsumerInterestActionTypes.LoadLocationConsumerInterestsFailure: {
      return adapter.removeAll({
        ...state,
        error: action.payload,
        currentPage: 1,
        totalPages: null,
        totalRecords: null,
        loading: false
      });
    }

    case LocationConsumerInterestActionTypes.LoadLocationConsumerInterestSuccess: {
      const locationConsumer = action.payload.interest;
      return adapter.upsertOne(locationConsumer, {
        ...state,
        error: null,
        selected: locationConsumer.uuid,
        loading: false
      });
    }

    case LocationConsumerInterestActionTypes.LoadLocationConsumerInterestFailure: {
      return adapter.removeAll({
        ...state,
        error: action.payload,
        selected: null,
        loading: false
      });
    }

    case LocationConsumerInterestActionTypes.UpsertLocationConsumerInterest:
    case LocationConsumerInterestActionTypes.DeleteLocationConsumerInterest: {
      return { ...state, updating: true };
    }

    case LocationConsumerInterestActionTypes.UpsertLocationConsumerInterestSuccess: {
      const locationConsumer = action.payload.interest;
      return adapter.upsertOne(locationConsumer, {
        ...state,
        error: null,
        updating: false
      });
    }

    case LocationConsumerInterestActionTypes.DeleteLocationConsumerInterestSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        error: null,
        updating: false
      });
    }

    case LocationConsumerInterestActionTypes.UpsertLocationConsumerInterestFailure:
    case LocationConsumerInterestActionTypes.DeleteLocationConsumerInterestFailure: {
      return { ...state, error: action.payload, updating: false };
    }

    case LocationConsumerInterestActionTypes.ClearLocationConsumerInterests: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
