import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { BaseApiService } from '@shared/utility/api';

@Injectable()
export class SmsConversationActionsService extends BaseApiService {
  basePath: string = '/v2/sms_conversations/{consumer_id}/';
  readonly uniqueIdentifier: string = 'uuid';

  constructor(cellPhone: string, protected config: ApiConfig, protected http: HttpClient) {
    super(config, http);
    this.setBasePath(cellPhone);
  }

  setBasePath(cellPhone: string) {
    this.basePath = this.basePath.split('{consumer_id}').join(cellPhone);
  }

  archiveConversation() {
    return this.http.post<any>(this.getDoloresPath('/archive'), {});
  }

  markAsRead() {
    return this.http.post<any>(this.getDoloresPath('/mark_as_read'), {});
  }

  sendMessage(body: string) {
    return this.http.post<any>(this.getDoloresPath('/send_message'), { body: body });
  }

  unarchiveConversation() {
    return this.http.post<any>(this.getDoloresPath('/unarchive'), {});
  }
}
