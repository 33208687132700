import { OffsetHttpParameters } from '@shared/utility/api';
import { InventoryZoneEnum } from '../../inventory/enumeration';

type InventoryTransferOrderByTypes = 'date' | 'created_at';

export class InventoryTransferParameters extends OffsetHttpParameters {
  private searchJSON: Partial<{
    zone: { in: InventoryZoneEnum[] };
  }> = {};

  constructor(params?: Partial<InventoryTransferParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: InventoryTransferOrderByTypes): this {
    return super.setOrderBy(orderBy);
  }

  setWhereInventoryZone(zones?: InventoryZoneEnum | InventoryZoneEnum[]): this {
    const zoneArray: InventoryZoneEnum[] =
      Array.isArray(zones) && zones.length ? <InventoryZoneEnum[]>zones : zones ? [<InventoryZoneEnum>zones] : null;
    this.searchJSON = { ...this.searchJSON, zone: zoneArray ? { in: zoneArray } : undefined };
    return this;
  }

  getParams(): { [p: string]: any } {
    const search = JSON.stringify({
      ...this.searchJSON
    });

    return this.removeUndefinedValues({
      ...super.getParams(),
      search
    });
  }
}
