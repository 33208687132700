import { Pipe, PipeTransform } from '@angular/core';

/*
 * Rounds delivery mileage UP to nearest whole number
 * Usage:
 *   value | deliveryMileage
 */

@Pipe({ name: 'deliveryMileage' })
export class DeliveryMileagePipe implements PipeTransform {
  transform(value: number): number {
    if (!value) {
      return value;
    }
    let newMileage: number = 1;
    if (value > 1) {
      newMileage = Math.round(value);
    }

    return newMileage;
  }
}