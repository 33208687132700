import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import {
  MenuAnalyticsInterface, MenuInterface
} from '../interface';

@Injectable({
  providedIn: 'root'
})
export class MenuApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/menu`;
  }

  public getMenu(uuid: string): Observable<MenuInterface> {
    return this.http.get<MenuInterface>(`${this.basePath}/${uuid}`);
  }

  public updateMenu(request: MenuInterface): Observable<MenuInterface> {
    return this.http.patch<MenuInterface>(`${this.basePath}/${request.uuid}`, request);
  }

  public getAnalytics(): Observable<MenuAnalyticsInterface> {
    return this.http.get<MenuAnalyticsInterface>(`${this.basePath}/analytics`);
  }

  public updateAnalytics(request: MenuAnalyticsInterface): Observable<MenuAnalyticsInterface> {
    return this.http.patch<MenuAnalyticsInterface>(`${this.basePath}/analytics`, request);
  }

  public view(): Observable<MenuInterface> {
    return this.http.get<MenuInterface>(`${this.config.baseUrl}/v2/menus`);
  }

  public update(request: MenuInterface): Observable<MenuInterface> {
    return this.http.patch<MenuInterface>(`${this.config.baseUrl}/v2/menus`, request);
  }
}
