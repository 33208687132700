import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { InventoryStateModule } from '@shared/entity/inventory/state';
import { NgxPermissionsModule } from 'ngx-permissions';
import { InventoryTransferModalComponent } from './inventory-transfer-modal/inventory-transfer-modal.component';

@NgModule({
  declarations: [InventoryTransferModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    InventoryStateModule,
    NgxPermissionsModule.forChild(),
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatProgressBarModule
  ],
  exports: [MatDialogModule, InventoryStateModule],
  entryComponents: [InventoryTransferModalComponent]
})
export class InventoryTransferModule {}
