import { NgModule }             from '@angular/core';
import { StatusColorDirective } from './status-color.directive';



@NgModule({
  declarations: [StatusColorDirective],
  exports: [StatusColorDirective]
})

export class StatusColorModule {
}
