import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { NavbarComponent } from '../component/navigation/navbar/navbar.component';
import { OrderTypePipeModule } from '../pipe/order-origination/order-origination.module';
import { CustomPipesModule } from './shared/pipes/pipes.module';
import { SharedComponentsModule } from './shared/shared-components.module';
import { MessagingCenterModule } from '@myblackbird/shared/messaging-center';

@NgModule({
  declarations: [NavbarComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MomentModule,
    CustomPipesModule,
    SharedComponentsModule,
    RouterModule,
    OrderTypePipeModule,
    MatMenuModule,
    MatIconModule,
    MatBadgeModule,
    MatSelectModule,
    ScrollingModule,
    ScrollingModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatButtonModule,
    MessagingCenterModule
  ],
  exports: [NavbarComponent]
})
export class HeaderModule {}
