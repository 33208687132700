import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService implements OnDestroy {
  private isBrowser: boolean;
  private _subjects: { [key: string]: BehaviorSubject<any> } = {};

  constructor(@Inject(PLATFORM_ID) platform) {
    this.isBrowser = isPlatformBrowser(platform);
    this.start();
  }

  ngOnDestroy() {
    this.stop();
  }

  select<T>(key: string, defaultValue: any = null): Observable<T> | null {
    if (!this.isBrowser) return;

    if (this._subjects.hasOwnProperty(key)) {
      return this._subjects[key];
    }

    if (!localStorage.getItem(key) && defaultValue) {
      localStorage.setItem(key, JSON.stringify(defaultValue));
    }

    const value = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : defaultValue;
    const newSubj = new BehaviorSubject<T>(value);

    this._subjects[key] = newSubj;

    return newSubj.asObservable();
  }

  store(key: string, value: any): void {
    if (!this.isBrowser) return;

    if (value === null || value === undefined) {
      localStorage.setItem(key, null);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }

    if (this._subjects.hasOwnProperty(key)) {
      this._subjects[key].next(value);
    }
  }

  clear(key: string): void {
    if (!this.isBrowser) return;

    localStorage.removeItem(key);

    if (this._subjects.hasOwnProperty(key)) {
      this._subjects[key].next(null);
    }
  }

  getItem<T = any>(item: string): T {
    if (!this.isBrowser) return;

    if (!localStorage.getItem(item)) return null;

    return JSON.parse(localStorage.getItem(item));
  }

  private start(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  private storageEventListener(event: StorageEvent) {
    if (this.isBrowser && event.storageArea == localStorage) {
      let value;
      try {
        value = JSON.parse(event.newValue);
      } catch (e) {
        value = event.newValue;
      }

      if (this._subjects.hasOwnProperty(event.key)) {
        this._subjects[event.key].next(null);
      } else {
        this._subjects[event.key] = new BehaviorSubject(value);
      }

      this._subjects[event.key].next(value);
    }
  }

  private stop(): void {
    if (this.isBrowser) {
      window.removeEventListener('storage', this.storageEventListener.bind(this));
      for (let subject in this._subjects) {
        this._subjects[subject].complete();
      }
    }
  }
}
