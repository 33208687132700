/**
 * THIS FILE IS AUTOGENERATED
 */

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as actions from '../actions/location.actions';
import { LocationActionTypes, LocationActions } from '../actions/location.actions';
import { LocationService } from '../../service/location.service';

@Injectable()
export class LocationEffects {
  
  @Effect()
    loadCollection$ = this.actions$.pipe(
      ofType(LocationActionTypes.LoadLocationCollection),
      map((action: actions.LoadLocationCollection) => action.payload),
      switchMap((data) => {

        const { parameters, preserveExistingRecords } = data;

        return this.locationService
                   .loadCollection(parameters)
                   .pipe(
                     map(collection => new actions.LoadLocationCollectionSuccess({ collection, preserveExistingRecords })),
                     catchError(error => of(new actions.LoadLocationCollectionFailure({ error })))
                   )
      })
    );
  
  
  @Effect()
    loadLocation$ = this.actions$.pipe(
      ofType(LocationActionTypes.LoadLocation),
      map((action: actions.LoadLocation) => action.payload),
      switchMap((data) => {

        const { records } = data;

        return this.locationService
                   .load(records)
                   .pipe(
                     map(records => new actions.LoadLocationSuccess({ records })),
                     catchError(error => of(new actions.LoadLocationFailure({ error })))
                   )
      })
    );
  
  
  @Effect()
    upsertLocation$ = this.actions$.pipe(
      ofType(LocationActionTypes.UpsertLocation),
      map((action: actions.UpsertLocation) => action.payload),
      switchMap((data) => {

        const { records } = data;

        return  this.locationService
                     .upsert(records)
                     .pipe(
                       map(records => new actions.UpsertLocationSuccess({ records })),
                       catchError(error => of(new actions.UpsertLocationFailure({ error })))
                     )
      })
    );
  
  
  @Effect()
    removeLocation$ = this.actions$.pipe(
      ofType(LocationActionTypes.DeleteLocation),
      map((action: actions.DeleteLocation) => action.payload),
      switchMap((data) => {

        const { records } = data;

        return this.locationService
                   .delete(records)
                   .pipe(
                     map(records => new actions.DeleteLocationSuccess({ records })),
                     catchError(error => of(new actions.DeleteLocationFailure({ error })))
                   )
      })
    );
  

  constructor(
    private actions$: Actions<LocationActions>,
    private locationService: LocationService
  ) {}

}
