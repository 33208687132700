<div class="chat-header">
  <div *ngIf="!chatMessages?.length" class="selected-name">Start a new message</div>
  <button *ngIf="chatMessages?.length && !conversationConsumer.archived"
          type="button"
          mat-flat-button
          color="warn"
          class="close-chat" (click)="closeChat()">Archive Chat
  </button>
</div>
<mat-divider></mat-divider>

<div class="chat-messages" cdkScrollable>

  <div *ngFor="let message of chatMessages; trackBy: trackByFn">

    <mat-card
      [ngStyle]="{'margin-left': message.direction === 'outbound' ? 'auto' : '0' }"
      [ngClass]="{'conversational': message.conversation_tag?.length > 0, 'message-card': true}"
    >
      <mat-card-header>
        <div *ngIf="message.direction === 'inbound'" mat-card-avatar class="customer-image fas fa-user-circle"></div>
        <div *ngIf="message.direction === 'outbound'"
             mat-card-avatar
             class="location-image"
             [ngStyle]="{'background-image': 'url(' + getImageUrl()  + ')'}">
        </div>
        <mat-card-title *ngIf="message.direction === 'inbound'">
          {{ displayName || message.from | obfuscatephone }}
        </mat-card-title>
        <mat-card-title *ngIf="message.direction === 'outbound'">
          {{ message.operator?.first_name }} {{ message.operator?.last_name }}
        </mat-card-title>
        <small class="mat-hint message-time-ago">{{ message.created_at | amTimeAgo }}</small>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content>
        <p class="p-t-16">
          {{ message.body }}
        </p>
      </mat-card-content>
    </mat-card>

  </div>

</div>

<div class="new-message text-xs-right">
  <mat-divider></mat-divider>
  <ng-container  *ngIf="permissions.SEND_CONNECT_TRANSACTIONAL_SMS && twoWayFlagEnabled; else smsDisclaimer">
    <mat-form-field class="w-full message-text-area">
      <textarea [formControl]="message"
                matInput
                type="text"
                rows="2"
                placeholder="New Message"
                (keydown.enter)="onSubmit()"></textarea>
      <div class="mat-error">
        <validation-errors [for]="message"></validation-errors>
      </div>
    </mat-form-field>
    <button type="button"
            (click)="onSubmit()"
            [disabled]="sendingMessage || !message.value?.trim() || !message.valid"
            mat-flat-button
            color="primary"
            class="submit-button">{{ sendingMessage ? 'Sending...' : 'Send' }}</button>
  </ng-container>

  <ng-template #smsDisclaimer>
    <mat-card>
      <p style="text-align: left;">
        Carriers are cracking down on cannabis businesses communicating with customers over their SMS networks.
        While you can review received messages from your customers here, you cannot respond to them at this time. Consider calling consumers directly if needed. 
      </p>
    </mat-card>
  </ng-template>
</div>
