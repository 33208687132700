import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import { LoyaltyProgramDetailInterface, TypedEntityInterface } from '../interface';

export interface FormDataInterface {
  canEdit: boolean,
  locations: TypedEntityInterface[],
  loyalty_program: LoyaltyProgramDetailInterface | null
}

@Injectable({
  providedIn: 'root'
})
export class LoyaltyProgramApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/v2/loyalty-programs`;
  }

  public formData(): Observable<FormDataInterface> {
    return this.http.get<FormDataInterface>(`${this.basePath}/form-data`);
  }

  public upsert(params: any): Observable<LoyaltyProgramDetailInterface> {
    return this.http.post<LoyaltyProgramDetailInterface>(`${this.basePath}`, params);
  }
}
