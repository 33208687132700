import { Injectable, OnDestroy } from '@angular/core';
import { CampaignMsMessageInterface } from '../interface';
import { BasePaginationService } from './base-pagination.service';
import { CampaignMsMessageApiService } from './campaign-ms-message-api.service';

@Injectable()
export class CampaignMsMessagePaginatorService extends BasePaginationService<CampaignMsMessageInterface> implements OnDestroy {
  constructor(private api: CampaignMsMessageApiService) {
    super(api);
  }

  ngOnDestroy() {
    super.destroy();
  }
}
