import { PolicyPermissions } from '../policy.permissions';
import { AbstractPolicy } from '../abstract.policy';

export class ComplianceAddonPolicy extends AbstractPolicy {

  protected tellerPermissions = [
    PolicyPermissions.READ_DELIVERY
  ];

  protected managerPermissions = [
    ...this.tellerPermissions,
    PolicyPermissions.READ_EMPLOYEE,
    PolicyPermissions.WRITE_EMPLOYEE
  ];

  protected adminPermissions = [
    ...this.managerPermissions
  ];

  protected dispatcherPermissions = [
    ...this.adminPermissions
  ];

  constructor(acl) {
    super(acl);

    if (this.isLocationAcl) {
      this.managerPermissions.push(...[PolicyPermissions.READ_BUSINESS_INFO, PolicyPermissions.WRITE_BUSINESS_INFO])
      this.adminPermissions = [...this.managerPermissions]
    }

  }
}
