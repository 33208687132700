export type PropertyType = 'UA' | 'GA4' | undefined;

export interface WindowWithAnalytics extends Window {
  [key: string]: any;
}

export enum AnalyticsProviderEnum {
  GTM = 'google_tag_manager',
  GA = 'google_analytics'
}

export enum GoogleAnalyticsEnum {
  TAG_MANAGER = 'tag_manager',
  ANALYTICS = 'analytics'
}

export interface AnalyticsInterface {
  trackPageView(url: string): void;

  addProductImpressions(impressions: AnalyticsProductImpressionInterface[], listName?: string): void;

  addPromoImpressions(promos: AnalyticsPromoImpressionInterface[]): void;

  addPromoClick(promo: AnalyticsPromoClickInterface): void;

  viewProductDetail(product: AnalyticsProductDetailInterface): void;

  addToCart(products: AnalyticsCartProductInterface | AnalyticsCartProductInterface[]): void;

  removeFromCart(products: AnalyticsCartProductInterface | AnalyticsCartProductInterface[]): void;

  checkout(products: AnalyticsCartProductInterface[]): void;

  purchase(cart: AnalyticsSubmitCartInterface): void;
}

export interface AnalyticsProductDetailInterface {
  uuid: string;
  name: string;
  brand: { name: string };
  product_category: { name: string };
}

export interface AnalyticsProductInterface {
  product: {
    uuid: string;
    name: string;
    brand: { name: string };
    product_category: { name: string };
  };
  unit_label: string;
  price: number;
  quantity: number;
}

export interface AnalyticsCartProductInterface {
  discount_total: number;
  excise_tax_total: number;
  price_each: number;
  price_total: number;
  quantity: number;
  sales_tax_total: number;
  subtotal: number;
  tax_total: number;
  inventory: {
    uuid: string;
    stock: number;
    unit_label: string;
    product: {
      uuid: string;
      name: string;
      brand: { name: string } | null;
      product_category: { name: string };
      category: { name: string };
    };
  };
}

export interface AnalyticsProductImpressionInterface {
  product: {
    uuid: string;
    name: string;
    product_category: { name: string };
    brand: { name: string };
  };
  unit_label: string;
  price: number;
}

export interface AnalyticsPromoImpressionInterface {
  slug: string;
  name: string;
  creative_name?: string;
  creative_slot?: number;
}

export interface AnalyticsPromoClickInterface {
  slug: string;
  name: string;
  creative_name?: string;
  creative_slot?: number;
}

export interface AnalyticsSubmitCartInterface {
  uuid: string;
  subtotal: number;
  delivery_rate: number;
  discount_total: number;
  tax_total: number;
  total: number;
  created_at: string;
  menu: { slug: string };
  customer?: { uuid: string };
  products: {
    uuid: string;
    name: string;
    brand: { uuid: string; } & ({ _name: string } | { name: string });
    category: { uuid: string; } & ({ _name: string } | { name: string });
    unit_label: string;
    quantity: number;
    price_each: number;
  }[];
}
