import { Injectable, OnDestroy } from '@angular/core';
import { BasePaginationService } from './base-pagination.service';
import { LoyaltyTriggerInterface } from '../interface';
import { LoyaltyTriggerApiService } from './loyalty-trigger-api.service';

@Injectable()
export class LoyaltyTriggerPaginatorService extends BasePaginationService<LoyaltyTriggerInterface> implements OnDestroy {
  constructor(private api: LoyaltyTriggerApiService) {
    super(api);
  }

  ngOnDestroy() {
    super.destroy();
  }
}
