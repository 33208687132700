import { AbstractControl, ValidatorFn } from '@angular/forms';

const VALID_EXTENSIONS = ['gif', 'png', 'jpeg', 'jpg'];
const MAX_BYTE_SIZE = 1e7;

export function FileSize(maxFileSize?: number): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (!control.value || !control.value.size) return null;
    if (control.value.size <= (maxFileSize ? maxFileSize : MAX_BYTE_SIZE)) return null;
    const maxFileSizeMb = (maxFileSize ? maxFileSize : MAX_BYTE_SIZE) / (1024 * 1024);
    return { fileSize: maxFileSizeMb + ' MB'};
  };
}

export function FileExtension(validExtensions?: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value) {
      let extension;
      if (control.value.filename)
        extension = control.value.filename.substring(control.value.filename.lastIndexOf('.') + 1).toLowerCase();
      if (control.value.name)
        extension = control.value.name.substring(control.value.name.lastIndexOf('.') + 1).toLowerCase();

      if (validExtensions ? !validExtensions.includes(extension) : !VALID_EXTENSIONS.includes(extension)) {
        return { fileExtension: true };
      }
    }

    return null;
  };
}
