import { OffsetHttpParameters } from '@shared/utility/api';

export class SmsConversationParameters extends OffsetHttpParameters {
  protected conversation_uuid: string;
  protected cell_phone: string;
  protected with_archived: boolean;

  constructor(params?: Partial<SmsConversationParameters | OffsetHttpParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setWhereConversationUuid(uri?: string) {
    this.conversation_uuid = uri;
    return this;
  }

  getWhereConversationUuid(): string {
    return this.conversation_uuid;
  }

  setWhereCellPhone(uri?: string) {
    this.cell_phone = uri;
    return this;
  }

  getWhereCellPhone(): string {
    return this.cell_phone;
  }

  getWhereWithArchived(): boolean {
    return this.with_archived;
  }

  setWhereWithArchived(withArchived: boolean) {
    this.with_archived = withArchived;
    return this;
  }

  getParams(): any {
    return this.removeUndefinedValues({
      ...super.getParams(),
      ...(this.conversation_uuid
        ? { conversation_uuid: this.conversation_uuid }
        : undefined),
      ...(this.cell_phone
        ? { cell_phone: this.cell_phone }
        : undefined),
      ...(this.with_archived
        ? { with_archived: this.with_archived }
        : undefined)
    });
  }
}
