/**
 * THIS FILE IS AUTOGENERATED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model'; // change to wintermute config?
import { BaseApiService } from '@shared/utility/api';
import { SmsConversationModel } from '../model/sms-conversation.model';
import { Observable } from 'rxjs';
import { SmsConversationParameters } from './sms-conversation.parameters';
import { SmsConversationActionsService } from './sms-conversation-actions.service';

@Injectable({ providedIn: 'root' })
export class SmsConversationService extends BaseApiService {
  readonly basePath = '/v2/sms_conversations';

  constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }

  loadSmsConversations(smsConversationParameters: SmsConversationParameters): Observable<SmsConversationModel> {
    return this.http.post<SmsConversationModel>(this.getDoloresPath('/poll'), smsConversationParameters.getParams());
  }

  getSmsConversationActionsService(cellPhone: string): SmsConversationActionsService {
    return new SmsConversationActionsService(cellPhone, this.config, this.http);
  }
}
