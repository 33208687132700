import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { InventoryZoneEnum, RetailTransferZones, WholesaleTransferZones } from '@shared/entity/inventory/enumeration';
import { InventoryDataInterface, InventoryModel } from '@shared/entity/inventory/model';
import {
  InventoryActionTypes,
  inventoryQuery,
  UpsertInventory,
  UpsertInventoryFailure,
  UpsertInventorySuccess
} from '@shared/entity/inventory/state';
import { FrontendPolicyRolesEnum } from '@shared/policy';
import { NgxRolesService } from 'ngx-permissions';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

export interface InventoryTransferModalDataInterface {
  inventory: InventoryDataInterface;
}

@Component({
  selector: 'bb-inventory-transfer-modal',
  templateUrl: './inventory-transfer-modal.component.html',
  styleUrls: ['./inventory-transfer-modal.component.scss']
})
export class InventoryTransferModalComponent implements InventoryTransferModalDataInterface, OnInit, OnDestroy {
  inventory: InventoryDataInterface;
  wholesalerOrDispatcher: boolean;

  updating$: Observable<boolean>;

  zones: { id: InventoryZoneEnum; name: string; key: string }[];
  transferForm: FormGroup;
  destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) data: InventoryTransferModalDataInterface,
    private dialogRef: MatDialogRef<InventoryTransferModalComponent, InventoryDataInterface>,
    private _store: Store<unknown>,
    private actions$: Actions,
    private fb: FormBuilder,
    private ngxRolesService: NgxRolesService,
    private matSnackbar: MatSnackBar
  ) {
    const { inventory } = data;
    this.inventory = inventory;
    this.updating$ = this._store.pipe(select(inventoryQuery.getUpdating));
  }

  ngOnInit() {
    const wholesalerAndDispatchRoles = [
      FrontendPolicyRolesEnum.BLACKBIRD_DISPATCHER,
      FrontendPolicyRolesEnum.WHOLESALE_ADMIN,
      FrontendPolicyRolesEnum.WHOLESALE_MANAGER,
      FrontendPolicyRolesEnum.WHOLESALE_TELLER
    ];

    this.ngxRolesService.hasOnlyRoles(wholesalerAndDispatchRoles).then(hasRole => {
      this.wholesalerOrDispatcher = hasRole;
      this.zones = hasRole ? WholesaleTransferZones : RetailTransferZones;
    });

    this.initForm();
    this.subscribeActions();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initForm() {
    this.transferForm = this.fb.group({
      fromZone: [undefined, Validators.required],
      toZone: [undefined, Validators.required],
      quantity: [undefined, Validators.required],
      transfer_notes: undefined
    });

    this.subscribeForm();
  }

  subscribeActions() {
    this.actions$
      .pipe(
        ofType(InventoryActionTypes.UpsertInventorySuccess),
        map((action: UpsertInventorySuccess) => action.payload),
        take(1)
      )
      .subscribe(({ inventory }) => this.dialogRef.close(inventory));

    this.actions$
      .pipe(
        ofType(InventoryActionTypes.UpsertInventoryFailure),
        map((action: UpsertInventoryFailure) => action.payload.error),
        takeUntil(this.destroy$)
      )
      .subscribe(err => this.matSnackbar.open(err && err.error ? err.error : 'Error transferring stock'));
  }

  subscribeForm() {
    this.transferForm
      .get('fromZone')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(zone => {
        if (this.transferForm.get('toZone').value === zone) this.transferForm.get('toZone').reset();
        this.transferForm
          .get('quantity')
          .setValidators([Validators.required, Validators.min(1), Validators.max(this.inventory[zone])]);
        this.transferForm.get('quantity').updateValueAndValidity({ onlySelf: true });
      });
  }

  onSubmit() {
    if (this.transferForm.valid) {
      const data = this.transferForm.value;

      const updatedInventory = {
        ...this.inventory,
        [data.fromZone]: this.inventory[data.fromZone] - data.quantity,
        [data.toZone]: this.inventory[data.toZone] + data.quantity,
        transfer_notes: data.transfer_notes
      };

      this._store.dispatch(new UpsertInventory({ inventory: new InventoryModel(<any>updatedInventory) }));
    }
  }
}
