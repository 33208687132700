import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import { ConsumerOrderDetailInterface, ConsumerOrderInterface, PagyCollectionInterface } from '../interface';

@Injectable({
  providedIn: 'root'
})
export class ConsumerOrderApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/v2/consumer/orders`;
  }

  public list(params: any): Observable<PagyCollectionInterface<ConsumerOrderInterface>> {
    return this.http.get<PagyCollectionInterface<ConsumerOrderInterface>>(`${this.basePath}`, { params });
  }

  public show(uuid: string): Observable<ConsumerOrderDetailInterface> {
    return this.http.get<ConsumerOrderDetailInterface>(`${this.basePath}/${uuid}`);
  }
}
