import { Injectable, OnDestroy } from '@angular/core';
import { BasePaginationService } from './base-pagination.service';
import { InventoryInterface } from '../interface';
import { InventoryApiService } from './inventory-api.service';

@Injectable()
export class InventoryPaginatorService extends BasePaginationService<InventoryInterface> implements OnDestroy {
  constructor(private api: InventoryApiService) {
    super(api);
  }

  ngOnDestroy() {
    super.destroy();
  }
}
