import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { CollectionInterface } from '@shared/model/collection/collection.interface';
import { UploadModel } from '@shared/model/entity/upload/upload.model';
import { ErrorModel } from '@shared/model/error/error.model';
import { ToasterService } from 'angular2-toaster';
import { forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { InventoryModel } from '../../../inventory/model';
import { InventoryService } from '../../../inventory/service';
import {
  DeleteInventoryFile,
  DeleteInventoryFileFailure,
  DeleteInventoryFileSuccess,
  InventoryUploadActionTypes,
  LoadInventoryUploads,
  LoadInventoryUploadsFailure,
  LoadInventoryUploadsSuccess,
  UploadMultipleInventoryFiles,
  UploadMultipleInventoryFilesFailure,
  UploadMultipleInventoryFilesSuccess
} from '../actions/inventory-upload.actions';

@Injectable()
export class InventoryUploadEffects {
  @Effect()
  getInventoryUploads$ = this.actions$.pipe(
    ofType(InventoryUploadActionTypes.LoadInventoryUploads),
    map((action: LoadInventoryUploads) => action.payload),
    switchMap(data => {
      return this.inventoryService
        .getUploadService(new InventoryModel(data.inventory))
        .loadCollection(data.parameters)
        .pipe(
          map((collection: CollectionInterface<UploadModel>) => new LoadInventoryUploadsSuccess({ collection })),
          catchError((error: ErrorModel) => of(new LoadInventoryUploadsFailure({ error })))
        );
    })
  );

  @Effect()
  uploadMultipleInventoryFiles$ = this.actions$.pipe(
    ofType(InventoryUploadActionTypes.UploadMultipleInventoryFiles),
    map((action: UploadMultipleInventoryFiles) => action.payload),
    switchMap(data => {
      const operations = data.files.map(file => {
        return this.inventoryService
          .getUploadService(new InventoryModel(data.inventory))
          .insert(<any>file)
          .pipe(catchError(err => of(err)));
      });

      return forkJoin(operations).pipe(
        map(
          files => new UploadMultipleInventoryFilesSuccess({ files }),
          catchError((error: ErrorModel) => of(new UploadMultipleInventoryFilesFailure({ error })))
        )
      );
    })
  );

  @Effect()
  deleteInventoryFile$ = this.actions$.pipe(
    ofType(InventoryUploadActionTypes.DeleteInventoryFile),
    map((action: DeleteInventoryFile) => action.payload),
    switchMap(data => {
      return this.inventoryService
        .getUploadService(new InventoryModel(data.inventory))
        .delete(data.file)
        .pipe(
          map(() => new DeleteInventoryFileSuccess({ upload: data.file })),
          catchError((error: ErrorModel) => of(new DeleteInventoryFileFailure({ error })))
        );
    })
  );

  constructor(private actions$: Actions, private toaster: ToasterService, private inventoryService: InventoryService) {}
}
