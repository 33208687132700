import { CommonModule }          from '@angular/common';
import { NgModule }              from '@angular/core';
import { InlineLoaderComponent } from '../../../../component/loader/inline/inline-loader.component';

@NgModule({
  declarations: [InlineLoaderComponent],
  imports: [CommonModule],
  exports: [InlineLoaderComponent]

})
export class LoaderModule {
}