import { CommonModule }                   from '@angular/common';
import { NgModule }                       from '@angular/core';
import { FormsModule }                    from '@angular/forms';
import { ClickOutsideModule }             from 'ng4-click-outside';
import { VirtualScrollerModule }          from 'ngx-virtual-scroller';
import { SelectSearchEntityComponent }    from '../../../../component/search-entity/select-search-entity.component';
import { CustomPipesModule }              from '../../pipes/pipes.module';
import { LoaderModule }                   from '../loader/loader.module';


@NgModule({
  declarations: [
    SelectSearchEntityComponent
  ],
  imports: [
    CommonModule,
    LoaderModule,
    FormsModule,
    CustomPipesModule,
    VirtualScrollerModule,
    ClickOutsideModule
  ],
  exports: [
    SelectSearchEntityComponent
  ]

})

export class SelectSearchModule {
}
