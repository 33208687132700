import { Pipe, PipeTransform } from '@angular/core';

type SizeOptions = '0'|'0x'|'0x0'|'100x'|'150x'|'200x'|'350x'|'437x370'|'450x';

@Pipe({
  name: 'imageSize'
})
export class ImageSizePipe implements PipeTransform {
  transform(value: string | null | undefined, size: SizeOptions): any {
    if (!value || !value.includes('0x')) {
      return value;
    }

    return value.replace('\/0x', '/' + size);
  }
}
