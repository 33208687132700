<input
  matInput
  type="tel"
  [attr.data-private]="private ? 'lipsum' : null"
  [name]="'affd'"
  [placeholder]="placeholder"
  [attr.id]="id"
  [required]="required"
  [formControl]="formControl"
  [textMask]="{ mask: mask, keepCharPositions: false, guide: false }"
  (input)="onInput($event)"
/>
