import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedDialogComponentsModule } from '@shared/dialog';
import { ClickOutsideModule } from 'ng4-click-outside';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
import { SelectDeliveryStatusComponent } from '../../../_dispatch/component/shared/select-status/select-status.component';
import { AccordionComponent } from '../../component/accordion/accordion.component';
import { PanelComponent } from '../../component/accordion/panel.component';
import { PaginationComponent } from '../../component/pagination/pagination.component';
import { UploadCardComponent } from '../../component/upload/upload-card.component';
import { DeliveryFormComponent } from './components/delivery/form/delivery-form.component';
import { CustomDirectivesModule } from './directives/directives.module';
import { CustomPipesModule } from './pipes/pipes.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    PaginationComponent,
    SelectDeliveryStatusComponent,
    DeliveryFormComponent,
    PanelComponent,
    AccordionComponent,
    UploadCardComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomDirectivesModule,
    PerfectScrollbarModule,
    CustomPipesModule,
    ClickOutsideModule,
    MatTooltipModule,
    SharedDialogComponentsModule
  ],
  exports: [
    PaginationComponent,
    SelectDeliveryStatusComponent,
    DeliveryFormComponent,
    PanelComponent,
    AccordionComponent,
    UploadCardComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class SharedComponentsModule {}
