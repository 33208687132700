import { AuthContextInterface } from '@myblackbird/api/interface';
import { RolesEnum } from '@shared/entity/session/enumeration';
import { TypesEnum } from '@shared/enumeration/type/types.enum';
import { PolicyPermissions } from './policy.permissions';

export abstract class AbstractPolicy {
  readonly aclTarget: TypesEnum;
  protected tellerPermissions: PolicyPermissions[] = [];
  protected managerPermissions: PolicyPermissions[] = [];
  protected adminPermissions: PolicyPermissions[] = [];
  protected dispatcherPermissions: PolicyPermissions[] = [];
  protected driverPermissions: PolicyPermissions[] = [];

  constructor(protected acl: AuthContextInterface['current_acl']) {
    this.aclTarget = acl.target.type_id;
  }

  public get isLocationAcl(): boolean {
    return this.aclTarget === TypesEnum.LOCATIONS
  }

  public get isOrganizationAcl(): boolean {
    return this.aclTarget === TypesEnum.ORGANIZATIONS
  }

  public getPermissions(): PolicyPermissions[] {
    switch (this.acl.role) {
      case RolesEnum.TELLER:
        return this.tellerPermissions;
      case RolesEnum.MANAGER:
        return this.managerPermissions;
      case RolesEnum.ADMIN:
        return this.adminPermissions;
      case RolesEnum.DISPATCHER:
        return this.dispatcherPermissions;
      case RolesEnum.DRIVER:
        return this.driverPermissions;
      default:
        return [];
    }
  }
}
