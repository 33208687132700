import { Injectable, OnDestroy } from '@angular/core';
import { StationInterface } from '../interface';
import { BasePaginationService } from './base-pagination.service';
import { StationApiService } from './station-api.service';

@Injectable()
export class StationPaginatorService extends BasePaginationService<StationInterface> implements OnDestroy {
  constructor(private api: StationApiService) {
    super(api);
  }

  ngOnDestroy() {
    super.destroy();
  }
}
