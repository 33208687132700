import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import {
  CampaignRecipientFormInterface,
  CampaignRecipientListInterface,
  PagyCollectionInterface,
  TypedEntityInterface
} from '../interface';

interface RecipientCountsInterface {
  has_phones: number;
  has_emails: number;
}

export interface RecipientPageDataInterface {
  locations: TypedEntityInterface[];
}

@Injectable({
  providedIn: 'root'
})
export class CampaignRecipientListApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/campaign-recipient-lists`;
  }

  public loadPageData(uuid?: string): Observable<RecipientPageDataInterface> {
    return this.http.get<RecipientPageDataInterface>(`${this.basePath}/page-data`, { params: { uuid: uuid || '' } });
  }

  public list(params: any): Observable<PagyCollectionInterface<CampaignRecipientListInterface>> {
    return this.http.get<PagyCollectionInterface<CampaignRecipientListInterface>>(this.basePath, { params });
  }

  public upsert(params: any): Observable<CampaignRecipientFormInterface> {
    return this.http.post<CampaignRecipientFormInterface>(this.basePath, params);
  }

  public getCountsByQuery(query: string): Observable<RecipientCountsInterface> {
    return this.http.get<RecipientCountsInterface>(`${this.basePath}/counts`, { params: { query } });
  }

  public delete(uuid: string): Observable<void> {
    return this.http.delete<void>(`${this.basePath}/${uuid}`);
  }
}
