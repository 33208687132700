import { Injectable } from '@angular/core';
import { SmsConversationModel } from '@shared/entity/sms-conversation/model';
import { SmsConversationParameters, SmsConversationService } from '@shared/entity/sms-conversation/service';
import { BehaviorSubject, concat, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, delay, map, skip, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MessagePollingService {
  loadMessages$ = new BehaviorSubject('');
  smsConversationRequest$: Observable<SmsConversationModel>;
  poll$: Observable<string | SmsConversationModel>;
  searchParameters: SmsConversationParameters;

  polledMessages$: Subject<SmsConversationModel>;
  messagePollingSubscription: Subscription;

  whenToRefresh$: Observable<string>;

  constructor(private _smsConversationService: SmsConversationService) {
    this.whenToRefresh$ = of('').pipe(
      delay(60000),
      tap(() => this.loadMessages$.next('')),
      skip(1)
    );

    this.searchParameters = new SmsConversationParameters().setLimit(50);
    this.smsConversationRequest$ = this._smsConversationService
      .loadSmsConversations(this.searchParameters)
      .pipe(catchError(error => of(error)));
    this.poll$ = concat(this.smsConversationRequest$, this.whenToRefresh$);
    this.polledMessages$ = new Subject<SmsConversationModel>();
  }

  startPolling() {
    if (this.messagePollingSubscription) return;
    this.messagePollingSubscription = this.loadMessages$
      .pipe(switchMap(() => this.poll$.pipe(map((response: SmsConversationModel) => response))))
      .subscribe(conversation => this.polledMessages$.next(conversation));
  }

  subscribeMessagePolling() {
    return this.polledMessages$.asObservable();
  }

  refreshPolling() {
    this.loadMessages$.next('');
  }

  selectConversation(conversationUuid: string, withArchived: boolean) {
    this.searchParameters = new SmsConversationParameters({ ...this.searchParameters });
    this.smsConversationRequest$ = this._smsConversationService
      .loadSmsConversations(
        this.searchParameters
          .setWhereConversationUuid(conversationUuid)
          .setWhereWithArchived(withArchived)
      )
      .pipe(catchError(error => of(error)));
    this.poll$ = concat(this.smsConversationRequest$, this.whenToRefresh$);
    this.loadMessages$.next('');
  }

  searchConversation(conversationUri: string) {
    this.searchParameters = new SmsConversationParameters({ ...this.searchParameters });
    this.smsConversationRequest$ = this._smsConversationService
      .loadSmsConversations(this.searchParameters.setWhereCellPhone(conversationUri))
      .pipe(catchError(error => of(error)));
    this.poll$ = concat(this.smsConversationRequest$, this.whenToRefresh$);
    this.loadMessages$.next('');
  }

  unsubscribePolling() {
    this.messagePollingSubscription?.unsubscribe();
    this.messagePollingSubscription = null;
  }
}
