import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ConsumerEffects } from '../effects/consumer.effects';
import * as fromConsumer from '../reducers/consumer.reducer';
import { CONSUMER_KEY } from '../selectors/consumer.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(CONSUMER_KEY, fromConsumer.reducer),
    EffectsModule.forFeature([ConsumerEffects])
  ]
})
export class ConsumerStateModule {}
