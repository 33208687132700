import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map,take } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class LoggedInGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  private authFromStoreOrAPI(): Observable<boolean> {
    return this.authService.context$.pipe(
      map((context) => context === null),
      take(1),
    );
  }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    return this.authFromStoreOrAPI();
  }
}
