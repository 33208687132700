import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import { PagyCollectionInterface, UserInterface } from '../interface';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {
  baseUrl: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.baseUrl = `${this.config.baseUrl}/v2/users`;
  }

  public list(params: any): Observable<PagyCollectionInterface<UserInterface>> {
    return this.http.get<PagyCollectionInterface<UserInterface>>(`${this.baseUrl}`, { params });
  }

  // public show(uuid: string | null): Observable<RewardDetailInterface> {
  //   if (!uuid) {
  //     return of(null);
  //   }
  //   return this.http.get<RewardDetailInterface>(`${this.config.baseUrl}/rewards/${uuid}`);
  // }
  //
  // public upsert(params: any): Observable<RewardDetailInterface> {
  //   return this.http.post<RewardDetailInterface>(`${this.config.baseUrl}/rewards`, params);
  // }
  //
  // public delete(uuid: string): Observable<void> {
  //   return this.http.delete<void>(`${this.config.baseUrl}/rewards/${uuid}`);
  // }
  //
  // public dropdown(): Observable<ListItemInterface[]> {
  //   return this.http.get<ListItemInterface[]>(`${this.config.baseUrl}/rewards/dropdown`);
  // }
}
