import { Injectable, OnDestroy } from '@angular/core';
import { DiscountInterface } from '../interface';
import { BasePaginationService } from './base-pagination.service';
import { DiscountApiService } from './discount-api.service';

@Injectable()
export class DiscountPaginatorService extends BasePaginationService<DiscountInterface> implements OnDestroy {
  constructor(private api: DiscountApiService) {
    super(api);
  }

  ngOnDestroy() {
    super.destroy();
  }
}
