import { OverlayModule } from '@angular/cdk/overlay';
import { APP_BASE_HREF, isPlatformServer } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconRegistry } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ApplicationsEnum, AppNameTokenProviderModule, ENVIRONMENT } from '@frontend/shared/token-provider';
import { LocationConsumerInterestFeatureStateModule } from '@frontend/state';
import { LoaderModule } from '@myblackbird/shared/component/loader';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NxModule } from '@nrwl/angular';
import { GoogleTagManagerModule } from '@shared/analytics';
import { BrowserCookiesModule } from '@shared/cookie/browser';
import { SharedDialogComponentsModule } from '@shared/dialog';
import { LocationStateModule } from '@shared/entity/location/state';
import { SessionStateModule } from '@shared/entity/session/state';
import { TypeFactory } from '@shared/enumeration/type/factory/type.factory';
import { ImageLoadModule } from '@shared/image-load';
import { IntercomModule } from '@shared/intercom';
import { LogRocketModule } from '@shared/log-rocket';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { QzModule } from '@shared/qz-tray';
import { ApiParametersFactory } from '@shared/utility/api/factory/api-parameters.factory';
import { ApiServiceFactory } from '@shared/utility/api/factory/api-service.factory';
import { EncodeHttpParamsInterceptor } from '@shared/utility/encode-http-params.interceptor';
import { WindowModule } from '@shared/window';
import { ToasterService } from 'angular2-toaster';
import * as moment from 'moment';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxPermissionsModule } from 'ngx-permissions';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { HeaderModule } from './shared/module/header.module';
import { ModalModule } from './shared/module/modal.module';
import { SharedModModule } from './shared/module/shared/shared.module';
import { FeatureFlagApiService, FeatureFlagStateService } from '@myblackbird/api/service';
import { AuthInterceptor } from '@myblackbird/api/interceptor';

export function apiServiceFactory(apiConfig: ApiConfig, http: HttpClient) {
  return new ApiServiceFactory(apiConfig, http);
}

export function apiParametersFactory() {
  return new ApiParametersFactory();
}

export function typeFactory() {
  return new TypeFactory();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    MatProgressBarModule,
    OverlayModule,
    BrowserAnimationsModule,
    ModalModule,
    SharedDialogComponentsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserCookiesModule.forRoot({
      expires: moment().add(1, 'days').toDate(),
      secure: environment.prod || environment.staging || environment.develop,
      httpOnly: false
    }),
    NgxPermissionsModule.forRoot(),
    AppNameTokenProviderModule.forRoot(ApplicationsEnum.MYBLACKBIRD),
    NxModule.forRoot(),
    WindowModule.forRoot(),
    NgxMaterialTimepickerModule,
    HeaderModule,
    SharedModModule,
    LoaderModule,
    IntercomModule.forRoot({
      appId: environment.intercomId,
      updateOnRouterChange: true
    }),
    StoreModule.forRoot({}, {
      runtimeChecks: { strictStateImmutability: false, strictActionImmutability: false }
    }),
    !environment.prod ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([]),
    LocationStateModule,
    SessionStateModule,
    LocationConsumerInterestFeatureStateModule,
    MatMomentDateModule,
    MatSnackBarModule,
    QzModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.develop || environment.staging }),
    ImageLoadModule.forRoot({ errorPath: '/assets/images/bbgo-fallback.svg' }),
    GoogleTagManagerModule.forRoot(environment.googleTagManager, { trackPageViews: true }),
    LogRocketModule.forRoot({
      appId: environment.logRocketId,
      lazyLoad: true,
      options: {
        network: {
          requestSanitizer: request => {
            if (request.url.includes('session') && request.method && request.method.toLowerCase() === 'post') {
              request.body = null;
            }

            if (request.headers['Authorization']) {
              request.headers['Authorization'] = '';
            }

            return request;
          },
          responseSanitizer: response => {
            if (response.headers['bearer']) {
              response.headers['bearer'] = '';
            }

            return response;
          }
        }
      }
    })
  ],
  entryComponents: [AppComponent],
  providers: [
    {
      provide: ApiConfig,
      useValue: {
        baseUrl: `${environment.apiUrl}/api`,
        doloresUrl: `${environment.doloresUrl}`
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeHttpParamsInterceptor,
      multi: true
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
    {
      provide: ApiServiceFactory,
      useFactory: apiServiceFactory,
      deps: [ApiConfig, HttpClient]
    },
    {
      provide: ApiParametersFactory,
      useFactory: apiParametersFactory,
      deps: []
    },
    {
      provide: TypeFactory,
      useFactory: typeFactory,
      deps: []
    },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        ...MAT_DIALOG_DEFAULT_OPTIONS,
        wrapperClass: 'modal fade modal-fade-scale-up',
        closeOnNavigation: true,
        hasBackdrop: true,
        width: 568,
        maxHeight: '100vh'
      }
    },
    MatIconRegistry,
    ToasterService,
    { provide: ENVIRONMENT, useValue: environment },
    {
      provide: APP_INITIALIZER,
      useFactory: (apiService: FeatureFlagApiService, state: FeatureFlagStateService) => () => {
        return new Promise<void>((resolve, _reject) => {
          apiService.loadFeatureFlags().subscribe({
            next: flags => {
              state.setFlags(flags);
              resolve();
            },
            error: () => {
              resolve();
            }
          });
        });
      },
      multi: true,
      deps: [FeatureFlagApiService, FeatureFlagStateService]
    }
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {
  constructor(@Inject(PLATFORM_ID) platformId: Object, matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.registerFontClassAlias('fontawesome', 'fa');

    const absUrl = isPlatformServer(platformId) ? environment.url : '';

    matIconRegistry.addSvgIcon(
      'concentrate',
      domSanitizer.bypassSecurityTrustResourceUrl(`${absUrl}/assets/icons/interests/concentrate.svg`)
    );

    matIconRegistry.addSvgIcon(
      'edible',
      domSanitizer.bypassSecurityTrustResourceUrl(`${absUrl}/assets/icons/interests/edible.svg`)
    );

    matIconRegistry.addSvgIcon(
      'flower',
      domSanitizer.bypassSecurityTrustResourceUrl(`${absUrl}/assets/icons/interests/flower.svg`)
    );

    matIconRegistry.addSvgIcon(
      'preroll',
      domSanitizer.bypassSecurityTrustResourceUrl(`${absUrl}/assets/icons/interests/pre-roll.svg`)
    );

    matIconRegistry.addSvgIcon(
      'topical',
      domSanitizer.bypassSecurityTrustResourceUrl(`${absUrl}/assets/icons/interests/topical.svg`)
    );

    matIconRegistry.addSvgIcon(
      'vape',
      domSanitizer.bypassSecurityTrustResourceUrl(`${absUrl}/assets/icons/interests/vape.svg`)
    );

    matIconRegistry.addSvgIcon(
      'cbd',
      domSanitizer.bypassSecurityTrustResourceUrl(`${absUrl}/assets/icons/interests/cbd.svg`)
    );

    matIconRegistry.addSvgIcon(
      'hybrid',
      domSanitizer.bypassSecurityTrustResourceUrl(`${absUrl}/assets/icons/interests/hybrid.svg`)
    );

    matIconRegistry.addSvgIcon(
      'indica',
      domSanitizer.bypassSecurityTrustResourceUrl(`${absUrl}/assets/icons/interests/indica.svg`)
    );

    matIconRegistry.addSvgIcon(
      'cbd-thc',
      domSanitizer.bypassSecurityTrustResourceUrl(`${absUrl}/assets/icons/interests/cbd-thc.svg`)
    );

    matIconRegistry.addSvgIcon(
      'sativa',
      domSanitizer.bypassSecurityTrustResourceUrl(`${absUrl}/assets/icons/interests/sativa.svg`)
    );

    matIconRegistry.addSvgIcon(
      'star',
      domSanitizer.bypassSecurityTrustResourceUrl(`${absUrl}/assets/icons/interests/star.svg`)
    );

    matIconRegistry.addSvgIcon(
      'bb-bird',
      domSanitizer.bypassSecurityTrustResourceUrl(`${absUrl}/assets/icons/bb-bird.svg`)
    );

    matIconRegistry.addSvgIcon(
      'loyalty',
      domSanitizer.bypassSecurityTrustResourceUrl(`${absUrl}/assets/icons/trophy.svg`)
    );
  }
}
