import { CommonModule, isPlatformBrowser } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { Inject, ModuleWithProviders, NgModule, Optional, PLATFORM_ID, SkipSelf } from '@angular/core';
import { LogRocketConfig } from './log-rocket.config';
import { LogRocketService } from './log-rocket.service';

@NgModule({
  imports: [CommonModule, HttpClientModule, HttpClientJsonpModule]
})
export class LogRocketModule {

  constructor(
    config: LogRocketConfig,
    logRocketService: LogRocketService,
    @Inject(PLATFORM_ID) platform: object,
    @Optional() @SkipSelf() parentModule?: LogRocketModule
  ) {
    if (parentModule) {
      throw new Error('LogRocket module is already loaded. Import it in the AppModule only');
    } else if (isPlatformBrowser(platform) && config.lazyLoad === false) {
      logRocketService.initialize();
    }
  }

  static forRoot(config: LogRocketConfig): ModuleWithProviders<LogRocketModule> {
    return {
      ngModule: LogRocketModule,
      providers: [{ provide: LogRocketConfig, useValue: config }]
    };
  }
}
