import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import { EmployeeInterface } from '../interface';

@Injectable({
  providedIn: 'root'
})
export class EmployeeApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/employees`;
  }

  public autocomplete(query: string | null, exists: 'email' | 'cell_phone' | null): Observable<EmployeeInterface[]> {
    return this.http.get<EmployeeInterface[]>(`${this.basePath}/autocomplete`, { params: { query, exists } });
  }
}
