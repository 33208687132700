import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';

export class ListingUploadService {

  constructor(private listing: { uuid: string }, private config: ApiConfig, private http: HttpClient) {}

  public delete(listing_upload_uuid: string): Observable<{ success: true }> {
    return this.http.delete<{ success: true }>(`${this.config.doloresUrl}/v2/listings/${this.listing.uuid}/listing_uploads/${listing_upload_uuid}`);
  }

}
