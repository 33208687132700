import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TypesEnum } from '@shared/enumeration/type/types.enum';
import { Observable, of } from 'rxjs';
import { catchError, map, skip, switchMap, take } from 'rxjs/operators';
import { ConsumerModel } from '../../../consumer/model';
import { consumerQuery, LoadConsumer } from '../../../consumer/state';
import { OrderModel } from '../../model';
import { OrderService } from '../../service/order.service';
import * as actions from '../actions/order.actions';
import { OrderActions, OrderActionTypes } from '../actions/order.actions';

@Injectable()
export class OrderEffects {
  selectedConsumer$: Observable<ConsumerModel>;

  @Effect()
  loadCollection$ = this.actions$.pipe(
    ofType(OrderActionTypes.LoadOrderCollection),
    map((action: actions.LoadOrderCollection) => action.payload),
    switchMap(data => {
      const { parameters, preserveExistingRecords } = data;

      return this.orderService.loadCollection(parameters).pipe(
        map(collection => new actions.LoadOrderCollectionSuccess({ collection, preserveExistingRecords })),
        catchError(error => of(new actions.LoadOrderCollectionFailure({ error })))
      );
    })
  );

  @Effect()
  loadOrder$ = this.actions$.pipe(
    ofType(OrderActionTypes.LoadOrder),
    map((action: actions.LoadOrder) => action.payload),
    switchMap(data => {
      const { order, eagerLoadCustomer } = data;

      return this.orderService.load(order).pipe(
        switchMap((loadedOrder: OrderModel) => {
          if (eagerLoadCustomer && loadedOrder.customer && loadedOrder.customer._type === TypesEnum.CONSUMERS) {
            return this.eagerLoadConsumer$(loadedOrder).pipe(
              map(
                consumer =>
                  new actions.LoadOrderSuccess({
                    order: <OrderModel>{
                      ...loadedOrder,
                      customer: consumer
                    }
                  })
              ),
              catchError(error => of(new actions.LoadOrderSuccess({ order: loadedOrder })))
            );
          }

          return of(
            new actions.LoadOrderSuccess({
              order: {
                ...loadedOrder,
                customer: order.customer ? order.customer : loadedOrder.customer
              } as OrderModel
            })
          );
        }),
        catchError(error => of(new actions.LoadOrderFailure({ error })))
      );
    })
  );

  @Effect()
  upsertOrder$ = this.actions$.pipe(
    ofType(OrderActionTypes.UpsertOrder),
    map((action: actions.UpsertOrder) => action.payload),
    switchMap(data => {
      const { order, eagerLoadCustomer } = data;

      return this.orderService.upsert(order).pipe(
        switchMap((updatedOrder: OrderModel) => {
          if (eagerLoadCustomer && updatedOrder.customer && updatedOrder.customer._type === TypesEnum.CONSUMERS) {
            return this.eagerLoadConsumer$(updatedOrder).pipe(
              map(
                consumer =>
                  new actions.UpsertOrderSuccess({
                    order: new OrderModel({
                      ...updatedOrder,
                      customer: new ConsumerModel(consumer)
                    })
                  })
              ),
              catchError(error => of(new actions.UpsertOrderSuccess({ order: updatedOrder })))
            );
          }

          return of(
            new actions.UpsertOrderSuccess({
              order: {
                ...updatedOrder,
                customer: order.customer ? order.customer : updatedOrder.customer
              } as OrderModel
            })
          );
        }),
        catchError(error => of(new actions.UpsertOrderFailure({ error })))
      );
    })
  );

  @Effect()
  removeOrder$ = this.actions$.pipe(
    ofType(OrderActionTypes.DeleteOrder),
    map((action: actions.DeleteOrder) => action.payload),
    switchMap(data => {
      const { records } = data;

      return this.orderService.delete(records).pipe(
        map(records => new actions.DeleteOrderSuccess({ records })),
        catchError(error => of(new actions.DeleteOrderFailure({ error })))
      );
    })
  );

  @Effect()
  deletePreauthorizedTransaction$ = this.actions$.pipe(
    ofType(OrderActionTypes.DeletePreauthorizedTransaction),
    map((action: actions.DeletePreauthorizedTransaction) => action.payload),
    switchMap(data => {
      const { order, transaction } = data;

      return this.orderService.getPreauthorizedTransactionService(order).cancel(transaction).pipe(
        map(() => new actions.DeletePreauthorizedTransactionSuccess()),
        catchError(error => of(new actions.DeletePreauthorizedTransactionFailure(error)))
      );
    })
  );

  constructor(
    private actions$: Actions<OrderActions>,
    private store: Store<unknown>,
    private orderService: OrderService
  ) {
    this.selectedConsumer$ = this.store.pipe(select(consumerQuery.getSelected));
  }

  eagerLoadConsumer$(order: OrderModel): Observable<ConsumerModel> {
    this.store.dispatch(
      new LoadConsumer({
        consumer: order.customer as ConsumerModel,
        eagerLoadPendingRewards: true
      })
    );

    return this.selectedConsumer$.pipe(
      skip(1),
      take(1),
      map(consumer => new ConsumerModel(consumer))
    );
  }
}
