import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, State } from '../reducers/inventory-upload.reducer';

export const INVENTORY_UPLOAD_KEY = 'INVENTORY-UPLOAD';

const getInventoryUploadState = createFeatureSelector<State>(INVENTORY_UPLOAD_KEY);

const getLoading = createSelector(
  getInventoryUploadState,
  (state: State) => state.loading
);

const getUpdating = createSelector(
  getInventoryUploadState,
  (state: State) => state.updating
);

const getError = createSelector(
  getInventoryUploadState,
  (state: State) => state.error
);

const getCurrentPage = createSelector(
  getInventoryUploadState,
  (state: State) => state.currentPage
);

const getTotalPages = createSelector(
  getInventoryUploadState,
  (state: State) => state.totalPages
);

const getTotalRecords = createSelector(
  getInventoryUploadState,
  (state: State) => state.totalRecords
);

const getPagination = createSelector(
  getTotalPages,
  getCurrentPage,
  getTotalRecords,
  (total, current, records) => ({
    totalPages: total,
    currentPage: current,
    totalRecords: records
  })
);

const getCanLoadMore = createSelector(
  getCurrentPage,
  getTotalPages,
  (current, total) => current < total
);

export const inventoryUploadQuery = {
  ...adapter.getSelectors(getInventoryUploadState),
  getLoading,
  getUpdating,
  getError,
  getCurrentPage,
  getTotalPages,
  getTotalRecords,
  getPagination,
  getCanLoadMore
};
