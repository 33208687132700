import { NgModule }            from '@angular/core';
import { RemoveDashPipe }      from '../../../pipe/remove-dash.pipe';
import { DeliveryMileagePipe } from '../../../pipe/delivery-mileage.pipe';
import { ImageSizePipe }       from '../../../pipe/image-size.pipe';
import { PercentToMgPipe }     from '../../../pipe/percent-to-mg.pipe';
import { PhonePipe }           from '../../../pipe/phone.pipe';
import { RetailTimerPipe }     from '../../../pipe/retail-timer.pipe';
import { EntityNamePipe }      from '../../../pipe/entity-name.pipe';
import { VehiclePipe }         from '../../../pipe/vehicle.pipe';


@NgModule({
  declarations: [
    ImageSizePipe,
    PercentToMgPipe,
    PhonePipe,
    DeliveryMileagePipe,
    RetailTimerPipe,
    EntityNamePipe,
    RemoveDashPipe,
    VehiclePipe
  ],
  exports: [
    ImageSizePipe,
    PercentToMgPipe,
    PhonePipe,
    DeliveryMileagePipe,
    RetailTimerPipe,
    EntityNamePipe,
    RemoveDashPipe,
    VehiclePipe
  ],
  providers: [DeliveryMileagePipe]

})

export class CustomPipesModule {
}
