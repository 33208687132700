import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import { ServiceInterface } from '../interface/service.interface';

@Injectable({
  providedIn: 'root'
})
export class ServiceApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/v2/services`;
  }

  public dropdown(): Observable<ServiceInterface[]> {
    return this.http.get<ServiceInterface[]>(`${this.basePath}/dropdown`);
  }
}
