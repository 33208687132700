<h1 matDialogTitle>{{ title }}</h1>
<mat-dialog-content>
  <p>{{ message }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button *ngIf="showCancelButton !== false" [mat-dialog-close] [attr.data-cy]="'cancel'">
    {{ cancelButtonCopy }}
  </button>
  <button
    mat-button
    cdkFocusInitial
    *ngIf="showConfirmButton !== false"
    [color]="confirmButtonColor"
    [mat-dialog-close]="true"
    [attr.data-cy]="'confirm'"
  >
    {{ confirmButtonCopy }}
  </button>
</mat-dialog-actions>
