import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { UploadModel } from '@shared/model/entity/upload/upload.model';
import { ErrorModel } from '@shared/model/error/error.model';
import { InventoryUploadActions, InventoryUploadActionTypes } from '../actions/inventory-upload.actions';

export interface State extends EntityState<UploadModel> {
  loading: boolean;
  updating: boolean;
  selected: string;
  currentPage: number;
  totalPages: number;
  totalRecords: number;
  error: ErrorModel;
}

export const adapter: EntityAdapter<UploadModel> = createEntityAdapter<UploadModel>({
  selectId: (uploadModel: UploadModel) => uploadModel.uuid
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  updating: false,
  selected: undefined,
  currentPage: undefined,
  totalPages: undefined,
  totalRecords: undefined,
  error: undefined
});

export function reducer(state = initialState, action: InventoryUploadActions): State {
  switch (action.type) {
    case InventoryUploadActionTypes.LoadInventoryUploads: {
      return {
        ...state,
        loading: true
      };
    }

    case InventoryUploadActionTypes.UploadMultipleInventoryFiles:
    case InventoryUploadActionTypes.DeleteInventoryFile: {
      return {
        ...state,
        updating: true
      };
    }

    case InventoryUploadActionTypes.LoadInventoryUploadsSuccess: {
      const { collection } = action.payload;

      return adapter.setAll(collection.records, {
        ...state,
        loading: false,
        totalRecords: collection.total_records,
        totalPages: collection.total_pages,
        currentPage: collection.current_page
      });
    }

    case InventoryUploadActionTypes.UploadMultipleInventoryFilesSuccess: {
      const { files } = action.payload;
      return adapter.upsertMany(files, { ...state, updating: false });
    }

    case InventoryUploadActionTypes.DeleteInventoryFileSuccess: {
      const { upload } = action.payload;
      return adapter.removeOne(upload.uuid, { ...state, updating: false });
    }

    case InventoryUploadActionTypes.LoadInventoryUploadsFailure:
    case InventoryUploadActionTypes.UploadMultipleInventoryFilesFailure:
    case InventoryUploadActionTypes.DeleteInventoryFileFailure: {
      const { error } = action.payload;
      return { ...state, loading: false, updating: false, error };
    }

    case InventoryUploadActionTypes.ClearInventoryUploadState: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
