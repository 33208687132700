import { NgModule }             from '@angular/core';
import { AddClassDirective }    from '../../../directive/add-class.directive';
import { ImageDirective }       from '../../../directive/image.directive';
import { AutoFocusDirective } from '../../../directive/auto-focus.directive';


@NgModule({
  declarations: [
    AddClassDirective,
    ImageDirective,
    AutoFocusDirective
  ],
  exports: [
    AddClassDirective,
    ImageDirective,
    AutoFocusDirective
  ]

})

export class CustomDirectivesModule {
}
