<div mat-dialog-title>
  <h3>Select Printer</h3>
  <p class="mat-caption mat-hint">Used for printing pick lists and order receipts</p>
</div>
<ng-container *ngIf="!loadingPrinters; else loadingTpl">
  <div class="print-select-container" mat-dialog-content>
    <mat-radio-group [formControl]="printFormCtrl" aria-label="Select a printer">
      <mat-radio-button color="primary" *ngFor="let printer of printers" [value]="printer" labelPosition="before">
        {{ printer }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <p class="mat-caption mat-hint margin-top">
    If you are not printing and would like to permanently disable this pop up when completing orders, you can turn
    receipt printing off in the printer configuration settings in the top right
  </p>
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]>Close</button>
    <button mat-button color="primary" [disabled]="!printFormCtrl.value" [mat-dialog-close]="printFormCtrl.value">
      Select
    </button>
  </div>
</ng-container>

<ng-template #loadingTpl>
  <div mat-dialog-content>
    <mat-progress-spinner mode="indeterminate" strokeWidth="2" diameter="20"></mat-progress-spinner>
  </div>
</ng-template>
