/**
 * THIS FILE IS AUTOGENERATED
 */

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorModel } from '@shared/model/error/error.model';
import { LocationModel } from '../../model/location.model';
import { LocationActions, LocationActionTypes } from '../actions/location.actions';

export interface State extends EntityState<LocationModel> {
  loading: boolean;
  updating: boolean;
  selected: string;
  currentPage: number;
  totalPages: number;
  totalRecords: number;
  error: ErrorModel | ErrorModel[];
}

export const adapter: EntityAdapter<LocationModel> = createEntityAdapter<LocationModel>({
  selectId: (locationModel: LocationModel) => locationModel.uuid
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  updating: false,
  selected: undefined,
  currentPage: undefined,
  totalPages: undefined,
  totalRecords: undefined,
  error: undefined
});

export function reducer(state = initialState, action: LocationActions): State {
  switch (action.type) {
    case LocationActionTypes.LoadLocationCollection:
    case LocationActionTypes.LoadLocation: {
      return {
        ...state,
        loading: true
      };
    }

    case LocationActionTypes.UpsertLocation:
    case LocationActionTypes.DeleteLocation: {
      return {
        ...state,
        updating: true
      };
    }

    case LocationActionTypes.LoadLocationCollectionSuccess: {
      const { collection, preserveExistingRecords } = action.payload;
      const method = preserveExistingRecords ? 'addMany' : 'setAll';

      return adapter[method](collection.records, {
        ...state,
        loading: false,
        totalRecords: collection.total_records,
        totalPages: collection.total_pages,
        currentPage: collection.current_page
      });
    }

    case LocationActionTypes.LoadLocationSuccess: {
      const { records } = action.payload;

      return Array.isArray(records)
        ? adapter.upsertMany(records as LocationModel[], { ...state, loading: false })
        : adapter.upsertOne(records as LocationModel, { ...state, selected: records.uuid, loading: false });
    }

    case LocationActionTypes.UpsertLocationSuccess: {
      const { records } = action.payload;

      return Array.isArray(records)
        ? adapter.upsertMany(records as LocationModel[], { ...state, updating: false })
        : adapter.upsertOne(records as LocationModel, { ...state, updating: false });
    }

    case LocationActionTypes.DeleteLocationSuccess: {
      const { records } = action.payload;

      if (Array.isArray(records)) {
        const ids = records.map(record => record.uuid);
        return adapter.removeMany(ids, { ...state, updating: false });
      } else {
        const id = records.uuid;
        return adapter.removeOne(id, { ...state, updating: false });
      }
    }

    case LocationActionTypes.LoadLocationCollectionFailure:
    case LocationActionTypes.LoadLocationFailure:
    case LocationActionTypes.UpsertLocationFailure:
    case LocationActionTypes.DeleteLocationFailure: {
      const { error } = action.payload;
      return { ...state, loading: false, updating: false, error };
    }

    case LocationActionTypes.ClearLocationState: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
