import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import { AutocompleteResponseInterface, PagyCollectionInterface, ProductInterface } from '../interface';

@Injectable({
  providedIn: 'root'
})
export class ProductApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/product`;
  }

  public list(params: any): Observable<PagyCollectionInterface<ProductInterface>> {
    return this.http.get<PagyCollectionInterface<ProductInterface>>(`${this.basePath}/list`, { params });
  }

  public autocomplete(params: any): Observable<AutocompleteResponseInterface<ProductInterface>> {
    return this.http.get<AutocompleteResponseInterface<ProductInterface>>(`${this.basePath}/autocomplete`, { params });
  }
}
