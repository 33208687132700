import { AfterContentInit, Component, Input, OnChanges } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'validation-errors',
  templateUrl: './validation-errors.component.html',
  styleUrls: ['./validation-errors.component.scss']
})
export class ValidationErrorsComponent implements AfterContentInit, OnChanges {
  @Input() for: FormControl;
  @Input() validationErrors: ValidationErrors;

  subscription: Subscription;
  error: string;

  ngOnChanges() {
    if (this.for) {
      this.subscribeStatusChanges();
    }
  }

  ngAfterContentInit() {
    this.checkErrors();
  }

  subscribeStatusChanges() {
    if (this.subscription) this.subscription.unsubscribe();
    this.subscription = this.for.statusChanges.subscribe(() => this.checkErrors());
  }

  checkErrors() {
    if (this.for) {
      const { invalid, errors } = this.for;

      if (invalid && errors) {
        this.error = Object.keys(errors).find(error => !!error);
      } else {
        this.error = undefined;
      }
    }

    if (this.validationErrors) {
      this.error = Object.keys(this.validationErrors).find(error => !!error);
    }
  }
}
