import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { InventoryUploadEffects } from '../effects/inventory-upload.effects';
import * as fromInventoryUpload from '../reducers/inventory-upload.reducer';
import { INVENTORY_UPLOAD_KEY } from '../selectors/inventory-upload.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(INVENTORY_UPLOAD_KEY, fromInventoryUpload.reducer),
    EffectsModule.forFeature([InventoryUploadEffects])
  ]
})
export class InventoryUploadStateModule {}
