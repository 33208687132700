import { CollectionInterface } from '@shared/model/collection/collection.interface';
import { UploadModel } from '@shared/model/entity/upload/upload.model';
import { ErrorModel } from '@shared/model/error/error.model';
import { OffsetHttpParameters } from '@shared/utility/api';
import { InventoryDataInterface } from '../../../inventory/model';

export enum InventoryUploadActionTypes {
  LoadInventoryUploads = '[Inventory Upload] Load Inventory Uploads',
  LoadInventoryUploadsSuccess = '[Inventory Upload] Load Inventory Uploads Success',
  LoadInventoryUploadsFailure = '[Inventory Upload] Load Inventory Uploads Failure',

  UploadMultipleInventoryFiles = '[Inventory Upload] Upload Multiple Files',
  UploadMultipleInventoryFilesSuccess = '[Inventory Upload] Upload Multiple Files Success',
  UploadMultipleInventoryFilesFailure = '[Inventory Upload] Upload Multiple Files Failure',

  DeleteInventoryFile = '[Inventory Upload] Delete File',
  DeleteInventoryFileSuccess = '[Inventory Upload] Delete File Success',
  DeleteInventoryFileFailure = '[Inventory Upload] Delete File Failure',

  ClearInventoryUploadState = '[Inventory Upload] Clear State'
}

export class LoadInventoryUploads {
  readonly type = InventoryUploadActionTypes.LoadInventoryUploads;

  constructor(public payload: { inventory: InventoryDataInterface; parameters: OffsetHttpParameters }) {}
}

export class LoadInventoryUploadsSuccess {
  readonly type = InventoryUploadActionTypes.LoadInventoryUploadsSuccess;

  constructor(public payload: { collection: CollectionInterface<UploadModel> }) {}
}

export class LoadInventoryUploadsFailure {
  readonly type = InventoryUploadActionTypes.LoadInventoryUploadsFailure;

  constructor(public payload: { error: ErrorModel }) {}
}

export class UploadMultipleInventoryFiles {
  readonly type = InventoryUploadActionTypes.UploadMultipleInventoryFiles;

  constructor(public payload: { inventory: InventoryDataInterface; files: FormData[] }) {}
}

export class UploadMultipleInventoryFilesSuccess {
  readonly type = InventoryUploadActionTypes.UploadMultipleInventoryFilesSuccess;

  constructor(public payload: { files: UploadModel[] }) {}
}

export class UploadMultipleInventoryFilesFailure {
  readonly type = InventoryUploadActionTypes.UploadMultipleInventoryFilesFailure;

  constructor(public payload: { error: ErrorModel }) {}
}

export class DeleteInventoryFile {
  readonly type = InventoryUploadActionTypes.DeleteInventoryFile;

  constructor(public payload: { inventory: InventoryDataInterface; file: UploadModel }) {}
}

export class DeleteInventoryFileSuccess {
  readonly type = InventoryUploadActionTypes.DeleteInventoryFileSuccess;

  constructor(public payload: { upload: UploadModel }) {}
}

export class DeleteInventoryFileFailure {
  readonly type = InventoryUploadActionTypes.DeleteInventoryFileFailure;

  constructor(public payload: { error: ErrorModel }) {}
}

export class ClearInventoryUploadState {
  readonly type = InventoryUploadActionTypes.ClearInventoryUploadState;
}

export type InventoryUploadActions =
  | LoadInventoryUploads
  | LoadInventoryUploadsSuccess
  | LoadInventoryUploadsFailure
  | UploadMultipleInventoryFiles
  | UploadMultipleInventoryFilesFailure
  | UploadMultipleInventoryFilesSuccess
  | DeleteInventoryFile
  | DeleteInventoryFileSuccess
  | DeleteInventoryFileFailure
  | ClearInventoryUploadState;
