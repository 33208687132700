import { AbstractControl, ValidationErrors } from '@angular/forms';

export function TimeRangeValidator(control: AbstractControl): ValidationErrors {
  if (
    control.get('start') &&
    control.get('stop') &&
    ((control.get('start').value && !control.get('stop').value) ||
      (!control.get('start').value && control.get('stop').value))
  ) {
    return { startStopRequired: 'Please fill out both start and end times' };
  } else {
    return null;
  }
}
