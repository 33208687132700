<div mat-dialog-title>
  <h3>QZ Connection Error</h3>
</div>
<ng-container>
  <div class="content-container" mat-dialog-content>
    <img src="/assets/images/qz-logo.png" alt="QZ Tray Icon" />
    <h4>We cannot connect to QZ Tray</h4>
    <p class="mat-hint">Please ensure the application is running.</p>
  </div>
  <div mat-dialog-actions align="right">
    <button mat-button [mat-dialog-close]>Continue Without Printing</button>
    <button mat-button color="primary" [disabled]="reconnecting" (click)="onReconnect()">
      Reconnect
      <mat-progress-spinner
        style="display: inline; margin-left: 8px;"
        *ngIf="reconnecting"
        mode="indeterminate"
        strokeWidth="2"
        diameter="20"
      ></mat-progress-spinner>
    </button>
  </div>
</ng-container>
