import { AbstractControl, ValidationErrors } from '@angular/forms';
import { AllAvailableInventoryZones } from '@shared/entity/inventory/enumeration';
import { TerpeneLevelInterface } from '@shared/entity/inventory/model';

export class InventoryValidators {
  static trackedStockRequired(control: AbstractControl): ValidationErrors | null {
    if (!control.get('tracked') || !control.get('tracked').value) {
      return null;
    }

    const totalStock = AllAvailableInventoryZones.reduce((acc, zone) => {
      if (control.get(zone.key)) {
        return Number(control.get(zone.key).value) + acc;
      }

      return acc;
    }, 0);

    return totalStock ? null : { stockRequired: true };
  }

  static batchNameRequired(control: AbstractControl): ValidationErrors | null {
    let needsBatchName: boolean = false;
    const ctrlFields = [
      'batch_date',
      'test_date',
      'expiration_date',
      'package_date',
      'thc_percent',
      'cbd_percent',
      'cbn_percent'
    ];

    if (control.get('batch') && !control.get('batch').value) {
      ctrlFields.forEach((controlName: string) => {
        if (control.get(controlName) && control.get(controlName).value != null) {
          needsBatchName = true;
          return needsBatchName;
        }
      });

      if (
        control.get('terpene_levels') &&
        Array.isArray(control.get('terpene_levels').value) &&
        control.get('terpene_levels').value.filter((terpeneLevel: TerpeneLevelInterface) => terpeneLevel.terpene).length
      ) {
        needsBatchName = true;
      }
    }

    return needsBatchName ? { batchNameRequired: true } : null;
  }
}
