import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorModel } from '@shared/model/error/error.model';
import { ConsumerModel, IConsumerData } from '../../model/consumer.model';
import { ConsumerActions, ConsumerActionTypes } from '../actions/consumer.actions';

export interface State extends EntityState<IConsumerData> {
  loading: boolean;
  updating: boolean;
  selected: string;
  currentPage: number;
  totalPages: number;
  totalRecords: number;
  error: ErrorModel;
}

export const adapter: EntityAdapter<IConsumerData> = createEntityAdapter<IConsumerData>({
  selectId: (consumer: IConsumerData) => consumer.uuid
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  updating: false,
  selected: undefined,
  currentPage: undefined,
  totalPages: undefined,
  totalRecords: undefined,
  error: undefined
});

export function reducer(state = initialState, action: ConsumerActions): State {
  switch (action.type) {
    case ConsumerActionTypes.LoadConsumerCollection:
    case ConsumerActionTypes.LoadConsumer: {
      return {
        ...state,
        loading: true
      };
    }

    case ConsumerActionTypes.UpsertConsumer:
    case ConsumerActionTypes.DeleteConsumer: {
      return {
        ...state,
        updating: true
      };
    }

    case ConsumerActionTypes.LoadConsumerCollectionSuccess: {
      const { collection, preserveExistingRecords } = action.payload;
      const method = preserveExistingRecords ? 'addMany' : 'setAll';

      return adapter[method](collection.records, {
        ...state,
        loading: false,
        totalRecords: collection.total_records,
        totalPages: collection.total_pages,
        currentPage: collection.current_page
      });
    }

    case ConsumerActionTypes.LoadConsumerSuccess: {
      const { consumer } = action.payload;
      return adapter.upsertOne(consumer, { ...state, selected: consumer.uuid, loading: false, error: null });
    }

    case ConsumerActionTypes.UpsertConsumerSuccess: {
      const { consumer } = action.payload;
      return adapter.upsertOne(consumer, { ...state, updating: false, error: null });
    }

    case ConsumerActionTypes.DeleteConsumerSuccess: {
      const { consumer } = action.payload;
      return adapter.removeOne(consumer.uuid, { ...state, updating: false, error: null });
    }

    case ConsumerActionTypes.LoadConsumerCollectionFailure:
    case ConsumerActionTypes.LoadConsumerFailure:
    case ConsumerActionTypes.UpsertConsumerFailure:
    case ConsumerActionTypes.DeleteConsumerFailure: {
      const { error } = action.payload;
      return { ...state, loading: false, updating: false, error };
    }

    case ConsumerActionTypes.SelectConsumer: {
      return { ...state, selected: action.payload.uuid };
    }

    case ConsumerActionTypes.DeselectConsumer: {
      return { ...state, selected: undefined };
    }

    case ConsumerActionTypes.ClearConsumerState: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
