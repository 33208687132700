import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocationLinkInterface } from '@myblackbird/api/interface';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LocationLinkService {
  constructor(private config: ApiConfig, private http: HttpClient) {}

  public create(link: { name: string; url: string }): Observable<LocationLinkInterface> {
    return this.http.post<LocationLinkInterface>(`${this.config.baseUrl}/v2/location/link`, link);
  }

  public update(link: { uuid: string; name: string; url: string }): Observable<LocationLinkInterface> {
    return this.http.patch<LocationLinkInterface>(`${this.config.baseUrl}/v2/location/link/${link.uuid}`, link);
  }

  public delete(location_link_uuid: string): Observable<void> {
    return this.http.delete<void>(`${this.config.baseUrl}/v2/location/link/${location_link_uuid}`);
  }
}
