import { Injectable } from '@angular/core';
import { AuthContextInterface, AuthLoginRequestInterface, AuthUserAclInterface } from '@myblackbird/api/interface';
import { AuthApiService, FeatureFlagStateService } from '@myblackbird/api/service';
import { AnalyticsService } from '@shared/analytics';
import { TypesEnum } from '@shared/enumeration/type/types.enum';
import { Intercom } from '@shared/intercom';
import { LogRocketService } from '@shared/log-rocket';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgxPermissionsObject } from 'ngx-permissions/lib/service/permissions.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, map, tap } from 'rxjs/operators';
import { CookiesService } from '@shared/cookie';
import { TransportationAddonPolicy } from './permissions/addons/transportation-addon.policy';
import { LocationPolicy } from './permissions/acl-target/location.policy';
import { OrganizationPolicy } from './permissions/acl-target/organization.policy';
import { AddonIdentifierEnum } from './permissions/addons/addon.enum';
import { BlackbirdAddonPolicy } from './permissions/addons/blackbird-addon.policy';
import { ComplianceAddonPolicy } from './permissions/addons/compliance-addon.policy';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _context$ = new BehaviorSubject<AuthContextInterface | null>(null);

  constructor(
    private authApiService: AuthApiService,
    private ngxPermissionsService: NgxPermissionsService,
    private featureFlagStateService: FeatureFlagStateService,
    private analyticsService: AnalyticsService,
    private intercomService: Intercom,
    private logRocketService: LogRocketService,
    private cookiesService: CookiesService
  ) {
  }

  get context$(): Observable<AuthContextInterface | null> {
    return this._context$.asObservable();
  }

  get contextSnaphot(): AuthContextInterface | null {
    return this._context$.getValue();
  }

  get authenticated$(): Observable<boolean> {
    return this.context$.pipe(map(context => !!context));
  }

  get authenticated(): boolean {
    return !!this.contextSnaphot;
  }

  get currentLocation$(): Observable<AuthContextInterface['current_location']> {
    return this.context$.pipe(
      filter(context => !!context),
      map(context => context.current_location)
    );
  }

  get currentLocationSnapshot(): AuthContextInterface['current_location'] | null {
    return this.contextSnaphot?.current_location;
  }

  get currentOrganization$(): Observable<AuthContextInterface['current_organization']> {
    return this.context$.pipe(
      filter(context => !!context),
      map(context => context.current_organization)
    );
  }

  get currentOrganizationSnapshot(): AuthContextInterface['current_organization'] | null {
    return this.contextSnaphot?.current_organization;
  }

  get currentUser$(): Observable<AuthContextInterface['current_user']> {
    return this.context$.pipe(
      filter(context => !!context),
      map(context => context.current_user)
    );
  }

  get currentUserSnapshot(): AuthContextInterface['current_user'] | null {
    return this.contextSnaphot?.current_user;
  }

  get currentUserAccessControl$(): Observable<AuthUserAclInterface> {
    return this.context$.pipe(
      filter(context => !!context),
      map(context => context.current_acl)
    );
  }

  get currentUserAccessControlSnapshot(): AuthUserAclInterface | null {
    return this.contextSnaphot?.current_acl;
  }

  get availableUserAccessControls$(): Observable<AuthUserAclInterface[]> {
    return this.context$.pipe(
      filter(context => !!context),
      map(context => context.acls)
    );
  }

  get availableUserAccessControlsSnapshot(): AuthUserAclInterface[] {
    return this.contextSnaphot?.acls ?? [];
  }

  public get permissions$(): Observable<NgxPermissionsObject> {
    return this.ngxPermissionsService.permissions$;
  }

  public loadSession(): Observable<AuthContextInterface> {
    return this.authApiService.whoami().pipe(
      tap(context => {
        this.onAuthenticateUser(context);
        this._context$.next(context);
      }),
      catchError(err => {
        this._context$.next(null);
        return throwError(err);
      })
    );
  }

  public login(credentials: AuthLoginRequestInterface): Observable<AuthContextInterface> {
    if (this.cookiesService.get('current_acl_target')) {
      try {
        const lastKnownAccessTarget = JSON.parse(this.cookiesService.get('current_acl_target'));
        credentials['user_access_control_target'] = {
          uuid: lastKnownAccessTarget.uuid,
          _type: lastKnownAccessTarget.type_id ?? lastKnownAccessTarget._type
        };
      } catch (e) {
      }
    }

    return this.authApiService.login(credentials).pipe(
      tap(context => {
        this.onAuthenticateUser(context);
        this._context$.next(context);
      })
    );
  }

  public logout(): Observable<void> {
    return this.authApiService.logout().pipe(
      tap(() => (this.removeState())),
      finalize(() => {
        this.intercomService.shutdown();
        this.intercomService.boot();
        this.cookiesService.remove('mybb-access-token');
        this.cookiesService.remove('mybb-refresh-token');
      })
    );
  }

  public removeState() {
    this.ngxPermissionsService.flushPermissions();
    this._context$.next(null);
    return this.context$;
  }

  public changeAccess(user_access_control_target: { uuid: string; type_id: string }): Observable<AuthContextInterface> {
    return this.authApiService.changeAccess({ user_access_control_target }).pipe(
      tap(context => {
        this.storeAccessControl(context.current_acl);
        this.setPermissions(context);
        this._context$.next(context);
      })
    );
  }

  public hasAddon(addon: AddonIdentifierEnum): boolean {
    return this.contextSnaphot?.current_addons.includes(addon) ?? false;
  }

  private onAuthenticateUser(context: AuthContextInterface) {
    this.setPermissions(context);
    this.initLogRocket();
    this.identifyUser(context);
  }

  private setPermissions(context: AuthContextInterface): void {
    this.ngxPermissionsService.flushPermissions();

    const { current_acl: acl, current_addons } = context;

    let permissions = [];

    if (acl.target.type_id === TypesEnum.LOCATIONS) permissions.push(...new LocationPolicy(acl).getPermissions());

    if (acl.target.type_id === TypesEnum.ORGANIZATIONS)
      permissions.push(...new OrganizationPolicy(acl).getPermissions());

    permissions.push(...new BlackbirdAddonPolicy(acl).getPermissions());
    permissions.push(...new ComplianceAddonPolicy(acl).getPermissions());
    permissions.push(...new TransportationAddonPolicy(acl).getPermissions());

    // if (current_addons.includes(AddonIdentifierEnum.BLACKBIRD))

    // if (current_addons.includes(AddonIdentifierEnum.COMPLIANCE))

    // if (addonIdentifiers.includes(AddonIdentifierEnum.CONNECT))
    //   permissions.push(...new ConnectAddonPolicy(acl, <any>connectTypes).getPermissions());

    // if (current_addons.includes(AddonIdentifierEnum.DISPATCH))
    //   permissions.push(...new DispatchAddonPolicy(acl).getPermissions());

    // if (addonIdentifiers.includes(AddonIdentifierEnum.EXPERIENCES))
    //   permissions.push(...new ExperiencesAddonPolicy(acl).getPermissions());
    //
    // if (addonIdentifiers.includes(AddonIdentifierEnum.LOYALTY))
    //   permissions.push(...new LoyaltyAddonPolicy(acl).getPermissions());
    //
    // if (addonIdentifiers.includes(AddonIdentifierEnum.RETAIL_MENU))
    //   permissions.push(...new OnlineMenuAddonPolicy(acl).getPermissions());

    // if (addonIdentifiers.includes(AddonIdentifierEnum.POINT_OF_SALE))
    //   permissions.push(...new PosAddonPolicy(acl).getPermissions());

    // if (addonIdentifiers.includes(AddonIdentifierEnum.SELF_DELIVERY))
    //   permissions.push(...new SelfDeliveryAddonPolicy(acl).getPermissions());

    // if (current_addons.includes(AddonIdentifierEnum.TRANSPORTATION))

    // if (addonIdentifiers.includes(AddonIdentifierEnum.BRAND_MANAGEMENT))
    //   permissions.push(...new BrandManagementAddonPolicy(acl).getPermissions());

    // if (addonIdentifiers.includes(AddonIdentifierEnum.BRAND_MENU)) {
    //   permissions.push(...new BrandMenuAddonPolicy(acl).getPermissions());
    //   permissions = permissions.filter(p => p != PolicyPermissions.WRITE_INTEGRATION_CONFIGURATION);
    // }

    this.ngxPermissionsService.addPermission(permissions);
  }

  private initLogRocket() {
    this.logRocketService.initialize();
  }

  private identifyUser(context: AuthContextInterface) {
    const { current_user: user, current_acl, intercom_hash } = context;
    const userName = `${user.first_name} ${user.last_name}`;

    this.analyticsService.identifyUser(user.uuid);

    this.intercomService.update({
      user_hash: intercom_hash,
      user_id: user.uuid,
      email: user.email,
      name: userName,
      companies: context.acls.map(acl => ({ type: 'company', id: acl.target.uuid, name: acl.target.name })),
      role: current_acl.role
    });

    this.logRocketService.identifyUser(user.uuid, {
      name: userName,
      email: user.email,
      lastSeenLocation: current_acl.target.name,
      role: current_acl.role
    });
  }

  private storeAccessControl(acl: AuthUserAclInterface) {
    this.cookiesService.put('current_acl_target', JSON.stringify(acl.target));
  }
}
