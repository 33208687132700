import { BehaviorSubject, of, Subject } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  IdInterface,
  PaginationApiServiceInterface,
  PagyCollectionInterface,
  UnhandledErrorInterface,
  UuidInterface
} from '../interface';

interface PaginationRequestInterface {
  page: number;
  limit?: number;
  orderBy?: string | null;
  order?: 'asc' | 'desc' | '' | null;
  query?: any;
}

interface DefaultOptionsInterface {
  limit?: number;
  pageSizeOptions?: [];
}

interface StateInterface<T> extends DefaultOptionsInterface, PaginationRequestInterface, PagyCollectionInterface<T> {
  busy: boolean;
  initialized: boolean;
  error?: string | null;
}

export abstract class BasePaginationService<T extends IdInterface | UuidInterface> {
  private search$ = new Subject<PaginationRequestInterface>();
  private state$: BehaviorSubject<StateInterface<T>>;
  private destroy$ = new Subject<void>();
  protected initParams = {};
  apiMethodName = 'list';

  protected constructor(private apiService: PaginationApiServiceInterface<T>, defaults?: DefaultOptionsInterface) {
    const state = {
      page: 1,
      limit: 10,
      pageSizeOptions: [10, 25, 50],
      query: null,
      orderBy: null,
      order: '',
      busy: false,
      initialized: false,
      records: [],
      total_pages: 0,
      total_records: 0,
      current_page: 1,
      has_next_page: false,
      has_prev_page: false,
      error: null,
      ...defaults
    };
    this.state$ = new BehaviorSubject(state as StateInterface<T>);

    this.search$
      .pipe(
        takeUntil(this.destroy$),
        tap((paginationRequest: PaginationRequestInterface) => {
          this.state$.next({ ...this.state$.value, error: null, busy: true, ...paginationRequest });
        }),
        switchMap((paginationRequest: PaginationRequestInterface) => {
          const params = { ...this.initParams, ...paginationRequest } as any;
          if (paginationRequest.query) {
            params.query = JSON.stringify(paginationRequest.query);
          }

          return this.apiService[this.apiMethodName](params).pipe(catchError(() => of(null)));
        })
      )
      .subscribe({
        next: (res: PagyCollectionInterface<T> | null) => {
          let newState = this.state$.value;
          if (res) {
            newState = { ...newState, ...res };
          } else {
            newState.page = newState.page - 1 > 1 ? newState.page - 1 : 1;
          }
          newState.busy = false;
          newState.initialized = true;
          this.state$.next(newState);
        },
        error: (err: UnhandledErrorInterface) => {
          const currentState = this.getStateSnapshot();
          this.state$.next({ ...currentState, error: err.message, busy: false });
        }
      });
  }

  init(params = {}) {
    this.initParams = { ...params };
  }

  getStateSnapshot(): StateInterface<T> {
    return this.state$.getValue();
  }

  getState() {
    return this.state$.asObservable();
  }

  setPage(page: number, limit?: number) {
    const state = this.state$.value;
    this.search$.next({
      page,
      limit: limit || state.limit,
      order: state.order,
      orderBy: state.orderBy,
      query: state.query
    });
  }

  firstPage() {
    this.setPage(1);
  }

  setQuery(query: any) {
    this.state$.next({ ...this.state$.value, query });
  }

  setOrder(orderBy: string, order: 'asc' | 'desc' | '') {
    this.state$.next({ ...this.state$.value, orderBy, order });
  }

  forceRefreshCurrentPage() {
    const state = this.state$.value;
    this.setPage(state.page);
  }

  fetchAndUpdateCurrentPage() {
    let state = this.state$.value;
    const params = {
      ...this.initParams,
      query: state.query ? JSON.stringify(state.query) : null,
      page: state.page,
      limit: state.limit,
      orderBy: state.orderBy,
      order: state.order
    };
    this.apiService[this.apiMethodName](params).subscribe((response) => {
      state = this.state$.value;
      for (const resRecord of response.records) {
        for (const currentRecord of state.records) {
          if (resRecord['uuid'] && currentRecord['uuid'] == resRecord['uuid']) {
            Object.assign(currentRecord, resRecord);
          }
          if (resRecord['id'] && currentRecord['id'] == resRecord['id']) {
            Object.assign(currentRecord, resRecord);
          }
        }
      }
      this.state$.next({ ...state });
    });
  }

  destroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
