export enum FeatureFlagEnum {
  WholesaleOrdering = 1,
  Billing = 2
}

export const WholesaleOrdering = {
  id: FeatureFlagEnum.WholesaleOrdering,
  name: 'wholesale-ordering'
};

export const BillingPortal = {
  id: FeatureFlagEnum.Billing,
  name: 'billing'
};

export const AllFeatureFlags = [WholesaleOrdering, BillingPortal];
