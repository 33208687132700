import { CdkScrollable } from '@angular/cdk/overlay';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { NavigationEnd, Router } from '@angular/router';
import { MessagingCenterDialogComponent } from '@myblackbird/shared/messaging-center';
import { PickOrderService } from '@myblackbird/shared/print';
import { MessagePollingService } from '@myblackbird/shared/service/message-polling';
import { Actions, ofType } from '@ngrx/effects';
import { ConsumerModel } from '@shared/entity/consumer/model';
import { ConsumerService } from '@shared/entity/consumer/service';
import { DeliveryModel } from '@shared/entity/delivery/model';
import { DeliveryService } from '@shared/entity/delivery/service';
import { OrderStatusEnum } from '@shared/entity/order/enumeration';
import { OrderModel } from '@shared/entity/order/model';
import { OrderParameters, OrderService } from '@shared/entity/order/service';
import { OrderActionTypes, UpsertOrderSuccess } from '@shared/entity/order/state';
import { SmsSubscriberModel } from '@shared/entity/sms-conversation/model';
import { TypesEnum } from '@shared/enumeration/type/types.enum';
import { LocalStorageService } from '@shared/local-storage';
import { AuthService, PolicyPermissions } from '@myblackbird/auth';
import { PrintService } from '@shared/qz-tray';
import { forkJoin, interval, Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  skip,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
import { AuthUserAclInterface } from '@myblackbird/api/interface';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @ViewChild(CdkScrollable) cdkScrollRef: CdkScrollable;

  aclSelector: FormControl;
  aclSelectorIcon: string = null;
  audibleAlertsCtrl: FormControl = new FormControl();
  autoPrintPickListsCtrl: FormControl = new FormControl();

  orderParameters: OrderParameters;
  ordersLoading: boolean = false;
  allOrders: boolean = false;
  orderStatus = OrderStatusEnum;
  orders: OrderModel[] = [];
  totalRecords: number = -1;
  totalUnreadMessages: number;

  showNavbar = false;
  currentRouteUrl: string;
  newOrderSound: HTMLAudioElement = new Audio('/assets/audio/new_order_alert.wav');

  authenticated$: Observable<boolean>;
  acl$: Observable<AuthUserAclInterface[]>;
  aclLocations$: Observable<AuthUserAclInterface[]>;
  aclOrganizations$: Observable<AuthUserAclInterface[]>;
  currentAcl$: Observable<AuthUserAclInterface>;
  aclUpdated$: Observable<AuthUserAclInterface>;
  currentAcl: AuthUserAclInterface;
  userName$: Observable<string>;
  defaultPrinterName$: Observable<string | null>;
  defaultPrinterName: string | null;
  isQzConnected$: Observable<boolean>;

  isOrganization: boolean;

  dialogRef: MatDialogRef<MessagingCenterDialogComponent>;

  constructor(
    private router: Router,
    private actions$: Actions,
    private orderService: OrderService,
    private deliveryService: DeliveryService,
    private consumerService: ConsumerService,
    private localStorageService: LocalStorageService,
    private pickOrderService: PickOrderService,
    private printService: PrintService,
    private matDialog: MatDialog,
    private messagePollingService: MessagePollingService,
    private authService: AuthService
  ) {
    this.acl$ = this.authService.availableUserAccessControls$;
    this.currentAcl$ = this.authService.currentUserAccessControl$;
    this.aclUpdated$ = this.currentAcl$.pipe(
      skip(1),
      distinctUntilChanged((acl, newAcl) => acl?.target?.uuid === acl?.target?.uuid)
    );
    this.authenticated$ = this.authService.authenticated$;
    this.userName$ = this.authService.currentUser$.pipe(map(user => user.first_name));

    this.aclLocations$ = this.acl$.pipe(
      map(acls => (acls ? acls.filter(acl => acl.target.type_id === TypesEnum.LOCATIONS) : []))
    );
    this.aclOrganizations$ = this.acl$.pipe(
      map(acls => (acls ? acls.filter(acl => acl.target.type_id === TypesEnum.ORGANIZATIONS) : []))
    );
    this.defaultPrinterName$ = this.printService.defaultPrinter$;
    this.isQzConnected$ = this.printService.isQzConnected$;
  }

  ngOnInit() {
    this.initAudibleAlertsCtrl();
    this.subscribeAudibleAlertsCtrl();
    this.initAutoPrintPickListsCtrl();
    this.subscribeAutoPrintPickListsCtrl();

    this.initAclCtrl();
    this.subscribeAcl();
    this.suscribeAuthenticated();
    this.subscribePermissions();
    this.subscribeActions();
    this.subscribeMessagePolling();

    this.defaultPrinterName$.subscribe(name => (this.defaultPrinterName = name));

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.currentRouteUrl = event.url;
      this.showNavbar = event.url !== '/' && !event.url.includes('invite');
      if (event.url.includes('auth?unauthorized=true')) {
        this.matDialog.closeAll();
      }
    });
  }

  initAclCtrl() {
    this.aclSelector = new FormControl('', Validators.required);
  }

  suscribeAuthenticated() {
    this.authenticated$.subscribe(authed => {
      if (authed) {
        this.printService.retryConnection();
      } else {
        this.printService.closeConnection();
        this.messagePollingService.unsubscribePolling();
      }
    });
  }

  subscribePermissions() {
    this.authService.permissions$
      .pipe(
        tap(() => {
          this.orders = [];
          this.totalRecords = -1;
        }),
        debounceTime(100)
      )
      .subscribe(permissions => {
        if (PolicyPermissions.WATCH_PENDING_ORDERS in permissions) {
          this.initPendingOrders();
          this.startPendingOrderUpdateInterval();
        }

        if (PolicyPermissions.WATCH_INCOMING_MESSAGES in permissions) {
          this.messagePollingService.startPolling();
        }
      });
  }

  updateForm(acl: AuthUserAclInterface) {
    this.currentAcl = acl;
    this.aclSelector.setValue(acl);
    this.isOrganization = this.currentAcl.target.type_id === TypesEnum.ORGANIZATIONS;
    this.setAclSelectorIcon(acl);
  }

  subscribeAcl() {
    this.currentAcl$.pipe(filter(res => !!res)).subscribe(acl => this.updateForm(acl));

    this.aclSelector.valueChanges
      .pipe(
        filter(acl => this.currentAcl !== acl),
        switchMap(acl => this.authService.changeAccess(acl.target))
      )
      .subscribe(() => {
        this.router.navigate(['/home']);
        this.messagePollingService.unsubscribePolling();
        this.totalUnreadMessages = 0;
      });
  }

  setAclSelectorIcon(acl: AuthUserAclInterface) {
    if (acl.target.type_id === TypesEnum.ORGANIZATIONS) {
      this.aclSelectorIcon = 'business';
    }
    if (acl.target.type_id === TypesEnum.LOCATIONS) {
      this.aclSelectorIcon = 'store';
    }
  }

  subscribeOrderScroll(trigger: MatMenuTrigger) {
    this.cdkScrollRef.scrollTo({ top: 60 });
    this.cdkScrollRef
      .elementScrolled()
      .pipe(
        debounceTime(50),
        distinctUntilChanged(),
        map(() => this.cdkScrollRef.measureScrollOffset('bottom')),
        filter(bottom => bottom === 0),
        takeUntil(trigger.menuClosed)
      )
      .subscribe(() => {
        if (!this.allOrders) this.loadPendingOrders();
      });
  }

  compareSelected(obj1: AuthUserAclInterface, obj2: AuthUserAclInterface) {
    return obj1 && obj2 && obj1.target.uuid === obj2.target.uuid;
  }

  initPendingOrders() {
    this.orderParameters = new OrderParameters()
      .setOffset(0)
      .setWhereStatus([OrderStatusEnum.Pending])
      .setOrder('DESC')
      .setOrderBy('created_at');

    this.loadPendingOrders();
  }

  startPendingOrderUpdateInterval() {
    interval(60e3)
      .pipe(
        takeUntil(this.aclUpdated$),
        takeUntil(this.authenticated$.pipe(filter(authed => authed === false)))
      )
      .subscribe(() => this.initPendingOrders());
  }

  subscribeActions() {
    this.actions$
      .pipe(
        ofType(OrderActionTypes.UpsertOrderSuccess),
        map((action: UpsertOrderSuccess) => action.payload.order)
      )
      .subscribe((order: OrderModel) => {
        const idx = this.orders.findIndex(_order => _order.uuid === order.uuid);

        if (idx !== -1) {
          this.orders.splice(idx, 1);
          this.totalRecords--;
        }
      });
  }

  subscribeMessagePolling() {
    const loadMessages$ = this.messagePollingService.subscribeMessagePolling();

    loadMessages$.subscribe(response => {
      if (response.results) {
        this.getUnreadMessageTotal(response.results);
      }
      if (this.dialogRef) {
        this.dialogRef.componentInstance.updateData(response);
      }
    });
  }

  openMessageCenter() {
    this.messagePollingService.refreshPolling();
    this.dialogRef = this.matDialog.open<MessagingCenterDialogComponent>(MessagingCenterDialogComponent, {
      data: {
        disableSearch: false
      }
    });
    this.dialogRef.afterClosed().subscribe(() => {
      this.messagePollingService.selectConversation(null, false);
      this.messagePollingService.searchConversation(null);
      this.dialogRef = null;
    });
  }

  getUnreadMessageTotal(conversations: SmsSubscriberModel[]) {
    this.totalUnreadMessages = 0;
    conversations.forEach(conversation => {
      this.totalUnreadMessages += conversation.unread_message_count;
    });
  }

  initAudibleAlertsCtrl() {
    if (this.localStorageService.getItem('mybb_audible_alerts_enabled')) {
      this.audibleAlertsCtrl = new FormControl(true);
    } else {
      this.audibleAlertsCtrl = new FormControl(false);
    }
  }

  subscribeAudibleAlertsCtrl() {
    this.audibleAlertsCtrl.valueChanges.subscribe(value => {
      this.localStorageService.store('mybb_audible_alerts_enabled', value);
    });
  }

  initAutoPrintPickListsCtrl() {
    if (this.localStorageService.getItem('mybb_auto_print_pick_lists') === true) {
      this.autoPrintPickListsCtrl = new FormControl(true);
    } else {
      this.autoPrintPickListsCtrl = new FormControl(false);
    }
  }

  subscribeAutoPrintPickListsCtrl() {
    this.autoPrintPickListsCtrl.valueChanges.subscribe(value => {
      this.localStorageService.store('mybb_auto_print_pick_lists', value);
    });
  }

  openOrder(order: OrderModel) {
    this.router.navigate(['/orders/edit', order.uuid]);
  }

  loadPendingOrders() {
    this.ordersLoading = true;
    this.orderService
      .loadCollection(this.orderParameters)
      .pipe(
        finalize(() => (this.ordersLoading = false)),
        takeUntil(this.aclUpdated$)
      )
      .subscribe(collection => {
        const areNewOrders: boolean = this.totalRecords >= 0 && collection.total_records > this.totalRecords;

        if (areNewOrders && this.audibleAlertsCtrl.value) {
          this.newOrderSound.play();
        }

        if (areNewOrders && this.defaultPrinterName && this.autoPrintPickListsCtrl.value) {
          collection.records
            .filter(freshOrder => !this.orders.find(oldOrder => freshOrder.uuid === oldOrder.uuid))
            .map(newOrder => new OrderModel(newOrder))
            .forEach(newOrder => this.printPickList(newOrder));
        }

        this.orders = this.orderParameters.getOffset() ? this.orders.concat(collection.records) : collection.records;
        this.totalRecords = collection.total_records;
        this.allOrders = !collection.has_next_page;
        this.orderParameters.setOffset(this.orderParameters.getOffset() + this.orderParameters.getLimit());
      });
  }

  printPickList(orderStub: OrderModel) {
    const order$ = this.orderService.load(orderStub).pipe(map((order: OrderModel) => new OrderModel(order)));
    const consumer$ = this.consumerService
      .load(new ConsumerModel({ uuid: orderStub.customer.uuid }))
      .pipe(map((consumer: ConsumerModel) => new ConsumerModel(consumer)));

    if (orderStub.delivery) {
      const delivery$ = this.deliveryService
        .load(new DeliveryModel({ uuid: orderStub.delivery.uuid }))
        .pipe(map((delivery: DeliveryModel) => new DeliveryModel(delivery)));

      if (orderStub.customer._type === TypesEnum.CONSUMERS) {
        forkJoin([order$, consumer$, delivery$]).subscribe(
          ([order, consumer, delivery]: [OrderModel, ConsumerModel, DeliveryModel]) => {
            this.pickOrderService.printPickList(order, this.currentAcl.target, consumer, delivery);
          }
        );
      } else {
        forkJoin([order$, delivery$]).subscribe(([order, delivery]: [OrderModel, DeliveryModel]) => {
          this.pickOrderService.printPickList(order, this.currentAcl.target, null, delivery);
        });
      }
    } else if (!orderStub.delivery) {
      if (orderStub.customer._type === TypesEnum.CONSUMERS) {
        forkJoin([order$, consumer$]).subscribe(([order, consumer]: [OrderModel, ConsumerModel]) => {
          this.pickOrderService.printPickList(order, this.currentAcl.target, consumer);
        });
      } else {
        order$.subscribe((order: OrderModel) => {
          this.pickOrderService.printPickList(order, this.currentAcl.target);
        });
      }
    }
  }

  onChangePrinter() {
    if (this.printService.isQzConnected) {
      this.printService.openPrinterSelectDialog();
    } else {
      this.printService.openQzErrorDialog();
    }
  }

  logout() {
    this.authService.logout()
      .pipe(finalize(() => this.router.navigate(['/'])))
      .subscribe();
  }
}
