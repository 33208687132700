<mat-progress-bar mode="indeterminate" *ngIf="(updating$ | async)"></mat-progress-bar>
<div class="modal-header">
  <button type="button" class="close" [mat-dialog-close]>
    <span aria-hidden="true">×</span>
  </button>
  <h3 class="modal-title m-y-5">
    Transferring {{ inventory.unit_label }}
    <small>{{ inventory.batch }}</small>
  </h3>
</div>
<div class="modal-body">
  <div class="row text-xs-center">
    <div class="col-lg-4 col-xs-6">
      <h5>Front</h5>
      <p>{{ inventory.stock_front }}</p>
    </div>
    <div class="col-lg-4 col-xs-6">
      <h5>Back</h5>
      <p>{{ inventory.stock_back }}</p>
    </div>
    <div class="col-lg-4 col-xs-6">
      <h5>Sample</h5>
      <p>{{ inventory.stock_sample }}</p>
    </div>
    <div class="col-lg-4 col-xs-6" *ngIf="wholesalerOrDispatcher">
      <h5>Shipping</h5>
      <p>{{ inventory.stock_shipping }}</p>
    </div>
    <div class="col-lg-4 col-xs-6">
      <h5>Unsellable</h5>
      <p>{{ inventory.stock_unsellable }}</p>
    </div>
    <div class="col-lg-4 col-xs-6">
      <h5>Quarantine</h5>
      <p>{{ inventory.stock_quarantine }}</p>
    </div>
  </div>

  <form [formGroup]="transferForm" (ngSubmit)="onSubmit()" class="row p-t-30">
    <div class="col-sm-6">
      <mat-form-field class="w-full">
        <mat-label>From Zone</mat-label>
        <mat-select formControlName="fromZone">
          <mat-option *ngFor="let zone of zones" [disabled]="inventory[zone.key] <= 0" [value]="zone.key">
            {{ zone.name }} - {{ inventory[zone.key] }}
          </mat-option>
        </mat-select>
        <mat-error>Select a zone</mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm-6">
      <mat-form-field class="w-full">
        <mat-label>To Zone</mat-label>
        <mat-select formControlName="toZone">
          <mat-option
            *ngFor="let zone of zones"
            [disabled]="transferForm.get('fromZone').value === zone.key"
            [value]="zone.key"
          >
            {{ zone.name }} - {{ inventory[zone.key] }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="transferForm.get('toZone').hasError('required')">Select a zone</mat-error>
      </mat-form-field>
    </div>
    <div class="col-xs-12">
      <mat-form-field class="w-full">
        <mat-label>Quantity</mat-label>
        <input type="number" matInput formControlName="quantity" />
        <mat-error *ngIf="transferForm.get('quantity').hasError('required')">Please enter a quantity</mat-error>
        <mat-error *ngIf="transferForm.get('quantity').hasError('min')">Quantity must be at least 1</mat-error>
        <mat-error *ngIf="transferForm.get('quantity').hasError('max')">Not enough stock available in zone</mat-error>
      </mat-form-field>
    </div>

    <div class="col-xs-12">
      <mat-form-field class="w-full">
        <mat-label>Notes</mat-label>
        <textarea matInput formControlName="transfer_notes" rows="3"></textarea>
      </mat-form-field>
    </div>
    <div class="form-group col-lg-10 offset-lg-1 m-t-15">
      <button
        type="submit"
        class="btn btn-success btn-lg btn-block m-y-20"
        [disabled]="updating$ | async"
        (click)="onSubmit()"
      >
        Transfer
      </button>
    </div>
  </form>
</div>
