import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Optional,
  Self
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatIconRegistry } from '@angular/material/icon';
import { InputMasks, Utility } from '@frontend/component-lib/localization';
import { Subject } from 'rxjs';

import { MaterialInputBase } from '../base/material-input';

@Component({
  selector: 'frontend-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [MatIconRegistry, { provide: MatFormFieldControl, useExisting: PhoneInputComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[id]': 'id',
    '[attr.aria-describedby]': 'describedBy'
  }
})
export class PhoneInputComponent
  extends MaterialInputBase
  implements OnInit, OnDestroy, MatFormFieldControl<any>, ControlValueAccessor {
  static nextId = 0;
  @HostBinding() id = `cl-phone-input-${PhoneInputComponent.nextId++}`;

  value: string = '';
  stateChanges = new Subject<void>();
  controlType = 'phone-input';
  focused = false;
  errorState = false;
  mask: (string | RegExp)[];
  @Input() name: string = '';
  @Input() label: string = '';
  @Input() hint: string = '';
  @Input() normalize: boolean = true;
  @Input() private: boolean = true;
  @HostBinding('attr.aria-describedby') describedBy = '';

  constructor(
    @Inject(LOCALE_ID) private locale: 'en-US',
    @Optional() @Self() public ngControl: NgControl,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    private _fm: FocusMonitor,
    private _elRef: ElementRef<HTMLElement>,
    private _cdRef: ChangeDetectorRef
  ) {
    super(ngControl, _parentForm, _parentFormGroup, _defaultErrorStateMatcher);

    if (this.ngControl) this.ngControl.valueAccessor = this;

    this.mask = InputMasks.Phone[this.locale];
  }

  _placeholder: string = '';

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  _required: boolean = false;

  @Input()
  get required() {
    return this._required;
  }

  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  _disabled: boolean = false;

  @Input()
  get disabled() {
    return this._disabled;
  }

  set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis);
    this.stateChanges.next();
  }

  get empty() {
    return !this.value;
  }

  get formControl(): FormControl {
    return <FormControl>this.ngControl?.control;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return (this.focused && !this._disabled) || !!this.value;
  }

  ngOnInit() {
    this.subscribeFocus();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._fm.stopMonitoring(this._elRef.nativeElement);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if (!this.disabled) {
      if ((event.target as Element).tagName.toLowerCase() != 'input') {
        this._elRef.nativeElement.querySelector('input')!.focus();
      }
      this.onTouched(true);
    }
  }

  subscribeFocus() {
    this._fm.monitor(this._elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  writeValue(value: string) {
    if (value) this.value = value;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange(value: string) {
  }

  onTouched(value: any) {
  }

  onFocus($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  onClose() {
    this.onTouched(true);
  }

  onInput($event: any) {
    this.value = $event.target.value;
    if (this.normalize) {
      this.onChange(Utility.normalizePhoneNumber(this.value));
    } else {
      this.onChange(this.value);
    }
    this.stateChanges.next();
  }
}
