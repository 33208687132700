import { CdkScrollable } from '@angular/cdk/overlay';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SmsMessageDoesNotContainBannedWords } from '@shared/validation';
import { MessagePollingService } from '@myblackbird/shared/service/message-polling';
import { ConfirmationDialogComponent, ConfirmationDialogDataInterface } from '@shared/dialog';
import { LocationModel } from '@shared/entity/location/model';
import { SmsMessageModel, SmsSubscriberModel } from '@shared/entity/sms-conversation/model';
import { SmsConversationActionsService, SmsConversationService } from '@shared/entity/sms-conversation/service';
import { ImageSizePipe } from '@shared/pipe/image-size';
import { PolicyPermissions } from '@shared/policy';
import { of, Subject } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Component({
  selector: 'frontend-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
  providers: [ImageSizePipe]
})
export class ChatMessageComponent implements OnDestroy, OnChanges {
  @ViewChild(CdkScrollable, { static: true }) scrollRef: CdkScrollable;
  @Input() selectedSubscriberMessages: SmsMessageModel[];
  @Input() accountDisabled: boolean;
  @Input() location: { logo: { url: string } | null };
  @Input() displayName: string;
  @Input() conversationConsumer: SmsSubscriberModel;
  @Output() chatArchived = new EventEmitter<void>();

  chatMessages: SmsMessageModel[];
  message = new FormControl(undefined, SmsMessageDoesNotContainBannedWords);
  sendingMessage: boolean = false;
  smsConversationActionsService: SmsConversationActionsService;

  twoWayFlagEnabled = false;
  permissions = PolicyPermissions;

  private destroy$ = new Subject<void>();

  trackByFn = (index: number, item: { sid: string }) => (item ? item.sid : null);

  constructor(
    private imageSizePipe: ImageSizePipe,
    private cdRef: ChangeDetectorRef,
    private _smsConversationService: SmsConversationService,
    private _messagePollingService: MessagePollingService,
    private matDialog: MatDialog,
    private _snackbar: MatSnackBar
  ) {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(change: SimpleChanges) {
    if (change['selectedSubscriberUri']) {
      this.updateChat();
    }

    if (change['conversationConsumer']) {
      if (
        change['conversationConsumer'].firstChange ||
        change['conversationConsumer'].previousValue?.last_message_at !==
        change['conversationConsumer'].currentValue?.last_message_at
      ) {
        this.smsConversationActionsService = this._smsConversationService.getSmsConversationActionsService(
          this.conversationConsumer.cell_phone
        );
        this.updateChat();
      }
    }

    if (change['accountDisabled']) {
      if (this.accountDisabled) {
        this.message.disable();
      } else {
        this.message.enable();
      }
    }
  }

  updateChat(): void {
    this.smsConversationActionsService.markAsRead().subscribe(() => {
      this.chatMessages = this.selectedSubscriberMessages.slice().reverse();
      this.cdRef.detectChanges();
      this.scrollRef.scrollTo({ bottom: 0 });
    });
  }

  onSubmit(): void {
    this.sendingMessage = true;
    this.smsConversationActionsService
      .sendMessage(this.message.value)
      .pipe(
        finalize(() => (this.sendingMessage = false)),
        catchError(() => {
          this._snackbar.open('Message failed to send');
          return of();
        })
      )
      .subscribe(() => {
        this._messagePollingService.refreshPolling();
        this.message.reset();
      });
  }

  getImageUrl() {
    if (this.location?.logo?.url) {
      return this.imageSizePipe.transform(this.location.logo.url, '150x');
    } else {
      return null;
    }
  }

  closeChat() {
    this.matDialog
      .open<ConfirmationDialogComponent, ConfirmationDialogDataInterface>(ConfirmationDialogComponent, {
        data: {
          title: 'Close chat?',
          confirmButtonColor: 'warn'
        }
      })
      .afterClosed()
      .subscribe(res => {
        if (res === true) {
          this.smsConversationActionsService.archiveConversation().subscribe(() => this.chatArchived.emit());
        }
      });
  }
}
