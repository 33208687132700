import { AbstractControl, ValidationErrors } from '@angular/forms';
import { get } from 'lodash';
import * as moment from 'moment';

export const BannedWords = [
  'bong',
  'bud',
  'budtender',
  'cannabis',
  'cartridge',
  'cbd',
  'concentrate',
  'dispensaries',
  'dispensary',
  'doobie',
  'edible',
  'eighth',
  'flower',
  'gummy',
  'gummies',
  ' gram',
  'joint',
  'kief',
  'kush',
  'marijuana',
  'oz',
  'preroll',
  'pre-roll',
  'pre roll',
  'price',
  'shatter',
  'strain',
  'vape',
  'weed',
  'wax',
  '1/8',
  '1/4'
];

export function SmsMessageBodyPresent(control: AbstractControl): ValidationErrors | null {
  if (get(control, 'value.body.length', 0) > 0) return null;
  return { smsMessageBodyPresent: true };
}

export function SmsMessageDoesNotContainBannedWords(control: AbstractControl): ValidationErrors | null {
  const foundBannedWords = [];

  let text;

  if (control?.value?.body?.length > 0) {
    text = control.value.body;
  } else if (control?.value?.length > 0) {
    text = control.value;
  }

  if (text !== undefined) {
    BannedWords.forEach(bannedWord => {
      if (text.toLowerCase().includes(bannedWord)) {
        foundBannedWords.push(bannedWord);
      }
    });
  }

  if (foundBannedWords.length > 0) return { smsMessageDoesNotContainBannedWords: true };
  return null;
}



// BUGBUG: It would be nice to not have to hard-code the control names here so that this could
// be reused in other contexts and moved out of the sms-specific file.
export function FutureDateAndTime(control: AbstractControl): ValidationErrors | null {
  if (!control.get('sendDate').value) return null;
  if (!control.get('sendTime').value) return null;

  const isPastDate = moment(control.get('sendDate').value).isBefore(moment(), 'day');
  if (isPastDate) control.get('sendDate').setErrors({ futureDateAndTime: true });

  if (moment(control.get('sendDate').value).isSame(moment(), 'day')) {
    const isPastTime = getTime(control.get('sendTime').value).isBefore(getTime(moment()), 'minute');
    if (isPastTime) control.get('sendTime').setErrors({ futureDateAndTime: true });
  }
}

export function TcpaCompliantTime(control: AbstractControl): ValidationErrors | null {
  const start = moment('7:59 am', 'hh:mm a');
  const end = moment('5:01 pm', 'hh:mm a');

  if (control.get('scheduleMessage').value === true) {
    if (
      control.get('sendTime').value &&
      getTime(control.get('sendTime').value).isBetween(getTime(start), getTime(end), 'minute')
    )
      return null;

    control.get('sendTime').setErrors({ tcpaCompliantTime: true });
    return null;
  }

  if (!getTime(moment()).isBetween(getTime(start), getTime(end), 'minute')) return { tcpaCompliantTime: true };
}

function getTime(dateTime: moment.Moment): moment.Moment {
  dateTime = moment(dateTime, 'hh:mm a');
  return moment({ h: dateTime.hours(), m: dateTime.minutes() });
}
