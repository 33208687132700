import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AuthContextInterface } from '@myblackbird/api/interface';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ContextResolver implements Resolve<AuthContextInterface> {
  constructor(private authService: AuthService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): AuthContextInterface {
    return this.authService.contextSnaphot;
  }
}
