import { OffsetHttpParameters } from '@shared/utility/api';

type OrganizationInterestOrderByTypes = 'organization_interest.name';

export class OrganizationInterestParameters extends OffsetHttpParameters {
  protected searchJSON: Partial<{
    name: string;
    status: 'active';
  }> = {};

  constructor(params?: Partial<OrganizationInterestParameters>) {
    super();
    if (params) Object.assign(this, params);
  }

  setOrderBy(orderBy?: OrganizationInterestOrderByTypes): this {
    return super.setOrderBy(orderBy);
  }

  setWhereName(name?: string) {
    this.searchJSON = { ...this.searchJSON, name: name ?? undefined };
    return this;
  }

  setWhereStatus(status?: 'active' | undefined) {
    this.searchJSON = { ...this.searchJSON, status: status ?? undefined };
    return this;
  }

  setGlobalSearch(query?: string): this {
    return this.setWhereName(query);
  }

  getParams() {
    return this.removeUndefinedValues({
      ...super.getParams(),
      ...this.searchJSON
    });
  }
}
