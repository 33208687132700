/**
 * THIS FILE IS AUTOGENERATED
 */

import { OffsetHttpParameters } from '@shared/utility/api';
import { LocationModel } from '../../location/model'
import { StatusModel } from '@shared/enumeration/status/status.model';

export class PurchaseOrderParameters extends OffsetHttpParameters {

  protected active: string;

  protected from_location: LocationModel;
  protected to_location: LocationModel;

  protected searchJSON: { [key: string]: any } = {
    purchase_order: undefined,
    created_by_location: undefined,
    from_location: undefined,
    to_location: undefined,
    status: undefined,
    sale: undefined
  };

  constructor(params?: Partial<PurchaseOrderParameters>) {
    super();
    this.setOrderBy('created_at');
    this.setOrder('DESC');
    if(params) Object.assign(this, params);
  }


  setWhereCreatedByLocation(location?: LocationModel) {
    this.searchJSON.created_by_location = location ? { distinct: location.uuid } : undefined;
    return this;
  }

  setWherePurchaseOrderName(name?: string) {
    this.searchJSON.purchase_order = name ? { name: name } : undefined;
    return this;
  }

  setWhereFromLocationsIn(locations?: LocationModel[]) {
    this.searchJSON.from_location = locations ? { in: locations.map(location => location.uuid) } : undefined;
    this.from_location = locations ? locations[0] : undefined;
    return this;
  }

  setWhereToLocationsIn(locations?: LocationModel[]) {
    this.searchJSON.to_location = locations ? { in: locations.map(location => location.uuid) } : undefined;
    this.to_location = locations ? locations[0] : undefined;
    return this;
  }

  setWhereStatusIn(statuses?: StatusModel[]) {
    this.searchJSON.status = statuses ? { in: statuses.map(status => status.id) } : undefined;
    return this;
  }

  setWhereIsSale(sale?: boolean) {
    this.searchJSON.sale = (sale !== null && sale !== undefined) ? sale : undefined;
    return this;
  }

  getParams() {
    return this.removeUndefinedValues({
      ...super.getParams(),
      search: JSON.stringify(this.searchJSON)
    });
  }

  getFromLocation(){
    return this.from_location;
  }

  getToLocation(){
    return this.to_location;
  }

}
