import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ImageLoadOptionsInterface } from './image-load-options.interface';
import { IMAGE_LOAD_OPTIONS } from './image-load-options.token';
import { ImageLoadDirective } from './image-load.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ImageLoadDirective],
  exports: [ImageLoadDirective]
})
export class ImageLoadModule {
  static forRoot(options?: Partial<ImageLoadOptionsInterface>): ModuleWithProviders<ImageLoadModule> {
    return {
      ngModule: ImageLoadModule,
      providers: [
        {
          provide: IMAGE_LOAD_OPTIONS,
          useValue: {
            errorPath: options?.errorPath ?? 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
            loadingClass: options?.loadingClass ?? 'loading'
          }
        }
      ]
    };
  }
}
