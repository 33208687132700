import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private router: Router, private authService: AuthService) {}

  private authFromStateOrAPI(): Observable<boolean> {
    return this.authService.context$.pipe(
      switchMap(context => {
        if (context === null) {
          return this.authService.loadSession().pipe(
            catchError(err => {
              this.router.navigate(['/']);
              return throwError(err);
            })
          );
        } else {
          return of(context);
        }
      }),
      map(() => true)
    );
  }

  public canActivate(): Observable<boolean> {
    return this.authFromStateOrAPI();
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate();
  }

  public canLoad(route: Route) {
    return this.canActivate();
  }
}
