import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ConsumerModel } from '@shared/entity/consumer/model';
import { DeliveryModel } from '@shared/entity/delivery/model';
import { OrderProductModel } from '@shared/entity/order-product/model';
import { OrderModel } from '@shared/entity/order/model';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { PrintService, QzModule } from '@shared/qz-tray';
import * as moment from 'moment';


export enum TransferMethodEnum {
  PICKUP = 1,
  DELIVERY = 2,
  CURBSIDE = 3
}
@Injectable({
  providedIn: QzModule
})
export class PickOrderService {
  constructor(private printService: PrintService, private datePipe: DatePipe) {}

  printPickList(
    order: OrderModel,
    location: { _name?: string; } | { name: string },
    consumer?: ConsumerModel,
    delivery?: DeliveryModel
  ): Promise<null> {
    this.printService.reset();

    this.printService.centerAlign();
    this.printService.startBold();

    switch (order.transfer_method) {
      case TransferMethodEnum.CURBSIDE:
        this.printService.addText('Curbside Pickup');
      break;
      case TransferMethodEnum.DELIVERY:
        this.printService.addText('Delivery');
      break;
      case TransferMethodEnum.PICKUP:
        this.printService.addText('In-store Pickup');
      break;
    }

    if (order.customer) {
      this.printService.lineBreak();
      this.printService.addText(order.customer._name);
      this.printService.lineBreak();
    }

    if (consumer && consumer.license_class) {
      this.printService.addText(consumer.license_class.display_name);
      this.printService.lineBreak();
    }

    this.printService.endBold();

    if (delivery) {
      this.printService.addText(delivery.to_address.stringifyStreet());
      this.printService.addText(delivery.to_address.stringifyCityStatePostalCode());
      this.printService.lineBreak();

      if (order.service_window) {
        this.printService.startBold();
        this.printService.addText('Delivery Window');
        this.printService.endBold();
        this.printService.addText(this.datePipe.transform(order.service_window.start_at, 'MMM dd, y'));
        this.printService.addText(
          `${this.datePipe.transform(order.service_window.start_at, 'hh:mm a')} - ${this.datePipe.transform(
            order.service_window.end_at,
            'hh:mm a'
          )}`
        );
        this.printService.lineBreak();
      }
    }

    if (consumer) {
      if (consumer.cell_phone) {
        this.printService.addText(`Cell phone: ${consumer.cell_phone}`);
      }
      if (consumer.home_phone) {
        this.printService.addText(`Home phone: ${consumer.home_phone}`);
      }
      if (consumer.cell_phone || consumer.home_phone) {
        this.printService.lineBreak();
      }
    }

    this.printService.addText('name' in location ? location.name : location._name);
    this.printService.addText(`Order # ${order.getOrderNumber()}`);
    this.printService.addText(moment(order.created_at).format('MMM Do YYYY, h:mm a'));

    this.printService.startBold();
    this.printService.addText('Order Notes');
    this.printService.endBold();
    this.printService.addText(order.notes ? order.notes : ' No notes provided.');

    this.printService.lineBreak();
    this.printService.lineBreak();
    this.printService.addTwoColumnChunkA('Item', 'Quantity');
    this.printService.addLine();

    order.products.forEach(orderProduct => this.addItem(orderProduct));

    this.addTotal(order.total);

    this.printService.cutPaper();

    return this.printService.print();
  }

  private addItem(orderProduct: OrderProductModel) {
    this.printService.addTwoColumnChunkA(
      orderProduct.name,
      `x${orderProduct.quantity} @${orderProduct.price_each.toFixed(2)}`
    );

    this.printService.addTwoColumnChunkA(`Brand: ${orderProduct.brand._name}`, '');

    this.printService.addTwoColumnChunkA(`Unit Label: ${orderProduct.unit_label}`, '');

    if (orderProduct.batch) {
      this.printService.addTwoColumnChunkA(`Batch: ${orderProduct.batch}`, '');
    }

    if (orderProduct.sku) {
      this.printService.addTwoColumnChunkA(`SKU: ${orderProduct.sku}`, '');
    }

    this.printService.addLine();
  }

  private addTotal(amount) {
    this.printService.startBold();
    this.printService.addTwoColumnChunkA('Total', amount.toFixed(2));
    this.printService.endBold();
    this.printService.lineBreak();
    this.printService.lineBreak();
    this.printService.lineBreak();
    this.printService.lineBreak();
    this.printService.lineBreak();
  }
}
