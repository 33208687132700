<nav
  class="site-navbar navbar navbar-default navbar-fixed-top navbar-mega navbar-inverse"
  *ngIf="(authenticated$ | async)"
  [hidden]="!showNavbar"
>
  <div class="navbar-header">
    <button type="button" class="navbar-toggler">
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </button>
    <a class="navbar-brand navbar-brand-center" routerLink="/home">
      <img class="navbar-brand-logo navbar-brand-logo-normal" src="/assets/images/new-logo.png" alt="Blackbird" />
    </a>
  </div>
  <div class="navbar-container container-fluid">
    <div class="collapse navbar-collapse navbar-collapse-toolbar" style="display: flex !important;">
      <ul class="nav navbar-toolbar" style="margin-left: auto;">
        <li class="nav-item">
          <div class="location-switcher" *ngIf="(acl$ | async)?.length > 1">
            <mat-select
              panelClass="location-switcher-panel"
              class="form-control"
              [formControl]="aclSelector"
              [disableOptionCentering]="true"
              [compareWith]="compareSelected"
            >
              <mat-select-trigger>
                <ng-container *ngIf="aclSelectorIcon">
                  <mat-icon>{{ aclSelectorIcon }}</mat-icon>
                  &nbsp;
                </ng-container>
                {{ aclSelector.value.target.name }}
              </mat-select-trigger>
              <mat-optgroup label="Organizations" *ngIf="(aclOrganizations$ | async)?.length">
                <mat-option *ngFor="let acl of (aclOrganizations$ | async)" [value]="acl">
                  <mat-icon>business</mat-icon>
                  {{ acl.target.name }}
                </mat-option>
              </mat-optgroup>
              <mat-optgroup label="Locations" *ngIf="(aclLocations$ | async)?.length">
                <mat-option *ngFor="let acl of (aclLocations$ | async)" [value]="acl">
                  <mat-icon>store</mat-icon>
                  {{ acl.target.name }}
                </mat-option>
              </mat-optgroup>
            </mat-select>
          </div>
          <div class="navbar-text" *ngIf="(acl$ | async)?.length === 1">
            <ng-container *ngIf="aclSelectorIcon">
              <mat-icon>{{ aclSelectorIcon }}</mat-icon>
              &nbsp;
            </ng-container>
            {{ (currentAcl$ | async)?.target.name }}
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link">
            <small id="name" class="p-r-5 p-l-10 grey-400">{{ userName$ | async }}</small>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            href="javascript:void(0)"
            title="Notifications"
            #notificationsMenuTrigger="matMenuTrigger"
            [matMenuTriggerFor]="notificationsMenu"
            (menuOpened)="subscribeOrderScroll(notificationsMenuTrigger)"
            role="button"
          >
            <mat-icon
              fontSet="fal"
              fontIcon="fa-bell"
              [matBadge]="totalRecords"
              [matBadgeHidden]="totalRecords === 0"
              matBadgeSize="small"
              matBadgeColor="warn"
              *ngIf="totalRecords >= 0"
            ></mat-icon>
          </a>
          <mat-menu #notificationsMenu="matMenu" class="notification-menu">
            <div class="list-group m-0" cdkScrollable>
              <div class="toggle-control-container" (click)="$event.stopPropagation()">
                <mat-slide-toggle [formControl]="audibleAlertsCtrl" color="primary">
                  <mat-label class="mat-h4 mat-body-strong">
                    Audible Alerts -
                    <strong
                      [ngClass]="{
                        'blue-600': audibleAlertsCtrl.value
                      }"
                    >
                      {{ audibleAlertsCtrl.value ? 'Enabled' : 'Disabled' }}
                    </strong>
                  </mat-label>
                </mat-slide-toggle>
              </div>
              <a
                *ngFor="let order of orders"
                mat-menu-item
                class="list-group-item m-y-3"
                href="javascript:void(0)"
                (click)="openOrder(order)"
                role="menuitem"
              >
                <div class="media">
                  <div class="media-left p-r-10">
                    <mat-icon
                      fontSet="far"
                      fontIcon="fa-shopping-cart"
                      [ngClass]="{
                        'purple-700': order.status === orderStatus.Draft,
                        'orange-700': order.status === orderStatus.Pending,
                        'blue-700': order.status === orderStatus.Saved,
                        'cyan-700': order.status === orderStatus.Ready,
                        'green-700': order.status === orderStatus.Completed
                      }"
                    ></mat-icon>
                  </div>
                  <div class="media-body">
                    <h6 class="media-heading">{{ order | orderType }} from {{ order.customer._name }}</h6>
                    <time class="media-meta">{{ order.created_at | amTimeAgo }}</time>
                  </div>
                </div>
              </a>
            </div>
            <mat-progress-bar *ngIf="ordersLoading" mode="indeterminate"></mat-progress-bar>
            <div class="dropdown-menu-footer" *ngIf="!ordersLoading && orders.length === 0">
              <div class="p-25 m-0 blue-grey-400">
                <a>
                  There are currently no notifications.
                </a>
              </div>
            </div>
          </mat-menu>
        </li>
        <li class="nav-item dropdown" [matMenuTriggerFor]="printerMenu">
          <a class="nav-link" href="javascript:void(0)" aria-expanded="false" role="button">
            <mat-icon
              class="print-icon"
              matBadge="&#8288;"
              matBadgeSize="small"
              [matBadgeHidden]="(isQzConnected$ | async) && !!(defaultPrinterName$ | async)"
              [matBadgeColor]="'warn'"
              fontSet="fal"
              fontIcon="fa-print"
            ></mat-icon>
          </a>
          <mat-menu #printerMenu="matMenu" class="notification-menu">
            <div class="toggle-control-container" (click)="$event.stopPropagation()">
              <mat-slide-toggle [formControl]="autoPrintPickListsCtrl" color="primary">
                <mat-label class="mat-h4 mat-body-strong">
                  Auto Print Pick Lists -
                  <strong
                    [ngClass]="{
                      'blue-600': autoPrintPickListsCtrl.value
                    }"
                  >
                    {{ autoPrintPickListsCtrl.value ? 'Enabled' : 'Disabled' }}
                  </strong>
                </mat-label>
              </mat-slide-toggle>
            </div>
            <div class="dropdown-menu-footer">
              <div
                *ngIf="(isQzConnected$ | async); else qzConnectTpl"
                class="p-10 m-0 blue-grey-400 flex space-between baseline"
              >
                <p class="p-r-20">{{ (defaultPrinterName$ | async) || 'No Printer Connected' }}</p>
                <button mat-button color="primary" (click)="onChangePrinter()">
                  {{ (defaultPrinterName$ | async) ? 'Change' : 'Connect' }}
                </button>
              </div>
              <ng-template #qzConnectTpl>
                <div class="p-10 m-0 blue-grey-400 flex space-between baseline">
                  <p class="p-r-20">No Connection with QZ Tray</p>
                  <button mat-button color="primary" (click)="onChangePrinter()">Fix</button>
                </div>
              </ng-template>
            </div>
          </mat-menu>
        </li>
        <li class="nav-item dropdown" [matMenuTriggerFor]="logoutMenu">
          <a class="nav-link" href="javascript:void(0)" aria-expanded="false" role="button" data-cy="logout-myblackbird">
            <mat-icon fontSet="fal" fontIcon="fa-user-circle"></mat-icon>
          </a>
          <mat-menu #logoutMenu="matMenu">
            <button mat-menu-item (click)="logout()" data-cy="logout-myblackbird-confirm">
              <mat-icon class="m-r-5" fontSet="fal" fontIcon="fa-sign-out-alt"></mat-icon>
              Logout
            </button>
          </mat-menu>
        </li>
      </ul>
    </div>
  </div>
</nav>
