import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RetailStoreLocationInterface } from '@brand-menu/api/interface';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreLocatorApiService {
  private basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/v2/location/map-marker`;
  }

  list(): Observable<RetailStoreLocationInterface[]> {
    return this.http.get<RetailStoreLocationInterface[]>(this.basePath);
  }

  addLocation(uuid: string): Observable<void> {
    return this.http.post<void>(this.basePath, { uuid });
  }

  removeLocation(uuid: string): Observable<void> {
    return this.http.delete<void>(this.basePath, { params: { uuid } });
  }

  toggleFeatured(uuid: string, featured: boolean): Observable<void> {
    return this.http.patch<void>(`${this.basePath}/toggle-featured`, { uuid: uuid, featured: featured });
  }
}
