import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WINDOW, WINDOW_PROVIDERS } from './window.service';

@NgModule({
  imports: [CommonModule],
  providers: [WINDOW_PROVIDERS]
})
export class WindowModule {
  static forRoot(): ModuleWithProviders<WindowModule> {
    return {
      ngModule: WindowModule,
      providers: [WINDOW_PROVIDERS]
    };
  }
}
