import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BrandInterface,
  ListingInterface,
  LocationInterface,
  UpdateListingInterface
} from '@myblackbird/api/interface';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { ListingUploadService } from 'libs/myblackbird/api/src/lib/service/listing-upload.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListingService {
  constructor(private config: ApiConfig, private http: HttpClient) {}

  public loadCollection<T = LocationInterface | BrandInterface>(): Observable<{ records: ListingInterface<T>[] }> {
    return this.http.get<{ records: ListingInterface<T>[] }>(`${this.config.doloresUrl}/v2/listings`);
  }

  update(data: UpdateListingInterface): Observable<ListingInterface> {
    return this.http.patch<ListingInterface>(`${this.config.doloresUrl}/v2/listings/${data.uuid}`, data);
  }

  getListingUploadService(listing: { uuid: string }): ListingUploadService {
    return new ListingUploadService(listing, this.config, this.http);
  }
}
