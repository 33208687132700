/**
 * THIS FILE IS AUTOGENERATED
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { RestApiService } from '@shared/utility/api';
import { LocationModel } from '../model/location.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LocationDoloresService extends RestApiService<LocationModel> {
  readonly basePath = '/v2/locations/';
  readonly uniqueIdentifier = 'uuid';

  constructor(config: ApiConfig, http: HttpClient) {
    super(config, http);
  }

  load(location: LocationModel): Observable<LocationModel> {
    return this.http.get<LocationModel>(this.getDoloresPath(this.getIdentifier(location).toUpperCase()));
  }
}
