import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { LogRocketConfig } from './log-rocket.config';
import LogRocket from 'logrocket';

@Injectable({ providedIn: 'root' })
export class LogRocketService {
  private _initialized: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platform: object, private config: LogRocketConfig) {}

  get initialized() {
    return this._initialized;
  }

  initialize(): void {
    if(this.initialized || !this.config.appId) return;

    if (!isPlatformBrowser(this.platform)) {
      throw new Error('LogRocket can only be used in the browser');
    }

    try {
      LogRocket.init(this.config.appId, this.config.options);
      this._initialized = true;
    } catch (e) {
      console.error(e);
    }
  }

  identifyUser(identifier: string, traits?: { [key: string]: string | number | boolean }): void {
    if (!isPlatformBrowser(this.platform)) {
      throw new Error('LogRocket can only be used in the browser');
    }

    if (!this.initialized) {
      console.warn('LogRocket has not been initialized — skipping identification');
    }

    try {
      LogRocket.identify(identifier, traits);
    } catch (e) {
      console.error(e);
    }
  }
}
