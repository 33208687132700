import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ConsumerStateModule } from '../../../consumer/state';
import { OrderEffects } from '../effects/order.effects';
import * as fromOrder from '../reducers/order.reducer';
import { ORDER_KEY } from '../selectors/order.selectors';

@NgModule({
  imports: [
    CommonModule,
    ConsumerStateModule,
    StoreModule.forFeature(ORDER_KEY, fromOrder.reducer),
    EffectsModule.forFeature([OrderEffects])
  ]
})
export class OrderStateModule {}
