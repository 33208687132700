import { PolicyPermissions } from '../policy.permissions';
import { AbstractPolicy } from '../abstract.policy';

export class OrganizationPolicy extends AbstractPolicy {

  protected adminPermissions = [
    // PolicyPermissions.VIEW_BILLING,
    // PolicyPermissions.VIEW_CHARGEBEE_BILLING
  ];

}
