import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { PrinterSelectDialogComponent } from './component/dialog/printer-select-dialog/printer-select-dialog.component';
import { QzConnectErrorDialogComponent } from './component/dialog/qz-connect-error-dialog/qz-connect-error-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatButtonToggleModule,
    MatRadioModule,
    ReactiveFormsModule
  ],
  declarations: [QzConnectErrorDialogComponent, PrinterSelectDialogComponent],
  providers: [DatePipe],
  exports: [QzConnectErrorDialogComponent, PrinterSelectDialogComponent],
  entryComponents: [QzConnectErrorDialogComponent, PrinterSelectDialogComponent]
})
export class QzModule {}
