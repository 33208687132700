import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { BaseApiService } from '@shared/utility/api';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class QzSigningService extends BaseApiService {
  readonly basePath = '/qz-tray';

  constructor(config: ApiConfig, protected http: HttpClient) {
    super(config, http);
  }

  getDigitalCertificate(): Observable<string> {
    return this.http.get('/assets/signing/digital-certificate.txt', {
      headers: { 'Content-Type': 'text/plain' },
      responseType: 'text'
    });
  }

  loadSignature(toSign: string): Observable<string> {
    return this.http.get(this.getPath(), {
      headers: { 'Content-Type': 'text/plain' },
      params: { request: toSign },
      responseType: 'text'
    });
  }
}
