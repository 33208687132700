import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IntercomHideDirective } from './directive/hide.directive';
import { IntercomShowMessagesDirective } from './directive/show-messages.directive';
import { IntercomShowDirective } from './directive/show.directive';
import { IntercomShutdownDirective } from './directive/shutdown.directive';
import { IntercomTrackEventDirective } from './directive/track-event.directive';
import { IntercomConfig } from './model/intercom.model';
import { Intercom } from './service/intercom.service';

@NgModule({
  imports: [RouterModule],
  declarations: [
    IntercomHideDirective,
    IntercomShowMessagesDirective,
    IntercomShowDirective,
    IntercomShutdownDirective,
    IntercomTrackEventDirective
  ],
  exports: [
    IntercomHideDirective,
    IntercomShowMessagesDirective,
    IntercomShowDirective,
    IntercomShutdownDirective,
    IntercomTrackEventDirective
  ]
})
export class IntercomModule {
  static forRoot(config: IntercomConfig): ModuleWithProviders<IntercomModule> {
    return {
      ngModule: IntercomModule,
      providers: [{ provide: IntercomConfig, useValue: config }, Intercom]
    };
  }
}
