import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import {
  AutocompleteResponseInterface,
  ListItemInterface,
  ProductCategoryInterface,
  TypedEntityInterface
} from '../interface';

interface FormDataInterface {
  product_category: ProductCategoryInterface,
  taxes: []
}

export interface ProductCategoryTaxInterface {
  category: TypedEntityInterface;
  taxes: ProductCategoryTaxLineInterface[];
}

export interface ProductCategoryTaxLineInterface {
  uuid: string,
  name: string,
  enabled: boolean
}

@Injectable({
  providedIn: 'root'
})
export class ProductCategoryApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/v2/product-categories`;
  }

  public all(params?: any): Observable<ProductCategoryInterface[]> {
    return this.http.get<ProductCategoryInterface[]>(`${this.basePath}/all`, { params });
  }

  public delete(uuid: string): Observable<void> {
    return this.http.delete<void>(`${this.basePath}/${uuid}`);
  }

  public formData(uuid: string): Observable<FormDataInterface> {
    return this.http.get<FormDataInterface>(`${this.basePath}/form-data`, { params: { uuid: uuid || '' } });
  }

  public upsert(params: any) {
    return this.http.post<void>(`${this.basePath}`, params);
  }

  public autocomplete(params: any): Observable<AutocompleteResponseInterface<ProductCategoryInterface>> {
    return this.http.get<AutocompleteResponseInterface<ProductCategoryInterface>>(`${this.basePath}/autocomplete`, { params });
  }

  public dropdown(): Observable<ListItemInterface[]> {
    return this.http.get<ListItemInterface[]>(`${this.basePath}/dropdown`);
  }

  public genericTaxes(): Observable<ProductCategoryTaxInterface[]> {
    return this.http.get<ProductCategoryTaxInterface[]>(`${this.basePath}/generic-taxes`);
  }

  public saveGenericTaxes(categories: ProductCategoryTaxInterface[]): Observable<ProductCategoryTaxInterface[]> {
    return this.http.post<ProductCategoryTaxInterface[]>(`${this.basePath}/generic-taxes`, { categories });
  }
}
