import { StatusModel } from '@shared/enumeration/status/status.model';

export enum OrderSourceEnum {
  IN_STORE = 1,
  ONLINE = 2,
  KIOSK = 3
}

export const InStoreOrderSource: StatusModel = {
  id: OrderSourceEnum.IN_STORE,
  name: 'In Store'
};
export const OnlineOrderSource: StatusModel = {
  id: OrderSourceEnum.ONLINE,
  name: 'Online'
};
export const KioskOrderSource: StatusModel = {
  id: OrderSourceEnum.KIOSK,
  name: 'Kiosk'
};

// Kiosk has been excluded as it is not currently a valid order source for the Refresh Kitchen or MyBB
export const AllOrderSources: StatusModel[] = [InStoreOrderSource, OnlineOrderSource];
