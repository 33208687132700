import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TimeSelectComponent } from './time-select.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [TimeSelectComponent],
  exports: [TimeSelectComponent]
})
export class TimeSelectModule {}
