import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable, of } from 'rxjs';
import {
  ListItemInterface,
  LoyaltyProgramInterface,
  PagyCollectionInterface,
  RewardDetailInterface,
  RewardInterface,
  TypedEntityInterface
} from '../interface';

export interface PageDataResponseInterface {
  locations: TypedEntityInterface[];
  loyalty_program: LoyaltyProgramInterface;
}

@Injectable({
  providedIn: 'root'
})
export class RewardApiService {
  baseUrl: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.baseUrl = `${this.config.baseUrl}/v2/rewards`;
  }

  public pageData(): Observable<PageDataResponseInterface> {
    return this.http.get<PageDataResponseInterface>(`${this.baseUrl}/page-data`);
  }

  public list(params: any): Observable<PagyCollectionInterface<RewardInterface>> {
    return this.http.get<PagyCollectionInterface<RewardInterface>>(`${this.baseUrl}`, { params });
  }

  public show(uuid: string | null): Observable<RewardDetailInterface> {
    if (!uuid) {
      return of(null);
    }
    return this.http.get<RewardDetailInterface>(`${this.baseUrl}/${uuid}`);
  }

  public upsert(params: any): Observable<RewardDetailInterface> {
    return this.http.post<RewardDetailInterface>(`${this.baseUrl}`, params);
  }

  public delete(uuid: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${uuid}`);
  }

  public dropdown(): Observable<ListItemInterface[]> {
    return this.http.get<ListItemInterface[]>(`${this.baseUrl}/dropdown`);
  }
}
