import { isPlatformBrowser } from '@angular/common';
import { Inject, InjectionToken, ModuleWithProviders, NgModule, Optional, PLATFORM_ID, SkipSelf } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { AnalyticsProviderEnum } from '@shared/analytics';
import { filter } from 'rxjs/operators';
import { GoogleTagManagerCredentialsInterface } from '../interface/gtm-options.interface';
import { AnalyticsService } from '../service/analytics.service';

const GOOGLE_TAG_MANAGER_CONFIG = new InjectionToken<GoogleTagManagerCredentialsInterface>(
  'Credentials for initializing tag manager'
);
const GOOGLE_TAG_MANAGER_OPTIONS = new InjectionToken<GoogleTagManagerCredentialsInterface>(
  'Options when initializing tag manager'
);

interface GoogleTagManagerModuleOptionsInterface {
  trackPageViews: boolean;
}

const GOOGLE_TAG_MANAGER_MODULE_OPTION_DEFAULTS: GoogleTagManagerModuleOptionsInterface = {
  trackPageViews: false
};

@NgModule({
  imports: [RouterModule]
})
export class GoogleTagManagerModule {
  previousUrl: string | undefined;

  constructor(
    router: Router,
    analyticsService: AnalyticsService,
    @Inject(PLATFORM_ID) platform: object,
    @Inject(GOOGLE_TAG_MANAGER_CONFIG) config: GoogleTagManagerCredentialsInterface,
    @Inject(GOOGLE_TAG_MANAGER_OPTIONS) options: GoogleTagManagerModuleOptionsInterface,
    @Optional() @SkipSelf() parentModule?: GoogleTagManagerModule
  ) {
    if (parentModule) {
      throw new Error('GoogleTagManagerModule is already loaded');
    } else if (isPlatformBrowser(platform) && config?.id) {
      analyticsService.initializeTracker(AnalyticsProviderEnum.GTM, config);
    }

    if (options.trackPageViews) {
      router.events
        .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          const rootUrl = event.url.split('?')[0];
          if (rootUrl !== this.previousUrl) {
            if (this.previousUrl) analyticsService.trackPageView(rootUrl);
            this.previousUrl = rootUrl;
          }
        });
    }
  }

  static forRoot(
    config: GoogleTagManagerCredentialsInterface,
    options: Partial<GoogleTagManagerModuleOptionsInterface> = {}
  ): ModuleWithProviders<GoogleTagManagerModule> {
    return {
      ngModule: GoogleTagManagerModule,
      providers: [
        { provide: GOOGLE_TAG_MANAGER_CONFIG, useValue: config },
        { provide: GOOGLE_TAG_MANAGER_OPTIONS, useValue: { ...options, ...GOOGLE_TAG_MANAGER_MODULE_OPTION_DEFAULTS } }
      ]
    };
  }
}
