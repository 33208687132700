import { ConsumerModel as LocationConsumerModel } from '@shared/entity/consumer/model';
import { ConsumerInterestModel as LocationConsumerInterestModel } from '@shared/entity/consumer-interest/model';
import { ConsumerInterestParameters } from '@shared/entity/consumer-interest/service';
import { ErrorModel } from '@shared/model/error/error.model';
import { Action } from '@ngrx/store';
import { CollectionInterface } from '@shared/model/collection/collection.interface';

export enum LocationConsumerInterestActionTypes {
  LoadLocationConsumerInterests = '[Location Consumer Interest] Load Location Consumer Interests',
  LoadLocationConsumerInterestsSuccess = '[Location Consumer Interest] Load Location Consumer Interests Success',
  LoadLocationConsumerInterestsFailure = '[Location Consumer Interest] Load Location Consumer Interests Failure',

  LoadLocationConsumerInterest = '[Location Consumer Interest] Load Location Consumer Interest',
  LoadLocationConsumerInterestSuccess = '[Location Consumer Interest] Load Location Consumer Interest Success',
  LoadLocationConsumerInterestFailure = '[Location Consumer Interest] Load Location Consumer Interest Failure',

  UpsertLocationConsumerInterest = '[Location Consumer Interest] Upsert Location Consumer Interest',
  UpsertLocationConsumerInterestSuccess = '[Location Consumer Interest] Upsert Location Consumer Interest Success',
  UpsertLocationConsumerInterestFailure = '[Location Consumer Interest] Upsert Location Consumer Interest Failure',

  DeleteLocationConsumerInterest = '[Location Consumer Interest] Delete Location Consumer Interest',
  DeleteLocationConsumerInterestSuccess = '[Location Consumer Interest] Delete Location Consumer Interest Success',
  DeleteLocationConsumerInterestFailure = '[Location Consumer Interest] Delete Location Consumer Interest Failure',

  ClearLocationConsumerInterests = '[Location Consumer Interest] Clear Location Consumer Interests'
}

export class LoadLocationConsumerInterests implements Action {
  readonly type = LocationConsumerInterestActionTypes.LoadLocationConsumerInterests;

  constructor(
    public payload: { consumer: LocationConsumerModel; params: ConsumerInterestParameters; preserveRecords: boolean }
  ) {}
}

export class LoadLocationConsumerInterestsSuccess implements Action {
  readonly type = LocationConsumerInterestActionTypes.LoadLocationConsumerInterestsSuccess;

  constructor(
    public payload: {
      consumer: LocationConsumerModel;
      interests: CollectionInterface<LocationConsumerInterestModel>;
      preserveRecords: boolean;
    }
  ) {}
}

export class LoadLocationConsumerInterestsFailure implements Action {
  readonly type = LocationConsumerInterestActionTypes.LoadLocationConsumerInterestsFailure;

  constructor(public payload: ErrorModel) {}
}

export class LoadLocationConsumerInterest implements Action {
  readonly type = LocationConsumerInterestActionTypes.LoadLocationConsumerInterest;

  constructor(public payload: { consumer: LocationConsumerModel; id: string }) {}
}

export class LoadLocationConsumerInterestSuccess implements Action {
  readonly type = LocationConsumerInterestActionTypes.LoadLocationConsumerInterestSuccess;

  constructor(public payload: { consumer: LocationConsumerModel; interest: LocationConsumerInterestModel }) {}
}

export class LoadLocationConsumerInterestFailure implements Action {
  readonly type = LocationConsumerInterestActionTypes.LoadLocationConsumerInterestFailure;

  constructor(public payload: ErrorModel) {}
}

export class UpsertLocationConsumerInterest implements Action {
  readonly type = LocationConsumerInterestActionTypes.UpsertLocationConsumerInterest;

  constructor(public payload: { consumer: LocationConsumerModel; interest: LocationConsumerInterestModel }) {}
}

export class UpsertLocationConsumerInterestSuccess implements Action {
  readonly type = LocationConsumerInterestActionTypes.UpsertLocationConsumerInterestSuccess;

  constructor(public payload: { consumer: LocationConsumerModel; interest: LocationConsumerInterestModel }) {}
}

export class UpsertLocationConsumerInterestFailure implements Action {
  readonly type = LocationConsumerInterestActionTypes.UpsertLocationConsumerInterestFailure;

  constructor(public payload: ErrorModel) {}
}

export class DeleteLocationConsumerInterest implements Action {
  readonly type = LocationConsumerInterestActionTypes.DeleteLocationConsumerInterest;

  constructor(public payload: { consumer: LocationConsumerModel; id: string }) {}
}

export class DeleteLocationConsumerInterestSuccess implements Action {
  readonly type = LocationConsumerInterestActionTypes.DeleteLocationConsumerInterestSuccess;

  constructor(public payload: { consumer: LocationConsumerModel; id: string }) {}
}

export class DeleteLocationConsumerInterestFailure implements Action {
  readonly type = LocationConsumerInterestActionTypes.DeleteLocationConsumerInterestFailure;

  constructor(public payload: ErrorModel) {}
}

export class ClearLocationConsumerInterests implements Action {
  readonly type = LocationConsumerInterestActionTypes.ClearLocationConsumerInterests;
}

export type LocationConsumerInterestActions =
  | LoadLocationConsumerInterests
  | LoadLocationConsumerInterestsSuccess
  | LoadLocationConsumerInterestsFailure
  | LoadLocationConsumerInterest
  | LoadLocationConsumerInterestSuccess
  | LoadLocationConsumerInterestFailure
  | UpsertLocationConsumerInterest
  | UpsertLocationConsumerInterestSuccess
  | UpsertLocationConsumerInterestFailure
  | DeleteLocationConsumerInterest
  | DeleteLocationConsumerInterestSuccess
  | DeleteLocationConsumerInterestFailure
  | ClearLocationConsumerInterests;
