import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ClipboardModule } from 'ngx-clipboard';
import { CodeHighlightService } from '../service/code-highlight.service';
import { CodeComponent } from './code.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, ClipboardModule, MatButtonModule],
  declarations: [CodeComponent],
  exports: [CodeComponent],
  providers: [CodeHighlightService]
})
export class CodeModule {}
