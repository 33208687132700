export enum OrderStatusEnum {
  Cart = 0,
  Pending = 1,
  Saved = 2,
  Ready = 3,
  Draft = 4,
  Cancelled = 254,
  Completed = 255
}

export enum OrderStringStatus {
  CANCELLED = 'cancelled',
  CART = 'cart',
  COMPLETED = 'completed',
  DRAFT = 'draft',
  PENDING = 'pending',
  READY = 'ready',
  SAVED = 'saved',
}

const CartStatus = {
  id: OrderStatusEnum.Cart,
  name: 'Cart',
  color: 'yellow-700'
};

const PendingStatus = {
  id: OrderStatusEnum.Pending,
  name: 'Pending',
  color: 'orange-700'
};

const SavedStatus = {
  id: OrderStatusEnum.Saved,
  name: 'Saved',
  color: 'blue-700'
};

const ReadyStatus = {
  id: OrderStatusEnum.Ready,
  name: 'Ready',
  color: 'cyan-700'
};

const DraftStatus = {
  id: OrderStatusEnum.Draft,
  name: 'Draft',
  color: 'purple-700'
};

const CancelledStatus = {
  id: OrderStatusEnum.Cancelled,
  name: 'Cancelled',
  color: 'red-700'
};

const CompletedStatus = {
  id: OrderStatusEnum.Completed,
  name: 'Completed',
  color: 'green-700'
};

export const AllOrderStatuses = [CartStatus, PendingStatus, SavedStatus, ReadyStatus, DraftStatus, CancelledStatus, CompletedStatus];

export const WholesaleNonCompleteOrderStatuses = [CartStatus, PendingStatus, SavedStatus, ReadyStatus, CancelledStatus, DraftStatus];

export const RetailNonCompleteOrderStatuses = [PendingStatus, SavedStatus, ReadyStatus, DraftStatus];

export const RetailOrderStatuses = [PendingStatus, SavedStatus, ReadyStatus, DraftStatus, CompletedStatus, CancelledStatus];