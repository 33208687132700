import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TextareaCharacterCountComponent } from './textarea-character-count.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [TextareaCharacterCountComponent],
  exports: [TextareaCharacterCountComponent]
})
export class TextareaCharacterCountModule {}
