export class IntercomConfig {
  appId: string;
  updateOnRouterChange?: boolean;
}

export interface IntercomConfigInterface {
  /**
   * Intercom App ID
   */
  app_id: string;

  /**
   * Customize left or right position of messenger
   */
  alignment?: 'left' | 'right';

  /**
   * Customize horizontal padding
   */
  horizontal_padding?: number;

  /**
   * Customize vertical padding
   */
  vertical_padding?: number;
}

export interface UserWithEmail {
  email: string;
}

export interface UserWithUid {
  user_id: string;
}

export interface Any {
  [propName: string]: any;
}

export interface DefaultBootInput {
  custom_launcher_selector?: string;
}

export type BootInput = ((UserWithEmail | UserWithUid) & DefaultBootInput & Any) | DefaultBootInput;
