import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

import { LocationsDrawerComponent } from './locations-drawer.component';
import { TabsModule } from '../tabs/tabs.module';

@NgModule({
  imports: [MatSidenavModule, MatButtonModule, MatIconModule, MatToolbarModule, TabsModule, CommonModule],
  declarations: [LocationsDrawerComponent],
  exports: [LocationsDrawerComponent]
})
export class LocationsDrawerModule {}
