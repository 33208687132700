import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil, map, debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { MessagePollingService } from '@myblackbird/shared/service/message-polling';
import { ConsumerModel, IConsumerData } from '@shared/entity/consumer/model';
import { ConsumerParameters, ConsumerService } from '@shared/entity/consumer/service';
import { CdkScrollable } from '@angular/cdk/overlay';
import { ConfirmationDialogComponent, ConfirmationDialogDataInterface } from '@shared/dialog';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'frontend-consumer-list',
  templateUrl: './consumer-list.component.html',
  styleUrls: ['./consumer-list.component.scss']
})
export class ConsumerListComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {
  @ViewChild(CdkScrollable) cdkScrollRef: CdkScrollable;

  @Input() consumerSearchInput: string;

  destroy$ = new Subject<void>();

  selectedConversationUri: string;
  consumerParameters: ConsumerParameters;
  loadingConsumers: boolean;
  allConsumers: boolean = false;

  consumers: IConsumerData[];

  trackByConsumerFn = (index: number, item: { uuid: string }) => (item ? item.uuid : null);

  constructor(
    private messagePollingService: MessagePollingService,
    private consumerService: ConsumerService,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.resetSelection();
    this.consumerParameters = new ConsumerParameters().setLimit(20);
    this.loadConsumers();
  }

  ngOnChanges(change: SimpleChanges) {
    if (change['consumerSearchInput']) {
      this.resetSelection();
      if (this.consumerParameters) {
        this.allConsumers = false;
        this.consumerParameters.setOffset(0).setGlobalSearch(this.consumerSearchInput);
        this.loadConsumers();
      }
    }
  }

  ngAfterViewInit() {
    this.subscribeCustomerScroll();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onConsumerSelected(consumer: ConsumerModel): void {
    if (consumer.cell_phone) {
      this.messagePollingService.selectConversation(consumer.uuid, true);
      this.messagePollingService.searchConversation(consumer.cell_phone);
      this.messagePollingService.refreshPolling();
      this.selectedConversationUri = consumer.cell_phone;
    } else {
      this.matDialog.open<ConfirmationDialogComponent, ConfirmationDialogDataInterface>(ConfirmationDialogComponent, {
        data: {
          title: 'Warning',
          message: 'This customer does not have a cell phone number in their profile.',
          showConfirmButton: false
        }
      });
    }
  }

  loadConsumers() {
    if (!this.allConsumers) {
      this.loadingConsumers = true;
      this.consumerService
        .loadCollection(this.consumerParameters)
        .pipe(finalize(() => (this.loadingConsumers = false)))
        .subscribe(collection => {
          if (!collection.has_previous_page) {
            this.consumers = [];
          }
          this.consumers = this.consumers.concat(collection.records);
          this.allConsumers = !collection.has_next_page;
          this.consumerParameters.setOffset(this.consumerParameters.getOffset() + this.consumerParameters.getLimit());
        });
    }
  }

  subscribeCustomerScroll() {
    if (this.cdkScrollRef) {
      this.cdkScrollRef
        .elementScrolled()
        .pipe(
          debounceTime(50),
          distinctUntilChanged(),
          map(() => this.cdkScrollRef.measureScrollOffset('bottom')),
          filter(bottom => bottom === 0),
          takeUntil(this.destroy$)
        )
        .subscribe(() => {
          this.loadConsumers();
        });
    }
  }

  resetSelection() {
    this.messagePollingService.selectConversation(null, false);
    this.messagePollingService.searchConversation(null);
    this.selectedConversationUri = null;
  }
}
