/**
 * THIS FILE IS AUTOGENERATED
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrderModel } from '../../model';
import { adapter, State } from '../reducers/order.reducer';

export const ORDER_KEY = 'ORDER';

const getOrderState = createFeatureSelector<State>(ORDER_KEY);

const { selectEntities } = adapter.getSelectors(getOrderState);

const getLoading = createSelector(
  getOrderState,
  (state: State) => state.loading
);

const getUpdating = createSelector(
  getOrderState,
  (state: State) => state.updating
);

const getLoadingOrUpdating = createSelector(
  getLoading,
  getUpdating,
  (loading, updating) => loading || updating
);

const getError = createSelector(
  getOrderState,
  (state: State) => state.error
);

const getCurrentPage = createSelector(
  getOrderState,
  (state: State) => state.currentPage
);

const getTotalPages = createSelector(
  getOrderState,
  (state: State) => state.totalPages
);

const getTotalRecords = createSelector(
  getOrderState,
  (state: State) => state.totalRecords
);

const getPagination = createSelector(
  getTotalPages,
  getCurrentPage,
  getTotalRecords,
  (total, current, records) => ({
    totalPages: total,
    currentPage: current,
    totalRecords: records
  })
);

const getCanLoadMore = createSelector(
  getCurrentPage,
  getTotalPages,
  (current, total) => current < total
);

const getSelectedId = createSelector(
  getOrderState,
  (state: State) => state.selected
);

const getSelected = createSelector(
  selectEntities,
  getSelectedId,
  (entities, id) => {
    const result = entities[id];
    return result ? new OrderModel(result) : undefined;
  }
);

export const orderQuery = {
  ...adapter.getSelectors(getOrderState),
  getLoading,
  getUpdating,
  getLoadingOrUpdating,
  getError,
  getSelectedId,
  getSelected,
  getCurrentPage,
  getTotalPages,
  getTotalRecords,
  getPagination,
  getCanLoadMore
};
