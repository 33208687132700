import { EnhancedEcommerce } from '@shared/analytics';
import {
  AnalyticsCartProductInterface,
  AnalyticsInterface,
  AnalyticsProductDetailInterface,
  AnalyticsProductImpressionInterface,
  AnalyticsPromoClickInterface,
  AnalyticsPromoImpressionInterface,
  AnalyticsSubmitCartInterface,
  WindowWithAnalytics
} from '../../interface/analytics-tracker.interface';

// https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#ecommerce-data
export class GoogleUniversalAnalytics implements AnalyticsInterface {
  constructor(private trackingId: string, private scope: string = 'blackbirdClient') {
    if (window && document) this.initializeTracker();
  }

  initializeTracker() {
    (window as WindowWithAnalytics)['dataLayer'] = (window as WindowWithAnalytics)['dataLayer'] || [];

    this.initializeScript(this.trackingId);

    this.push('js', new Date());
    this.push('config', this.trackingId, { groups: this.scope, cookie_flags: 'max-age=7200;secure;samesite=none' });
  }

  public trackPageView(page: string, title?: string): void {
    this.push('set', 'page_path', page);
    this.push('event', 'page_view');
  }

  addProductImpressions(impressions: AnalyticsProductImpressionInterface[], listName?: string): void {
    const data: EnhancedEcommerce.ImpressionFieldObjectInterface[] = impressions.map((item, index) => ({
      id: item.product.uuid,
      name: item.product.name,
      brand: item.product.brand?.name,
      category: item.product.product_category.name,
      price: item.price,
      list_position: index,
      list_name: listName
    }));

    this.push('event', 'view_item_list', { items: data, send_to: this.scope });
  }

  addPromoImpressions(promos: AnalyticsPromoImpressionInterface[]): void {
    const data: EnhancedEcommerce.PromoFieldObjectInterface[] = promos.map(promo => ({
      id: promo.slug,
      name: promo.name,
      creative_name: promo.creative_name,
      creative_slot: promo.creative_slot
    }));
    this.push('event', 'view_promotion', { promotions: data, send_to: this.scope });
  }

  addPromoClick(promo: AnalyticsPromoClickInterface): void {
    const data: EnhancedEcommerce.PromoFieldObjectInterface = {
      id: promo.slug,
      name: promo.name,
      creative_name: promo.creative_name,
      creative_slot: promo.creative_slot
    };
    this.push('event', 'select_content', { promotions: [data], send_to: this.scope });
  }

  viewProductDetail(product: AnalyticsProductDetailInterface): void {
    const data: EnhancedEcommerce.ProductFieldObjectInterface = {
      id: product.uuid,
      name: product.name,
      brand: product.brand?.name,
      category: product.product_category.name
    };
    this.push('event', 'view_item', { items: [data], send_to: this.scope });
  }

  addToCart(products: AnalyticsCartProductInterface | AnalyticsCartProductInterface[]): void {
    if (!Array.isArray(products)) products = [products];
    const data: EnhancedEcommerce.ProductFieldObjectInterface[] = products.map(item => ({
      id: item.inventory.product.uuid,
      name: item.inventory.product.name,
      brand: item.inventory.product.brand ? (item.inventory.product.brand.name ?? (<any>item.inventory.product.brand)?._name) : null,
      category: item.inventory.product.category.name,
      price: item.price_each,
      quantity: item.quantity,
      variant: item.inventory.unit_label
    }));
    this.push('event', 'add_to_cart', { items: data, send_to: this.scope });
  }

  removeFromCart(products: AnalyticsCartProductInterface | AnalyticsCartProductInterface[]): void {
    if (!Array.isArray(products)) products = [products];
    const data: EnhancedEcommerce.ProductFieldObjectInterface[] = products.map(item => ({
      id: item.inventory.product.uuid,
      name: item.inventory.product.name,
      brand: item.inventory.product.brand ? (item.inventory.product.brand.name ?? (<any>item.inventory.product.brand)?._name) : null,
      category: item.inventory.product.category.name,
      price: item.price_each,
      quantity: item.quantity,
      variant: item.inventory.unit_label
    }));
    this.push('event', 'remove_from_cart', { items: data, send_to: this.scope });
  }

  checkout(products: AnalyticsCartProductInterface[]): void {
    const data: EnhancedEcommerce.ProductFieldObjectInterface[] = products.map(item => ({
      id: item.inventory.product.uuid,
      name: item.inventory.product.name,
      brand: item.inventory.product.brand ? (item.inventory.product.brand.name ?? (<any>item.inventory.product.brand)?._name) : null,
      category: item.inventory.product.category.name,
      price: item.price_each,
      quantity: item.quantity,
      variant: item.inventory.unit_label
    }));

    this.push('event', 'begin_checkout', { items: data, send_to: this.scope });
  }

  purchase(cart: AnalyticsSubmitCartInterface): void {
    const data: EnhancedEcommerce.ProductFieldObjectInterface[] = cart.products.map(item => ({
      id: item.uuid,
      name: item.name,
      brand: 'name' in item.brand ? item.brand.name : item.brand._name,
      category: 'name' in item.category ? item.category.name : item.category._name,
      price: item.price_each,
      quantity: item.quantity,
      variant: item.unit_label
    }));

    this.push('event', 'purchase', {
      send_to: this.scope,
      transaction_id: cart.uuid,
      affiliation: 'Blackbird Menu',
      value: cart.total,
      currency: 'USD',
      tax: cart.tax_total,
      shipping: cart.delivery_rate,
      items: data
    });
  }

  public identifyUser(id: string) {
    this.push('set', { user_id: id });
  }

  protected push(...args: any[]) {
    if (!window) return;
    (window as WindowWithAnalytics)['dataLayer'].push(arguments);
  }

  private initializeScript(id: string, queryParams: { [key: string]: string } = {}) {
    let url = `https://www.googletagmanager.com/gtag/js?id=${id}`;
    let optionKeys = Object.keys(queryParams).filter(key => !!queryParams[key]);

    if (optionKeys.length) {
      let params = optionKeys.map(key => `${key}=${queryParams[key]}`);
      url = url + `&${params.join('&')}`;
    }

    let script: HTMLScriptElement = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.async = true;

    document.getElementsByTagName('head')[0].appendChild(script);
  }
}
