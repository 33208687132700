import { Pipe, PipeTransform } from '@angular/core';
import { OrderModel } from '@shared/entity/order/model';

@Pipe({ name: 'orderType' })
export class OrderTypePipe implements PipeTransform {
  transform(order: OrderModel): string {

    let source = order.menu ? 'Online' : 'In-store';
    let method;

    switch(order.transfer_method) {
      case 1:
        method = 'Pickup';
      break;
      case 2:
        method = 'Delivery';
      break;
      case 3:
        method = 'Curbside';
      break;
    }

    return `${source} ${method}`;
  }
}
