import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { NavigationError, Router } from '@angular/router';
import { WINDOW } from '@shared/window';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * @description
 *
 * A service that applications can use to handle lazy load errors.
 *
 * This service listens for NavigationError events and triggers an app reload when a lazy load exception is thrown.
 *
 * A lazy load error can occur when:
 *  - A deployment causes new hashes to be generated for files, but a client app is still referencing the old ones
 *  - A network outage happens and the server does not respond to the request for the lazy loaded script
 *
 * The app reload fixes the above scenarios in both ways:
 *
 *  - Re-fetching the index.html file will resolve the new hashes generated by the deployment
 *  - With a network outage, the browser's loss of connection will be displayed to the user
 *
 *
 * @usageNotes
 *
 * This service should be provided only once in an application, and depends on the Router and Window packages
 *
 */
@Injectable({
  providedIn: 'root'
})
export class LazyErrorService implements OnDestroy {
  routerErrorSubscription: Subscription;

  constructor(private router: Router, @Inject(WINDOW) private window: Window, private matSnackBar: MatSnackBar) {
  }

  ngOnDestroy() {
    if (this.routerErrorSubscription) this.routerErrorSubscription.unsubscribe();
  }

  public subscribeRouteError() {
    if (this.routerErrorSubscription) return;
    this.routerErrorSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationError && event.error.message))
      .subscribe((event: NavigationError) => {
        if (event.error.message.includes('Loading chunk')) {
          this.checkChunk();
          return;
        }

        this.matSnackBar.open(event.error.message);
      });
  }

  private checkChunk() {
    this.matSnackBar
      .open('Error loading section. Please reload and try again.', 'Reload', { duration: 7000 })
      .afterDismissed()
      .subscribe((snackBarDismiss: MatSnackBarDismiss) => {
        if (snackBarDismiss.dismissedByAction) this.window.location.reload();
      });
  }
}
