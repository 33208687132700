/**
 * THIS FILE IS AUTOGENERATED
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LocationEffects } from '../effects/location.effects';
import * as fromLocation from '../reducers/location.reducer';
import { LOCATION_KEY } from '../selectors/location.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(LOCATION_KEY, fromLocation.reducer),
    EffectsModule.forFeature([LocationEffects])
  ]
})
export class LocationStateModule {}
