import { EnhancedEcommerce } from '@shared/analytics';
import {
  AnalyticsCartProductInterface,
  AnalyticsInterface,
  AnalyticsProductDetailInterface,
  AnalyticsProductImpressionInterface,
  AnalyticsPromoClickInterface,
  AnalyticsPromoImpressionInterface,
  AnalyticsSubmitCartInterface,
  WindowWithAnalytics
} from '../../interface/analytics-tracker.interface';
import { GoogleTagManagerCredentialsInterface } from '../../interface/gtm-options.interface';

// https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce
export class GoogleTagManager implements AnalyticsInterface {
  constructor(private gtmKeys: GoogleTagManagerCredentialsInterface) {
    if (window && document) this.initializeTracker();
  }

  public trackPageView(page: string, title?: string) {
    this.push({
      event: 'Page View',
      page,
      title
    });
  }

  public identifyUser(id: string) {
    this.push('set', { user_id: id });
  }

  addProductImpressions(impressions: AnalyticsProductImpressionInterface[], listName?: string): void {
    const data: EnhancedEcommerce.ImpressionFieldObjectInterface[] = impressions.map(impression => ({
      id: impression.product.uuid,
      name: impression.product.name,
      category: impression.product.product_category.name,
      brand: impression.product.brand?.name,
      variant: impression.unit_label,
      price: impression.price,
      list_name: listName
    }));

    this.push({
      event: 'productImpressions',
      ecommerce: {
        data
      }
    });
  }

  addPromoImpressions(promos: AnalyticsPromoImpressionInterface[]): void {
    const data: EnhancedEcommerce.PromoFieldObjectInterface | EnhancedEcommerce.PromoFieldObjectInterface[] =
      promos.map(promo => ({
        id: promo.slug,
        name: promo.name
      }));

    this.push({
      event: 'promoImpressions',
      ecommerce: {
        promoView: {
          promotions: data
        }
      }
    });
  }

  addPromoClick(promo: AnalyticsPromoClickInterface) {
    const data: EnhancedEcommerce.PromoFieldObjectInterface = {
      id: promo.slug,
      name: promo.name
    };

    this.push({
      event: 'promotionClick',
      ecommerce: {
        promoClick: {
          promotions: [data]
        }
      }
    });
  }

  viewProductDetail(product: AnalyticsProductDetailInterface): void {
    const data: EnhancedEcommerce.ProductFieldObjectInterface = {
      id: product.uuid,
      name: product.name,
      brand: product.brand?.name,
      category: product.product_category.name
    };

    this.push({
      event: 'productDetail',
      ecommerce: {
        detail: {
          products: [data]
        }
      }
    });
  }

  addToCart(cartProducts: AnalyticsCartProductInterface | AnalyticsCartProductInterface[]): void {
    cartProducts = Array.isArray(cartProducts) ? cartProducts : [cartProducts];

    const data: EnhancedEcommerce.ProductFieldObjectInterface[] = cartProducts.map(cartProduct => ({
      id: cartProduct.inventory.product.uuid,
      name: cartProduct.inventory.product.name,
      brand: cartProduct.inventory.product.brand ? (cartProduct.inventory.product.brand.name ?? (<any>cartProduct.inventory.product.brand)?._name) : null,
      category: cartProduct.inventory.product.category.name,
      variant: cartProduct.inventory.unit_label,
      quantity: cartProduct.quantity,
      price: cartProduct.subtotal
      }));
    this.push({
      event: 'addToCart',
      ecommerce: {
        add: {
          actionField: {
            action: 'add'
          },
          products: data
        }
      }
    });
  }

  removeFromCart(cartProducts: AnalyticsCartProductInterface | AnalyticsCartProductInterface[]): void {
    cartProducts = Array.isArray(cartProducts) ? cartProducts : [cartProducts];

    const data: EnhancedEcommerce.ProductFieldObjectInterface[] = cartProducts.map(cartProduct => ({
      id: cartProduct.inventory.product.uuid,
      name: cartProduct.inventory.product.name,
      brand: cartProduct.inventory.product.brand?.name,
      category: cartProduct.inventory.product.category.name,
      variant: cartProduct.inventory.unit_label,
      quantity: cartProduct.quantity,
      price: cartProduct.subtotal
    }));

    this.push({
      event: 'removeFromCart',
      ecommerce: {
        remove: {
          products: data
        }
      }
    });
  }

  checkout(cartProducts: AnalyticsCartProductInterface[]): void {
    const data: EnhancedEcommerce.ProductFieldObjectInterface[] = cartProducts.map(cartProduct => ({
      id: cartProduct.inventory.product.uuid,
      name: cartProduct.inventory.product.name,
      brand: cartProduct.inventory.product.brand?.name,
      category: cartProduct.inventory.product.product_category.name,
      variant: cartProduct.inventory.unit_label,
      quantity: cartProduct.quantity,
      price: cartProduct.subtotal
    }));

    this.push({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step: 'Open Checkout Dialog',
            action: 'checkout'
          },
          data
        }
      }
    });
  }

  purchase(order: AnalyticsSubmitCartInterface): void {
    const dataProducts: EnhancedEcommerce.ProductFieldObjectInterface[] = order.products.map(product => ({
      id: product.uuid,
      name: product.name,
      brand: product.brand ? ('name' in product.brand ? product.brand.name : product.brand._name) : null,
      category: 'name' in product.category ? product.category.name : product.category._name,
      variant: product.unit_label,
      quantity: product.quantity,
      price: product.price_each
    }));

    const dataDetails: EnhancedEcommerce.ActionFieldObjectInterface = {
      id: order.uuid,
      shipping: order.delivery_rate,
      revenue: order.total,
      tax: order.tax_total,
      affiliation: order.menu.slug
    };

    this.push({
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: {
            ...dataDetails,
            action: 'purchase'
          },
          products: dataProducts
        }
      }
    });
  }

  protected push(...args: any[]) {
    (window as WindowWithAnalytics)['dataLayer'].push(...args);
  }

  private initializeTracker() {
    (window as WindowWithAnalytics)['dataLayer'] = (window as WindowWithAnalytics)['dataLayer'] || [];

    this.initializeScript(this.gtmKeys!.id, this.gtmKeys!.options);

    this.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  }

  private initializeScript(id: string, queryParams: { [key: string]: string } = {}) {
    let url = `https://www.googletagmanager.com/gtm.js?id=${id}`;
    let optionKeys = Object.keys(queryParams).filter(key => !!queryParams[key]);

    if (optionKeys.length) {
      let params = optionKeys.map(key => `${key}=${queryParams[key]}`);
      url = url + `&${params.join('&')}`;
    }

    let script: HTMLScriptElement = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.async = true;

    document.getElementsByTagName('head')[0].appendChild(script);
  }
}
