import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  GuardsCheckEnd,
  GuardsCheckStart,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { Intercom } from '@shared/intercom';
import { ServiceWorkerUpdateService } from '@shared/service-worker';
import { ToasterConfig } from 'angular2-toaster';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AnalyticsProviderEnum, AnalyticsService } from '@shared/analytics';

@Component({
  selector: 'frontend-root',
  template: `
    <mat-progress-bar [hidden]="!loadingRoute" mode="indeterminate"></mat-progress-bar>
    <app-navbar></app-navbar>
    <div id="sub-navigation-tabs-container"></div>
    <toaster-container [toasterconfig]="config"></toaster-container>
    <router-outlet></router-outlet>
    <router-outlet name="manifest"></router-outlet>
  `,
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public loadingRoute: boolean;

  public config: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-full-width',
    timeout: 4000,
    limit: 1
  });

  private destroy$ = new Subject<void>();

  constructor(@Inject(DOCUMENT) private document: Document,
              private intercom: Intercom,
              private router: Router,
              private serviceWorkerUpdateService: ServiceWorkerUpdateService,
              private matSnackbar: MatSnackBar,
              private analyticsService: AnalyticsService) {
  }

  ngOnInit() {
    this.serviceWorkerUpdateService.pollForUpdates();
    this.subscribeLoader();
    this.subscribeAppChanges();
    this.initializeAnalytics();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  subscribeLoader() {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      if (event instanceof NavigationStart || event instanceof GuardsCheckEnd) {
        this.loadingRoute = true;
      } else if (
        event instanceof GuardsCheckStart ||
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.loadingRoute = false;
      }
    });
  }

  private subscribeAppChanges() {
    this.serviceWorkerUpdateService
      .onUpdateAvailable$()
      .subscribe(data => {
        if (data.showUpdateDialog) {
          this.matSnackbar.open(
            'A new app version is available',
            'Reload',
            {
              politeness: 'polite',
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 6000
            })
            .afterDismissed()
            .pipe(filter(res => res.dismissedByAction))
            .subscribe(() => this.document.location.reload());
        }
      });
  }

  private initializeAnalytics(): void {
    if (environment.googleAnalyticsTrackingId) this.analyticsService.initializeTracker(
      AnalyticsProviderEnum.GA,
      environment.googleAnalyticsTrackingId
    );
  }
}
