export enum PolicyPermissions {

  READ_ACTOR_ACL = 'read_actor_acl',
  WRITE_ACTOR_ACL = 'write_actor_acl',

  READ_BRAND = 'read_brand',
  WRITE_BRAND = 'write_brand',

  READ_BUSINESS_INFO = 'read_business_info',
  WRITE_BUSINESS_INFO = 'write_business_info',

  READ_CAMPAIGN_BILLING_PLAN = 'read_campaign_billing_plan',
  WRITE_CAMPAIGN_BILLING_PLAN = 'write_campaign_billing_plan',

  READ_CAMPAIGN_BILLING_STATEMENT = 'read_campaign_billing_statement',
  WRITE_CAMPAIGN_BILLING_STATEMENT = 'write_campaign_billing_statement',

  READ_CAMPAIGN_MESSAGE = 'read_campaign_message',
  READ_CAMPAIGN_MESSAGE_SNAPSHOT = 'read_campaign_message_snapshot',
  WRITE_CAMPAIGN_MESSAGE = 'write_campaign_message',

  READ_CASH_BALANCE = 'read_cash_balance',
  WRITE_CASH_BALANCE = 'write_cash_balance',

  READ_COLLECT_EMBED = 'read_collect_embed',
  WRITE_COLLECT_EMBED = 'write_collect_embed',

  READ_CATEGORY = 'read_category',
  WRITE_CATEGORY = 'write_category',

  READ_CLIENT_MENU_CONFIGURATION = 'read_client_menu_configuration',
  WRITE_CLIENT_MENU_CONFIGURATION = 'write_client_menu_configuration',

  READ_CONFIGURATION_TAXES = 'read_configuration_taxes',
  WRITE_CONFIGURATION_TAXES = 'write_configuration_taxes',

  READ_CONSUMER = 'read_consumer',
  READ_CONSUMER_SNAPSHOT = 'read_consumer_snapshot',
  WRITE_CONSUMER = 'write_consumer',

  READ_CHECK_IN = 'read_check_in',
  WRITE_CHECK_IN = 'write_check_in',

  READ_DELIVERY = 'read_delivery',
  WRITE_DELIVERY = 'write_delivery',

  READ_DELIVERY_LOG = 'read_delivery_log',
  WRITE_DELIVERY_LOG = 'write_delivery_log',

  READ_DISCOUNT = 'read_discount',
  WRITE_DISCOUNT = 'write_discount',

  READ_DISCOUNT_STACK_RULE = 'read_discount_stack_rule',
  WRITE_DISCOUNT_STACK_RULE = 'write_discount_stack_rule',

  READ_DRAWING = 'read_drawing',
  READ_DRAWING_SNAPSHOT = 'read_drawing_snapshot',
  WRITE_DRAWING = 'write_drawing',

  READ_DRAWING_WINNER = 'read_drawing_winner',
  WRITE_DRAWING_WINNER = 'write_drawing_winner',

  READ_EMPLOYEE = 'read_employee',
  WRITE_EMPLOYEE = 'write_employee',

  READ_EVENT = 'read_event',
  WRITE_EVENT = 'write_event',

  READ_INTEGRATION_CONFIGURATION = 'read_integration_configuration',
  WRITE_INTEGRATION_CONFIGURATION = 'write_integration_configuration',

  READ_INVENTORY = 'read_inventory',
  WRITE_INVENTORY = 'write_inventory',

  READ_LISTING = 'read_listing',
  WRITE_LISTING = 'write_listing',

  READ_LOCATION = 'read_location',
  WRITE_LOCATION = 'write_location',

  READ_LOCATION_CONTACT = 'read_location_contact',
  WRITE_LOCATION_CONTACT = 'write_location_contact',

  READ_LOYALTY_MULTIPLIER = 'read_loyalty_multiplier',
  WRITE_LOYALTY_MULTIPLIER = 'write_loyalty_multiplier',

  READ_LOYALTY_PROGRAM = 'read_loyalty_program',
  WRITE_LOYALTY_PROGRAM = 'write_loyalty_program',

  READ_LOYALTY_REWARD = 'read_loyalty_reward',
  READ_LOYALTY_REWARD_SNAPSHOT = 'read_loyalty_reward_snapshot',
  WRITE_LOYALTY_REWARD = 'write_loyalty_reward',

  READ_LOYALTY_REDEMPTION = 'read_loyalty_redemption',
  WRITE_LOYALTY_REDEMPTION = 'write_loyalty_redemption',

  READ_LOYALTY_TIER = 'read_loyalty_tier',
  WRITE_LOYALTY_TIER = 'write_loyalty_tier',

  READ_LOYALTY_TRANSACTION = 'read_loyalty_transaction',
  READ_LOYALTY_TRANSACTION_SNAPSHOT = 'read_loyalty_transaction_snapshot',
  WRITE_LOYALTY_TRANSACTION = 'write_loyalty_transaction',

  READ_LOYALTY_TRIGGER = 'read_loyalty_trigger',
  WRITE_LOYALTY_TRIGGER = 'write_loyalty_trigger',

  READ_MENU = 'read_menu',
  WRITE_MENU = 'write_menu',

  READ_MESSAGE_TRIGGER = 'read_message_trigger',
  WRITE_MESSAGE_TRIGGER = 'write_message_trigger',

  READ_ORDER = 'read_order',
  READ_ORDER_SNAPSHOT = 'read_order_snapshot',
  WRITE_ORDER = 'write_order',

  READ_PRODUCT = 'read_product',
  WRITE_PRODUCT = 'write_product',

  VIEW_MENU_SECTION_SETTINGS = 'view_menu_section_settings',

  READ_PURCHASE_ORDER = 'read_purchase_order',
  WRITE_PURCHASE_ORDER = 'write_purchase_order',

  READ_QUERY = 'read_query',
  WRITE_QUERY = 'write_query',

  READ_REFUND = 'read_refund',
  WRITE_REFUND = 'write_refund',

  READ_SAFE = 'read_safe',
  WRITE_SAFE = 'write_safe',

  READ_SAFE_TRANSFER = 'read_safe_transfer',
  WRITE_SAFE_TRANSFER = 'write_safe_transfer',

  READ_STATION = 'read_station',
  WRITE_STATION = 'write_station',

  READ_STATION_TRANSFER = 'read_station_transfer',
  WRITE_STATION_TRANSFER = 'write_station_transfer',

  READ_USER = 'read_user',
  WRITE_USER = 'write_user',

  VIEW_BILLING = 'view_billing',
  VIEW_CHARGEBEE_BILLING = 'view_chargebee_billing',
  VIEW_DISPATCH = 'view_dispatch',
  VIEW_REPORTING = 'view_reporting',
  VIEW_WHOLESALE_ORDERING = 'view_wholesale_ordering',

  WATCH_PENDING_ORDERS = 'watch_pending_orders',
  WATCH_INCOMING_MESSAGES = 'watch_incoming_messages',

  SEND_CONNECT_MARKETING_SMS = 'campaign_sms',
  SEND_CONNECT_TRANSACTIONAL_SMS = 'single_sms',

  SEND_CONNECT_MARKETING_MMS = 'campaign_mms',
  SEND_CONNECT_TRANSACTIONAL_MMS = 'single_mms',

  SEND_CONNECT_MARKETING_EMAIL = 'campaign_email',
  SEND_CONNECT_TRANSACTIONAL_EMAIL = 'single_email',

  READ_BRAND_MANAGEMENT = 'read_brand_management',
  WRITE_BRAND_MANAGEMENT = 'read_brand_management',

  READ_BRAND_EMBED_SETTINGS = 'read_brand_embed_setting',
  WRITE_BRAND_EMBED_SETTINGS = 'write_brand_embed_setting',

  READ_LOYALTY_EMBED_SETTINGS = 'read_loyalty_embed_settings',
  WRITE_LOYALTY_EMBED_SETTINGS = 'write_loyalty_embed_settings',

  VIEW_STORE_LOCATOR_SETTINGS = 'view_store_locator_settings',

  BALANCE_SAFE = 'balance_station',
  BALANCE_STATION = 'balance_safe',
  TRANSFER_FUNDS = 'transfer_funds',
}
