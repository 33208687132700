import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import {
  LoyaltyProgramInterface,
  LoyaltyRedemptionInterface,
  PagyCollectionInterface,
  TypedEntityInterface
} from '../interface';

interface PageDataInterface {
  locations: TypedEntityInterface[],
  loyalty_program: LoyaltyProgramInterface | null
}

@Injectable({
  providedIn: 'root'
})
export class LoyaltyRedemptionApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/v2/loyalty-redemptions`;
  }

  public pageData(): Observable<PageDataInterface> {
    return this.http.get<PageDataInterface>(`${this.basePath}/page-data`);
  }

  public list(params: any): Observable<PagyCollectionInterface<LoyaltyRedemptionInterface>> {
    return this.http.get<PagyCollectionInterface<LoyaltyRedemptionInterface>>(`${this.basePath}`, { params });
  }

  public redeem(uuid: string): Observable<void> {
    return this.http.post<void>(`${this.basePath}/redeem`, { uuid });
  }

  public orderRedeem(order_uuid: string, uuid: string): Observable<void> {
    return this.http.post<void>(`${this.basePath}/order-redeem`, { order_uuid, uuid });
  }

  public deny(uuid: string): Observable<void> {
    return this.http.post<void>(`${this.basePath}/deny`, { uuid });
  }
}
