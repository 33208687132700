<div class="chat-grid">

  <button type="button" class="close close-button" [mat-dialog-close]>×</button>
  <div class="messaging-center-list">
    <div class="messaging-center-header" [ngStyle]="{'height': openedFromCart ? '70px' : '145px' }">
      <div class="header-top" [ngStyle]="{'height': openedFromCart ? '70px' : 'unset' }">
        <h4>Messaging Center</h4>
        <ng-container *ngxPermissionsOnly="permissions.SEND_CONNECT_TRANSACTIONAL_SMS">
          <button *ngIf="!newMessageSearch && !openedFromCart"
                  class="new-message-btn"
                  mat-mini-fab
                  color="primary"
                  [disabled]="newMessageSearch"
                  (click)="onNewMessageClick()">
            <mat-icon fontSet="fa" fontIcon="fa-plus"></mat-icon>
          </button>
        </ng-container>
        <button *ngIf="newMessageSearch" (click)="backToMessages()" class="new-message-btn" color="accent" mat-mini-fab>
          <mat-icon fontSet="fas" fontIcon="fa-long-arrow-left"></mat-icon>
        </button>
      </div>

      <mat-form-field *ngIf="!newMessageSearch && !openedFromCart">
        <mat-label>Search Phone Number</mat-label>
        <frontend-phone-input [normalize]="true" [formControl]="searchMessagesCtrl"></frontend-phone-input>
      </mat-form-field>

      <mat-form-field *ngIf="newMessageSearch">
        <mat-label>Search Customers</mat-label>
        <input matInput [formControl]="searchConsumersCtrl" type="text" />
      </mat-form-field>

      <mat-divider></mat-divider>

    </div>
    <div>
      <mat-nav-list *ngIf="!newMessageSearch; else searchCustomers"
                    style="height: calc(100vh - 145px); overflow-y: scroll;"
                    [ngStyle]="{'height': openedFromCart ? 'calc(100vh - 70px)' : 'calc(100vh - 145px)' }"
                    role="list">
        <div *ngFor="let conversation of conversations; trackBy: trackByFn">
          <mat-list-item role="listitem"
                         [ngClass]="{ 'active-item': conversation.cell_phone === conversationConsumer?.cell_phone }"
                         (click)="onChatSelected(conversation)">
            <div class="chat-list-item">
              <span matLine [ngClass]="{ 'font-weight-700' : conversation.unread_message_count > 0 }">
                {{ getDisplayName(conversation) }} <small style="color: grey;">{{conversation.cell_phone | phone}}</small>
              </span>
              <span matLine [ngClass]="{ 'font-weight-700' : conversation.unread_message_count > 0 }">
                {{ conversation.last_message?.body }}
              </span>
            </div>
            <div class="chat-list-item-detail">
              <small *ngIf="conversation.last_message" class="mat-hint">
                {{ conversation.last_message_at | amTimeAgo }}
              </small>
              <span *ngIf="conversation.unread_message_count > 0"
                    style="display: block;"
                    [matBadge]="conversation.unread_message_count"
                    matBadgeOverlap="false"></span>
            </div>
          </mat-list-item>

          <mat-divider></mat-divider>

        </div>

        <div *ngIf="conversations?.length === 0"
             class="vertical-align text-xs-center"
             style="height: calc(100vh - 145px);">
          <div class="vertical-align-middle">
            <p class="font-size-18">
              No conversations found
            </p>
          </div>
        </div>
      </mat-nav-list>
    </div>
  </div>

  <div style="background-color: #F1F4F5; overflow-y: hidden;">
    <div *ngIf="!conversationConsumer" class="empty-container">
      <div class="text-xs-center">
        <mat-icon class="m-20" fontSet="fal" fontIcon="fa-paper-plane" [inline]="true"></mat-icon>
        <p class="font-size-18 p-10">
          {{ newMessageSearch
            ? 'Select a customer to start chatting with!'
            : 'No chats have been selected. Please select a chat from the list to start engaging with customers!'
          }}
        </p>
      </div>
    </div>
    <frontend-chat-message *ngIf="conversationConsumer"
                           [conversationConsumer]="conversationConsumer"
                           [selectedSubscriberMessages]="selectedConversationMessages"
                           [location]="location"
                           [accountDisabled]="accountDisabled"
                           [displayName]="getDisplayName(conversationConsumer)"
                           (chatArchived)="chatArchived()">
    </frontend-chat-message>
  </div>

  <div *ngIf="accountDisabled" class="account-disabled">
    <mat-divider></mat-divider>
    <div color="alert" class="error-message">
      Your messaging account is not setup. Please contact support.
    </div>
  </div>

</div>
<ng-template #searchCustomers>
  <frontend-consumer-list [consumerSearchInput]="consumerSearchInput"></frontend-consumer-list>
</ng-template>
