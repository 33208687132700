import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QzService } from '../../../service/qz.service';

export interface IQzPrinterSelectDialogData {
  qzService: QzService;
}

@Component({
  selector: 'frontend-printer-select-dialog',
  templateUrl: './printer-select-dialog.component.html',
  styleUrls: ['./printer-select-dialog.component.scss']
})
export class PrinterSelectDialogComponent implements OnInit {
  public loadingPrinters: boolean;
  public printers: string[];
  public printFormCtrl: FormControl;
  private qzService: QzService;

  constructor(@Inject(MAT_DIALOG_DATA) data: IQzPrinterSelectDialogData) {
    const { qzService } = data;
    this.qzService = qzService;
  }

  ngOnInit(): void {
    this.findPrinters();
    this.printFormCtrl = new FormControl();
  }

  findPrinters() {
    this.loadingPrinters = true;
    this.qzService
      .findPrinters()
      .then(printers => {
        this.loadingPrinters = false;
        this.printers = Array.isArray(printers) ? printers : [printers];

        this.qzService.getDefaultPrinter().then(defaultPrinter => {
          if (defaultPrinter && this.printers.includes(defaultPrinter)) {
            this.printFormCtrl.setValue(defaultPrinter);
          }
        });
      })
      .catch(err => {
        this.loadingPrinters = false;
        console.log(err);
      });
  }
}
