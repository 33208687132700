import { NgModule }         from '@angular/core';
import { ApplicationsEnum } from './applications.enum';
import { APPLICATION }      from './app.token';

@NgModule()
export class AppNameTokenProviderModule {
  static forRoot(application: ApplicationsEnum) {
    return {
      ngModule: AppNameTokenProviderModule,
      providers: [{ provide: APPLICATION, useValue: application }]
    };
  }
}
