import { Injectable, OnDestroy } from '@angular/core';
import { BasePaginationService } from './base-pagination.service';
import { ConsumerMessageInterface } from '../interface';
import { ConsumerMesssageApiService } from './consumer-message-api.service';

@Injectable()
export class ConsumerMessagePaginatorService extends BasePaginationService<ConsumerMessageInterface> implements OnDestroy {
  constructor(private api: ConsumerMesssageApiService) {
    super(api);
  }

  ngOnDestroy() {
    super.destroy();
  }
}
