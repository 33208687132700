import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SubNavigationTabsComponent } from './sub-navigation-tabs.component';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  imports: [CommonModule, RouterModule, PortalModule, MatTabsModule],
  declarations: [SubNavigationTabsComponent],
  exports: [SubNavigationTabsComponent, RouterModule]
})
export class SubNavigationTabsModule {}
