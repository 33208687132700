import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QzService } from '../../../service/qz.service';

export interface IQzConnectErrorDialogData {
  qzService: QzService;
}

@Component({
  selector: 'frontend-qz-connect-error-dialog',
  templateUrl: './qz-connect-error-dialog.component.html',
  styleUrls: ['./qz-connect-error-dialog.component.scss']
})
export class QzConnectErrorDialogComponent implements OnInit {
  public reconnecting: boolean;
  qzService: QzService;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: IQzConnectErrorDialogData,
    private dialogRef: MatDialogRef<QzConnectErrorDialogComponent, boolean>,
    private cdRef: ChangeDetectorRef
  ) {
    const { qzService } = data;
    this.qzService = qzService;
  }

  ngOnInit(): void {}

  onReconnect(): void {
    if (this.reconnecting) return;
    this.reconnecting = true;
    this.cdRef.detectChanges();
    this.qzService
      .initConnection()
      .then(() => {
        this.reconnecting = false;
        this.cdRef.detectChanges();
        this.dialogRef.close(true);
      })
      .catch(err => {
        this.reconnecting = false;
        this.cdRef.detectChanges();
      });
  }
}
