import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RetailStoreLocationInterface } from '@brand-menu/api/interface';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import {
  LicenseClassInterface,
  ListItemInterface,
  LocationDeliveryDataInterface,
  LocationInterface,
  PagyCollectionInterface,
  TypedEntityInterface
} from '../interface';

@Injectable({
  providedIn: 'root'
})
export class LocationApiService {
  constructor(private config: ApiConfig, private http: HttpClient) {}

  public me(): Observable<LocationInterface[]> {
    return this.http.get<LocationInterface[]>(`${this.config.baseUrl}/locations/me`);
  }

  public retailers(query: any): Observable<PagyCollectionInterface<RetailStoreLocationInterface>> {
    return this.http.get<PagyCollectionInterface<RetailStoreLocationInterface>>(
      `${this.config.baseUrl}/locations/retailers`,
      { params: query }
    );
  }

  public dropdown(): Observable<ListItemInterface[]> {
    return this.http.get<ListItemInterface[]>(`${this.config.baseUrl}/locations/dropdown`);
  }

  public deliveryData(): Observable<LocationDeliveryDataInterface> {
    return this.http.get<LocationDeliveryDataInterface>(`${this.config.baseUrl}/v2/location/delivery-data`);
  }

  public taxRemittableLocations(location_uuid?: string): Observable<TypedEntityInterface[]> {
    return this.http.get<TypedEntityInterface[]>(`${this.config.baseUrl}/v2/location/remit-tax-locations`, {
      params: { location_uuid }
    });
  }

  public hubs(): Observable<TypedEntityInterface[]> {
    return this.http.get<TypedEntityInterface[]>(`${this.config.baseUrl}/v2/location/hubs`);
  }

  public orderableConsumerLicenseClasses(location_uuid?: string): Observable<LicenseClassInterface[]> {
    return this.http.get<LicenseClassInterface[]>(`${this.config.baseUrl}/v2/location/orderable-consumer-licenses`, { params: { location_uuid }});
  }
}
