import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import {
  AuthChangeAccessRequestInterface,
  AuthContextInterface,
  AuthLoginRequestInterface,
} from '../interface';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/v2/session`;
  }

  public whoami(): Observable<AuthContextInterface> {
    return this.http.get<AuthContextInterface>(`${this.basePath}/whoami`);
  }

  public login(credentials: AuthLoginRequestInterface): Observable<AuthContextInterface> {
    return this.http.post<AuthContextInterface>(`${this.basePath}/login`, credentials);
  }

  public logout(): Observable<void> {
    return this.http.post<void>(`${this.basePath}/logout`, null);
  }

  public changeAccess(target: AuthChangeAccessRequestInterface): Observable<AuthContextInterface> {
    return this.http.patch<AuthContextInterface>(`${this.basePath}/change-access`, {
      target: {
        uuid: target.user_access_control_target.uuid,
        _type: target.user_access_control_target.type_id
      }
    });
  }
}
