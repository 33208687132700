import { Injectable } from '@angular/core';
import { AuthService } from '@myblackbird/auth';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedEntityInterface } from '@shared/model/entity/typed-entity.interface';
import { UploadModel } from '@shared/model/entity/upload/upload.model';
import { ErrorModel } from '@shared/model/error/error.model';
import { forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { LoyaltyRewardStatusEnum } from '../../../loyalty-reward/enumeration';
import { LoyaltyRewardParameters, LoyaltyRewardService } from '../../../loyalty-reward/service';
import { ConsumerModel, IConsumerData } from '../../model';
import { ConsumerService } from '../../service/consumer.service';
import * as actions from '../actions/consumer.actions';
import {
  ConsumerActions,
  ConsumerActionTypes,
  UpsertConsumerFailure,
  UpsertConsumerSuccess,
  UpsertRelatedConsumerData,
  UpsertRelatedConsumerDataFailure
} from '../actions/consumer.actions';

@Injectable()
export class ConsumerEffects {
  @Effect()
  loadCollection$ = this.actions$.pipe(
    ofType(ConsumerActionTypes.LoadConsumerCollection),
    map((action: actions.LoadConsumerCollection) => action.payload),
    switchMap(data => {
      const { parameters, preserveExistingRecords } = data;

      return this.consumerService.loadCollection(parameters).pipe(
        map(collection => new actions.LoadConsumerCollectionSuccess({ collection, preserveExistingRecords })),
        catchError(error => of(new actions.LoadConsumerCollectionFailure({ error })))
      );
    })
  );

  @Effect()
  loadConsumer$ = this.actions$.pipe(
    ofType(ConsumerActionTypes.LoadConsumer),
    map((action: actions.LoadConsumer) => action.payload),
    switchMap(data => {
      const { consumer, eagerLoadPendingRewards } = data;

      return this.consumerService.load(<IConsumerData>consumer).pipe(
        switchMap((loadedConsumer: IConsumerData) => {
          if (eagerLoadPendingRewards) {
            const loyaltyRewardParameters = new LoyaltyRewardParameters()
              .setLimit(100)
              .setWhereConsumer(loadedConsumer)
              .setWhereStatus(LoyaltyRewardStatusEnum.PENDING);
            return this.loyaltyRewardService.loadCollection(loyaltyRewardParameters).pipe(
              map(
                collection =>
                  new actions.LoadConsumerSuccess({
                    consumer: new ConsumerModel({
                      ...loadedConsumer,
                      loyalty_rewards: collection.records
                    })
                  })
              ),
              catchError(error =>
                of(
                  new actions.LoadConsumerSuccess({
                    consumer: new ConsumerModel({
                      ...loadedConsumer,
                      loyalty_rewards: []
                    })
                  })
                )
              )
            );
          }

          return of(new actions.LoadConsumerSuccess({ consumer: new ConsumerModel(loadedConsumer) }));
        }),
        catchError(error => of(new actions.LoadConsumerFailure({ error })))
      );
    })
  );

  @Effect()
  upsertConsumer$ = this.actions$.pipe(
    ofType(ConsumerActionTypes.UpsertConsumer),
    map((action: actions.UpsertConsumer) => action.payload),
    withLatestFrom(this.authService.currentUserAccessControl$),
    switchMap(([data, userAcl]) => {
      const { consumer, deliveryAddress, homeAddress, uploads } = data;

      const method = consumer.uuid ? 'update' : 'insert';

      return this.consumerService[method](
        consumer.uuid
          ? consumer
          : <IConsumerData>{
              ...consumer,
              location: { uuid: userAcl.target.uuid } as TypedEntityInterface
            }
      ).pipe(
        mergeMap((updatedConsumer: IConsumerData) => {
          const actionsQueue = [];
          actionsQueue.push(new UpsertConsumerSuccess({ consumer: new ConsumerModel(updatedConsumer) }));

          if (homeAddress || deliveryAddress || (uploads && uploads.length > 0))
            actionsQueue.push(
              new UpsertRelatedConsumerData({
                consumer,
                homeAddress,
                deliveryAddress,
                uploads
              })
            );

          return actionsQueue;
        }),
        catchError((error: ErrorModel) => of(new UpsertConsumerFailure({ error })))
      );
    })
  );

  @Effect()
  upsertCustomerAddress$ = this.actions$.pipe(
    ofType(ConsumerActionTypes.UpsertRelatedConsumerData),
    map((action: UpsertRelatedConsumerData) => action.payload),
    mergeMap(data => {
      let operations = [];
      let { consumer, deliveryAddress, homeAddress, uploads } = data;
      const addressService = this.consumerService.getAddressService(consumer);
      const uploadService = this.consumerService.getUploadService(consumer);

      if (deliveryAddress) {
        if (deliveryAddress.uuid) {
          operations.push(addressService.update(deliveryAddress, 'put'));
        } else {
          operations.push(addressService.insert(deliveryAddress));
        }
      }
      if (homeAddress) {
        if (homeAddress.uuid) {
          operations.push(addressService.update(homeAddress, 'put'));
        } else {
          operations.push(addressService.insert(homeAddress));
        }
      }
      if (uploads) {
        const uploadOperations = uploads.map(upload => uploadService.insert(<any>upload));
        operations = operations.concat(uploadOperations);
      }

      return forkJoin(operations).pipe(
        switchMap((data: any[]) => {
          if (deliveryAddress) {
            deliveryAddress = data[0];

            if (homeAddress) {
              homeAddress = data[1];
            } else {
              homeAddress = consumer.home_address;
            }
          } else if (homeAddress) {
            homeAddress = data[0];
            deliveryAddress = consumer.delivery_address;
          } else {
            deliveryAddress = consumer.delivery_address;
            homeAddress = consumer.home_address;
          }

          const updatedConsumerUploads = data.reduce((obj, item: UploadModel) => {
            if (item.meta_type) {
              obj[item.meta_type] = { uuid: item.uuid };
            }
            return obj;
          }, {});

          return this.consumerService
            .update(
              Object.assign(
                {},
                consumer,
                { delivery_address: deliveryAddress ? { uuid: deliveryAddress.uuid } : undefined },
                { home_address: homeAddress ? { uuid: homeAddress.uuid } : undefined },
                updatedConsumerUploads
              )
            )
            .pipe(
              map((updatedConsumer: IConsumerData) => {
                return new UpsertConsumerSuccess({ consumer: new ConsumerModel(updatedConsumer) })
              })
            );
        }),
        catchError((error: ErrorModel) => of(new UpsertRelatedConsumerDataFailure({ error })))
      );
    })
  );

  @Effect()
  removeConsumer$ = this.actions$.pipe(
    ofType(ConsumerActionTypes.DeleteConsumer),
    map((action: actions.DeleteConsumer) => action.payload),
    switchMap(data => {
      const { consumer } = data;

      return this.consumerService.delete(<IConsumerData>consumer).pipe(
        map(() => new actions.DeleteConsumerSuccess({ consumer })),
        catchError(error => of(new actions.DeleteConsumerFailure({ error })))
      );
    })
  );

  constructor(
    private actions$: Actions<ConsumerActions>,
    private store: Store<unknown>,
    private consumerService: ConsumerService,
    private loyaltyRewardService: LoyaltyRewardService,
    private authService: AuthService
  ) {}
}
