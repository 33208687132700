/**
 * THIS FILE IS AUTOGENERATED
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, State } from '../reducers/location.reducer';

export const LOCATION_KEY = 'LOCATION';

const getLocationState = createFeatureSelector<State>(LOCATION_KEY);

const { selectEntities } = adapter.getSelectors(getLocationState);

const getLoading = createSelector(getLocationState, (state: State) => state.loading);

const getUpdating = createSelector(getLocationState, (state: State) => state.updating);

const getError = createSelector(getLocationState, (state: State) => state.error);

const getCurrentPage = createSelector(getLocationState, (state: State) => state.currentPage);

const getTotalPages = createSelector(getLocationState, (state: State) => state.totalPages);

const getTotalRecords = createSelector(getLocationState, (state: State) => state.totalRecords);


const getPagination = createSelector(getTotalPages, getCurrentPage, getTotalRecords, (total, current, records) => ({
  totalPages: total,
  currentPage: current,
  totalRecords: records
}));


const getCanLoadMore = createSelector(getCurrentPage, getTotalPages, (current, total) => current < total);

const getSelectedId = createSelector(getLocationState, (state: State) => state.selected);

const getSelected = createSelector(selectEntities, getSelectedId, (entities, id) => {
  const result = entities[id];
  return result ? Object.assign({}, result) : undefined;
});

export const locationQuery = {
  ...adapter.getSelectors(getLocationState),
  getLoading,
  getUpdating,
  getError,
  getSelectedId,
  getSelected,
  getCurrentPage,
  getTotalPages,
  getTotalRecords,
  getPagination,
  getCanLoadMore
};
