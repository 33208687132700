import {
  HttpBackend,
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookiesService } from '@shared/cookie';
import { SessionService } from '@shared/entity/session/service';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '@myblackbird/auth';

const JWT_ACCESS_TOKEN = 'mybb-access-token';
const JWT_REFRESH_TOKEN = 'mybb-refresh-token';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private httpClient: HttpClient;

  constructor(
    private config: ApiConfig,
    private sessionService: SessionService,
    private cookiesService: CookiesService,
    private handler: HttpBackend,
    private authService: AuthService,
    private router: Router
  ) {
    this.httpClient = new HttpClient(handler);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes(this.config.baseUrl) && !request.url.includes(this.config.doloresUrl)) {
      return next.handle(request);
    }

    const token = this.cookiesService.get(JWT_ACCESS_TOKEN);
    const refreshToken = this.cookiesService.get(JWT_REFRESH_TOKEN);
    if (token) {
      request = this.addToken(request, token);
    }

    return next.handle(request).pipe(
      tap(response => {
        if (response instanceof HttpResponse && response.status === 200 && response.headers.get('Bearer')) {
          this.cookiesService.put(JWT_ACCESS_TOKEN, response.headers.get('Bearer'));
          if (response.url.includes('session') && ['POST', 'PATCH'].includes(request.method)) {
            this.cookiesService.put(JWT_REFRESH_TOKEN, response.headers.get('Bearer'));
          } else if (response.url.includes('session') && request.method === 'DELETE') {
            this.cookiesService.remove(JWT_ACCESS_TOKEN);
            this.cookiesService.remove(JWT_REFRESH_TOKEN);
          }
        }
      }),
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.cookiesService.remove(JWT_ACCESS_TOKEN);

          if (!refreshToken) {
            this.authService.removeState().subscribe(() => {
              this.router.navigate(['/auth'], { queryParams: { unauthorized: true } });
            });
            return throwError(error.error);
          }

          return this.httpClient
            .post(`${this.config.baseUrl}/session/refresh`, null, {
              headers: { 'Authorization': `Bearer ${refreshToken}` },
              observe: 'response'
            })
            .pipe(
              switchMap(res => {
                const newToken = res.headers.get('Bearer');
                this.cookiesService.put(JWT_ACCESS_TOKEN, newToken);
                this.cookiesService.put(JWT_REFRESH_TOKEN, newToken);
                return next.handle(this.addToken(request, newToken));
              }),
              catchError(responseError => {
                this.cookiesService.remove(JWT_REFRESH_TOKEN);
                this.router.navigate(['/auth'], { queryParams: { unauthorized: true } });
                return throwError(responseError.error);
              })
            );
        }

        return throwError(error.error);
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: { 'Authorization': `Bearer ${token}` }
    });
  }
}
