import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import { MenuBannerInterface } from '../interface';
import { MenuBannerListInterface } from '../interface/menu-banner-list.interface';
import { MenuBannerFormInterface } from '../interface/menu-banner-form.interface';

@Injectable({
  providedIn: 'root'
})
export class MenuBannerApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/v2/menu-banners`;
  }

  public listData(): Observable<MenuBannerListInterface> {
    return this.http.get<MenuBannerListInterface>(`${this.basePath}/list-data`);
  }

  public formData(uuid: string): Observable<MenuBannerFormInterface> {
    return this.http.get<MenuBannerFormInterface>(`${this.basePath}/form-data/${uuid ?? ''}`);
  }

  public upsert(request: MenuBannerInterface): Observable<MenuBannerInterface> {
    return this.http.patch<MenuBannerInterface>(`${this.config.baseUrl}/v2/menu-banners`, request);
  }

  public delete(uuid: string): Observable<void> {
    return this.http.delete<void>(`${this.basePath}`, { params: { uuid: uuid } });
  }

  public setOrder(uuids: string[]): Observable<void> {
    return this.http.post<void>(`${this.basePath}/set-order`, { uuids });
  }
}
