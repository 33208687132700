import {
  AnalyticsCartProductInterface,
  AnalyticsInterface,
  AnalyticsProductDetailInterface,
  AnalyticsProductImpressionInterface,
  AnalyticsPromoClickInterface,
  AnalyticsPromoImpressionInterface,
  AnalyticsSubmitCartInterface,
  WindowWithAnalytics
} from '../../interface/analytics-tracker.interface';
import {
  GA4EventAddToCart,
  GA4EventBeginCheckout,
  GA4EventPurchase,
  GA4EventViewItem,
  GA4EventViewItemList
} from '../../interface/ga4-events.interface';

// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag
export class GoogleAnalytics4 implements AnalyticsInterface {
  constructor(private trackingId: string) {
    this.initializeTracker();
  }

  initializeTracker() {
    this.initializeScript(this.trackingId);

    this.push('js', new Date());
    this.push('config', this.trackingId, { groups: 'dispensary',  cookie_flags: 'max-age=7200;secure;samesite=none' });
  }

  public trackPageView(page: string, title?: string): void {
    this.push('event', 'page_view', {
      page_location: window.location.href,
      page_title: title,
      send_to: 'dispensary'
    });
  }

  addProductImpressions(impressions: AnalyticsProductImpressionInterface[], listName?: string): void {
    const data: GA4EventViewItemList = {
      item_list_name: listName,
      items: impressions.map(impression => ({
        item_id: impression.product.uuid,
        item_name: impression.product.name,
        item_brand: impression.product.brand?.name,
        item_category: impression.product.product_category.name,
        price: impression.price,
        currency: 'USD',
        item_variant: impression.unit_label
      })),
      send_to: 'dispensary'
    };

    this.push('event', 'view_item_list', data);
  }

  addPromoImpressions(promos: AnalyticsPromoImpressionInterface[]): void {}

  addPromoClick(promo: AnalyticsPromoClickInterface): void {}

  viewProductDetail(product: AnalyticsProductDetailInterface): void {
    const data: GA4EventViewItem = {
      items: [
        {
          item_id: product.uuid,
          item_name: product.name,
          item_brand: product.brand?.name,
          item_category: product.product_category.name
        }
      ],
      send_to: 'dispensary'
    };

    this.push('event', 'view_item', data);
  }

  addToCart(products: AnalyticsCartProductInterface | AnalyticsCartProductInterface[]): void {
    products = Array.isArray(products) ? products : [products];

    const data: GA4EventAddToCart = {
      items: products.map(item => ({
        currency: 'USD',
        item_id: item.inventory.product.uuid,
        item_name: item.inventory.product.name,
        item_brand: item.inventory.product.brand ? (item.inventory.product.brand.name ?? (<any>item.inventory.product.brand)?._name) : null,
        item_category: item.inventory.product.category.name,
        item_variant: item.inventory.unit_label,
        quantity: item.quantity,
        price: item.subtotal
      })),
      send_to: 'dispensary'
    };

    this.push('event', 'add_to_cart', data);
  }

  removeFromCart(products: AnalyticsCartProductInterface | AnalyticsCartProductInterface[]): void {
    products = Array.isArray(products) ? products : [products];

    const data: GA4EventAddToCart = {
      items: products.map(item => ({
        currency: 'USD',
        item_id: item.inventory.product.uuid,
        item_name: item.inventory.product.name,
        item_brand: item.inventory.product.brand?.name,
        item_category: item.inventory.product.category.name,
        item_variant: item.inventory.unit_label,
        quantity: item.quantity,
        price: item.subtotal
      })),
      send_to: 'dispensary'
    };

    this.push('event', 'remove_from_cart', data);
  }

  checkout(cartProducts: AnalyticsCartProductInterface[]): void {
    const data: GA4EventBeginCheckout = {
      currency: 'USD',
      value: cartProducts.reduce((agg, item) => agg + item.subtotal, 0),
      items: cartProducts.map(cartProduct => ({
        currency: 'USD',
        item_id: cartProduct.inventory.product.uuid,
        item_name: cartProduct.inventory.product.name,
        item_brand: cartProduct.inventory.product.brand?.name,
        item_category: cartProduct.inventory.product.product_category.name,
        item_variant: cartProduct.inventory.unit_label,
        quantity: cartProduct.quantity,
        price: cartProduct.subtotal
      })),
      send_to: 'dispensary'
    };

    this.push('event', 'begin_checkout', data);
  }

  purchase(order: AnalyticsSubmitCartInterface): void {
    const data: GA4EventPurchase = {
      currency: 'USD',
      transaction_id: order.uuid,
      value: order.total,
      tax: order.tax_total,
      items: order.products.map(item => ({
        currency: 'USD',
        item_id: item.uuid,
        item_name: item.name,
        item_brand: item.brand ? ('name' in item.brand ? item.brand.name : item.brand._name) : null,
        item_category: 'name' in item.category ? item.category.name : item.category._name,
        item_variant: item.unit_label,
        quantity: item.quantity,
        price: item.price_each
      })),
      send_to: 'dispensary'
    };

    this.push('event', 'purchase', data);
  }

  public identifyUser(id: string) {
    this.push('set', { user_id: id, send_to: 'dispensary' });
  }

  protected push(...args: any[]) {
    if (!window) return;
    (window as WindowWithAnalytics)['dataLayer'].push(arguments);
  }

  private initializeScript(id: string, queryParams: { [key: string]: string } = {}) {
    let url = `https://www.googletagmanager.com/gtag/js?id=${id}`;
    let optionKeys = Object.keys(queryParams).filter(key => !!queryParams[key]);

    if (optionKeys.length) {
      let params = optionKeys.map(key => `${key}=${queryParams[key]}`);
      url = url + `&${params.join('&')}`;
    }

    let script: HTMLScriptElement = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.async = true;

    document.getElementsByTagName('head')[0].appendChild(script);

    (window as WindowWithAnalytics)['dataLayer'] =  (window as WindowWithAnalytics)['dataLayer'] || [];
  }
}
