import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MsVariableListComponent } from './ms-variable-list.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  imports: [CommonModule, MatChipsModule, MatFormFieldModule],
  declarations: [MsVariableListComponent],
  exports: [MsVariableListComponent]
})
export class MsVariableModule {
}
