<mat-progress-bar *ngIf="loadingConsumers" mode="indeterminate"></mat-progress-bar>
<div style="height: calc(100vh - 150px); overflow-y: scroll;" cdkScrollable>
  <mat-nav-list role="list">
    <div *ngFor="let consumer of consumers; trackBy: trackByConsumerFn">
      <mat-list-item role="listitem" (click)="onConsumerSelected(consumer)"
        [ngClass]="{ 'active-item': consumer.cell_phone === selectedConversationUri && selectedConversationUri !== null }">
        <div class="chat-list-item">
          <span matLine>{{ consumer._name }}</span>
          <span matLine>{{ consumer.cell_phone | obfuscatephone }}
          </span>
        </div>
      </mat-list-item>
      <mat-divider></mat-divider>
    </div>
  </mat-nav-list>

  <div *ngIf="consumers?.length === 0" class="vertical-align text-xs-center" style="height: calc(100vh - 145px);">
      <div class="vertical-align-middle">
        <p class="font-size-18">
          No customers found
        </p>
      </div>
    </div>
</div>
