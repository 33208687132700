import { Injectable, OnDestroy } from '@angular/core';
import { BasePaginationService } from './base-pagination.service';
import { LoyaltyMultiplierInterface } from '../interface';
import { LoyaltyMultiplierApiService } from './loyalty-multiplier-api.service';

@Injectable()
export class LoyaltyMultiplierPaginatorService extends BasePaginationService<LoyaltyMultiplierInterface> implements OnDestroy {
  constructor(private api: LoyaltyMultiplierApiService) {
    super(api);
  }

  ngOnDestroy() {
    super.destroy();
  }
}
