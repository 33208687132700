import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { InventoryDataInterface } from '../../model/inventory.model';
import { InventoryService } from '../../service/inventory.service';
import * as actions from '../actions/inventory.actions';
import { InventoryActions, InventoryActionTypes } from '../actions/inventory.actions';
import { ToasterService }                        from 'angular2-toaster';

@Injectable()
export class InventoryEffects {
  @Effect()
  loadCollection$ = this.actions$.pipe(
    ofType(InventoryActionTypes.LoadInventoryCollection),
    map((action: actions.LoadInventoryCollection) => action.payload),
    switchMap(data => {
      const { parameters, preserveExistingRecords } = data;

      return this.inventoryService.loadCollection(parameters).pipe(
        map(collection => new actions.LoadInventoryCollectionSuccess({ collection, preserveExistingRecords })),
        catchError(error => of(new actions.LoadInventoryCollectionFailure({ error })))
      );
    })
  );

  @Effect()
  loadInventory$ = this.actions$.pipe(
    ofType(InventoryActionTypes.LoadInventory),
    map((action: actions.LoadInventory) => action.payload),
    switchMap(data => {
      const { inventory } = data;

      return this.inventoryService.load(inventory).pipe(
        map((inventory: InventoryDataInterface) => new actions.LoadInventorySuccess({ inventory })),
        catchError(error => of(new actions.LoadInventoryFailure({ error })))
      );
    })
  );

  @Effect()
  upsertInventory$ = this.actions$.pipe(
    ofType(InventoryActionTypes.UpsertInventory),
    map((action: actions.UpsertInventory) => action.payload),
    switchMap(data => {
      const { inventory } = data;

      return this.inventoryService.upsert(inventory).pipe(
        map((inventory: InventoryDataInterface) => new actions.UpsertInventorySuccess({ inventory })),
        catchError(error => of(new actions.UpsertInventoryFailure({ error })))
      );
    })
  );

  @Effect()
  removeInventory$ = this.actions$.pipe(
    ofType(InventoryActionTypes.DeleteInventory),
    map((action: actions.DeleteInventory) => action.payload),
    switchMap(data => {
      const { inventory } = data;

      return this.inventoryService.delete(inventory).pipe(
        map((inventory: InventoryDataInterface) => new actions.DeleteInventorySuccess({ inventory })),
        catchError(error => of(new actions.DeleteInventoryFailure({ error })))
      );
    })
  );

  @Effect()
  destroyAndArchiveProductInventory$ = this.actions$.pipe(
    ofType(InventoryActionTypes.DestroyAndArchiveProductInventory),
    map((action: actions.DestroyAndArchiveProductInventory) => action.payload),
    switchMap(data => {
      const { inventory } = data;
      return of(...inventory).pipe(
        concatMap(operation => this.inventoryService.update(operation)),
        map((inventory: InventoryDataInterface) => {
          this.toaster.pop('success', 'Inventory Archived', inventory.name + ' ' + inventory.attribute_name);
          return new actions.DestroyAndArchiveProductInventorySuccess({ inventory });
        }),
        catchError(error => {
          this.toaster.pop('error', 'Cannot Archive Inventory', error.error);
          return of(new actions.DestroyAndArchiveProductInventoryFailure({ error }));
        })
      );
    })
  );

  constructor(
    private actions$: Actions<InventoryActions>,
    private inventoryService: InventoryService,
    private toaster: ToasterService
  ) {}
}
