import { TypesEnum } from '@shared/enumeration/type/types.enum';

export enum DiscountTargetEnum {
  PRODUCT = TypesEnum.PRODUCTS,
  INVENTORY = TypesEnum.INVENTORY,
  BRAND = TypesEnum.BRANDS,
  PRODUCT_CATEGORIES = TypesEnum.PRODUCT_CATEGORIES,
  ORDER = TypesEnum.ORDERS,
  CONSUMER = TypesEnum.CONSUMERS
}

const ProductDiscountObject = {
  id: DiscountTargetEnum.PRODUCT,
  name: 'Product'
};

const InventoryDiscountObject = {
  id: DiscountTargetEnum.INVENTORY,
  name: 'Inventory'
};

const BrandDiscountObject = {
  id: DiscountTargetEnum.BRAND,
  name: 'Brand'
};

const CategoryDiscountObject = {
  id: DiscountTargetEnum.PRODUCT_CATEGORIES,
  name: 'Product Category'
};

const MiscellaneousDiscountObject = {
  id: 0,
  name: 'Miscellaneous'
};

const OrderDiscountObject = {
  id: DiscountTargetEnum.ORDER,
  name: 'Order'
};

const LocationConsumerDiscountObject = {
  id: DiscountTargetEnum.CONSUMER,
  name: 'Customer'
};

export const RewardTargets = [
  OrderDiscountObject, ProductDiscountObject, BrandDiscountObject, CategoryDiscountObject, MiscellaneousDiscountObject
];

export const MyBlackbirdDiscountTargets = [
  OrderDiscountObject,
  BrandDiscountObject,
  CategoryDiscountObject,
  ProductDiscountObject,
  InventoryDiscountObject
];
