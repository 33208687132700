/**
 * THIS FILE IS AUTOGENERATED
 */

import { Action } from '@ngrx/store';
import { CollectionInterface } from '@shared/model/collection/collection.interface';
import { ErrorModel } from '@shared/model/error/error.model';
import { OrderModel } from '../../model/order.model';
import { OrderParameters } from '../../service/order.parameters';
import { TransactionModel } from '../../model';

export enum OrderActionTypes {
  ClearOrderState = '[Order] Clear Order State',

  LoadOrderCollection = '[Order] Load Order Collection',
  LoadOrderCollectionSuccess = '[Order] Load Order Collection Success',
  LoadOrderCollectionFailure = '[Order] Load Order Collection Failure',

  LoadOrder = '[Order] Load  Order',
  LoadOrderSuccess = '[Order] Load Order Success',
  LoadOrderFailure = '[Order] Load Order Failure',

  UpsertOrder = '[Order] Upsert Order',
  UpsertOrderSuccess = '[Order] Upsert Order Success',
  UpsertOrderFailure = '[Order] Upsert Order Failure',

  DeleteOrder = '[Order] Delete  Order',
  DeleteOrderSuccess = '[Order] Delete Order Success',
  DeleteOrderFailure = '[Order] Delete Order Failure',

  DeletePreauthorizedTransaction = '[Order] Delete Preauthorized Transaction',
  DeletePreauthorizedTransactionSuccess = '[Order] Preauthorized Transaction Success',
  DeletePreauthorizedTransactionFailure = '[Order] Preauthorized Transaction Failure'
}

export class ClearOrderState implements Action {
  readonly type = OrderActionTypes.ClearOrderState;
}

export class LoadOrderCollection implements Action {
  readonly type = OrderActionTypes.LoadOrderCollection;

  constructor(public payload: { parameters: OrderParameters; preserveExistingRecords?: boolean }) {
  }
}

export class LoadOrderCollectionSuccess implements Action {
  readonly type = OrderActionTypes.LoadOrderCollectionSuccess;

  constructor(public payload: { collection: CollectionInterface<OrderModel>; preserveExistingRecords?: boolean }) {
  }
}

export class LoadOrderCollectionFailure implements Action {
  readonly type = OrderActionTypes.LoadOrderCollectionFailure;

  constructor(public payload: { error: ErrorModel }) {
  }
}

export class LoadOrder implements Action {
  readonly type = OrderActionTypes.LoadOrder;

  constructor(public payload: { order: OrderModel; eagerLoadCustomer?: boolean }) {
  }
}

export class LoadOrderSuccess implements Action {
  readonly type = OrderActionTypes.LoadOrderSuccess;

  constructor(public payload: { order: OrderModel }) {
  }
}

export class LoadOrderFailure implements Action {
  readonly type = OrderActionTypes.LoadOrderFailure;

  constructor(public payload: { error: ErrorModel }) {
  }
}

export class UpsertOrder implements Action {
  readonly type = OrderActionTypes.UpsertOrder;

  constructor(public payload: { order: OrderModel; eagerLoadCustomer?: boolean }) {
  }
}

export class UpsertOrderSuccess implements Action {
  readonly type = OrderActionTypes.UpsertOrderSuccess;

  constructor(public payload: { order: OrderModel }) {
  }
}

export class UpsertOrderFailure implements Action {
  readonly type = OrderActionTypes.UpsertOrderFailure;

  constructor(public payload: { error: ErrorModel }) {
  }
}

export class DeleteOrder implements Action {
  readonly type = OrderActionTypes.DeleteOrder;

  constructor(public payload: { records: OrderModel | OrderModel[] }) {
  }
}

export class DeleteOrderSuccess implements Action {
  readonly type = OrderActionTypes.DeleteOrderSuccess;

  constructor(public payload: { records: OrderModel | OrderModel[] }) {
  }
}

export class DeleteOrderFailure implements Action {
  readonly type = OrderActionTypes.DeleteOrderFailure;

  constructor(public payload: { error: ErrorModel | ErrorModel[] }) {
  }
}

export class DeletePreauthorizedTransaction implements Action {
  readonly type = OrderActionTypes.DeletePreauthorizedTransaction;

  constructor(public payload: { order: OrderModel, transaction: TransactionModel }) {
  }
}

export class DeletePreauthorizedTransactionSuccess implements Action {
  readonly type = OrderActionTypes.DeletePreauthorizedTransactionSuccess;

  constructor() {
  }
}

export class DeletePreauthorizedTransactionFailure implements Action {
  readonly type = OrderActionTypes.DeletePreauthorizedTransactionFailure;

  constructor(public payload: any) {
  }
}

export type OrderActions =
  | ClearOrderState
  | LoadOrderCollection
  | LoadOrderCollectionSuccess
  | LoadOrderCollectionFailure
  | LoadOrder
  | LoadOrderSuccess
  | LoadOrderFailure
  | UpsertOrder
  | UpsertOrderSuccess
  | UpsertOrderFailure
  | DeleteOrder
  | DeleteOrderSuccess
  | DeleteOrderFailure
  | DeletePreauthorizedTransaction
  | DeletePreauthorizedTransactionSuccess
  | DeletePreauthorizedTransactionFailure;
