import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import { CampaignRecipientConditionFormInterface, CampaignRecipientFormInterface } from '../interface';

export interface RecipientFormPageDataInterface {
  query_form: CampaignRecipientFormInterface,
  query_conditions: CampaignRecipientConditionFormInterface[]
}

@Injectable({ providedIn: 'root' })
export class CampaignRecipientFormApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/campaign-recipient-lists/form`;
  }

  public loadPageData(uuid: string): Observable<RecipientFormPageDataInterface> {
    return this.http.get<RecipientFormPageDataInterface>(`${this.basePath}/page-data`, { params: { uuid: uuid || '' } });
  }

  public upsertQuery(params: any): Observable<CampaignRecipientFormInterface> {
    return this.http.post<CampaignRecipientFormInterface>(`${this.basePath}`, params);
  }

  public upsertCondition(params: any): Observable<CampaignRecipientConditionFormInterface> {
    return this.http.post<CampaignRecipientConditionFormInterface>(`${this.basePath}/conditions`, params);
  }

  public deleteCondition(uuid: string): Observable<void> {
    return this.http.delete<void>(`${this.basePath}/conditions/${uuid}`);
  }

  public updateLogicalOperator(query_uuid: string, value: string): Observable<void> {
    return this.http.post<void>(`${this.basePath}/conditions/logical-operator`, { query_uuid, value });
  }
}
