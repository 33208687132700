import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, State } from '../reducers/inventory.reducer';

export const INVENTORY_KEY = 'INVENTORY';

const getInventoryState = createFeatureSelector<State>(INVENTORY_KEY);

const { selectEntities } = adapter.getSelectors(getInventoryState);

const getLoading = createSelector(
  getInventoryState,
  (state: State) => state.loading
);

const getUpdating = createSelector(
  getInventoryState,
  (state: State) => state.updating
);

const getError = createSelector(
  getInventoryState,
  (state: State) => state.error
);

const getCurrentPage = createSelector(
  getInventoryState,
  (state: State) => state.currentPage
);

const getTotalPages = createSelector(
  getInventoryState,
  (state: State) => state.totalPages
);

const getTotalRecords = createSelector(
  getInventoryState,
  (state: State) => state.totalRecords
);

const getPagination = createSelector(
  getTotalPages,
  getCurrentPage,
  getTotalRecords,
  (total, current, records) => ({
    totalPages: total,
    currentPage: current,
    totalRecords: records
  })
);

const getCanLoadMore = createSelector(
  getCurrentPage,
  getTotalPages,
  (current, total) => current < total
);

const getSelectedId = createSelector(
  getInventoryState,
  (state: State) => state.selected
);

const getSelected = createSelector(
  selectEntities,
  getSelectedId,
  (entities, id) => {
    const result = entities[id];
    return result ? Object.assign({}, result) : undefined;
  }
);

export const inventoryQuery = {
  ...adapter.getSelectors(getInventoryState),
  getLoading,
  getUpdating,
  getError,
  getSelectedId,
  getSelected,
  getCurrentPage,
  getTotalPages,
  getTotalRecords,
  getPagination,
  getCanLoadMore
};
