import { Injectable, OnDestroy } from '@angular/core';
import { BasePaginationService } from './base-pagination.service';
import { ProductInterface } from '../interface';
import { ProductApiService } from './product-api.service';

@Injectable()
export class ProductPaginatorService extends BasePaginationService<ProductInterface> implements OnDestroy {
  constructor(private api: ProductApiService) {
    super(api);
  }

  ngOnDestroy() {
    super.destroy();
  }
}
