export enum FrontendPolicyRolesEnum {
  RETAIL_TELLER = 'retail_teller',
  RETAIL_MANAGER = 'retail_manager',
  RETAIL_ADMIN = 'retail_admin',

  WHOLESALE_TELLER = 'wholesale_teller',
  WHOLESALE_MANAGER = 'wholesale_manager',
  WHOLESALE_ADMIN = 'wholesale_admin',

  ORGANIZATION_ADMIN = 'organization_admin',

  BLACKBIRD_DRIVER = 'blackbird_driver',
  BLACKBIRD_DISPATCHER = 'blackbird_dispatcher',

  BAKER = 'baker'
}
