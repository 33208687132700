import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import { ListItemInterface } from '../interface';

@Injectable({ providedIn: 'root' })
export class CheckinDrawingApiService {

  constructor(private config: ApiConfig, private http: HttpClient) {
  }

  public dropdown(): Observable<ListItemInterface[]> {
    return this.http.get<ListItemInterface[]>(`${this.config.baseUrl}/checkin-drawings/dropdown`);
  }
}
