import { CommonModule }                                                                      from '@angular/common';
import { NgModule }                                                                          from '@angular/core';
import { FormsModule }                                                                       from '@angular/forms';
import { ToasterModule }                                                                     from 'angular2-toaster';
import { MomentModule }                                                                      from 'ngx-moment';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MomentModule,
    ToasterModule,
    PerfectScrollbarModule
  ],
  exports: [
    MomentModule,
    FormsModule,
    CommonModule,
    ToasterModule,
    PerfectScrollbarModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]

})

export class SharedModModule {
}
