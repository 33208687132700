import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import { ListItemInterface, PagyCollectionInterface, StationInterface, TypedEntityInterface } from '../interface';

export interface StationFormDataInterface {
  locations: TypedEntityInterface[];
  station: StationInterface | null;
}

@Injectable({ providedIn: 'root' })
export class StationApiService {
  private basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/v2/stations`;
  }

  public list(params: any): Observable<PagyCollectionInterface<StationInterface>> {
    return this.http.get<PagyCollectionInterface<StationInterface>>(`${this.basePath}`, { params });
  }

  public formData(uuid: string): Observable<StationFormDataInterface> {
    return this.http.get<StationFormDataInterface>(`${this.basePath}/form-data`, { params: { uuid } });
  }

  public upsert(params: any): Observable<StationInterface> {
    return this.http.post<StationInterface>(`${this.basePath}`, params);
  }

  public delete(uuid: string): Observable<void> {
    return this.http.delete<void>(`${this.basePath}/${uuid}`);
  }

  public dropdown(): Observable<ListItemInterface[]> {
    return this.http.get<ListItemInterface[]>(`${this.basePath}/dropdown`);
  }
}
