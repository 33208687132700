import { AbstractPolicy } from '../abstract.policy';
import { PolicyPermissions } from '../policy.permissions';

export class BlackbirdAddonPolicy extends AbstractPolicy {

  protected tellerPermissions = [
    PolicyPermissions.READ_DELIVERY,
  ];

  protected managerPermissions = [
    ...this.tellerPermissions
  ];

  protected adminPermissions = [
    ...this.managerPermissions,
    PolicyPermissions.READ_EMPLOYEE,
    PolicyPermissions.WRITE_EMPLOYEE
  ];

  protected dispatcherPermissions = [
    ...this.adminPermissions,
    // PolicyPermissions.VIEW_BILLING,
    PolicyPermissions.ADJUST_INVENTORY_SHIPPING_ZONE,
    PolicyPermissions.ADJUST_INVENTORY_QUARANTINE_ZONE,
    PolicyPermissions.VIEW_DISPATCH
  ];

  protected driverPermissions = [
    PolicyPermissions.READ_DELIVERY
  ]
}
