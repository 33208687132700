import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import { LoyaltyRedemptionInterface } from '../interface';

@Injectable({
  providedIn: 'root'
})
export class OrderApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/v2/orders`;
  }

  public addReward(order_uuid: string, reward_uuid: string): Observable<LoyaltyRedemptionInterface> {
    return this.http.post<LoyaltyRedemptionInterface>(`${this.basePath}/add-reward`, { order_uuid, reward_uuid });
  }
}
