import { AbstractPolicy } from '../abstract.policy';

export class LocationPolicy extends AbstractPolicy {

  protected tellerPermissions = [];

  protected managerPermissions = [
    ...this.tellerPermissions
  ];

  protected adminPermissions = [
    ...this.managerPermissions
  ];
}
