import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { LoyaltyTransactionInterface, PagyCollectionInterface } from '../interface';

@Injectable({
  providedIn: 'root'
})
export class LoyaltyTransactionApiService {
  private basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/v2/loyalty-transactions`;
  }

  public list(params: any): Observable<PagyCollectionInterface<LoyaltyTransactionInterface>> {
    return this.http.get<PagyCollectionInterface<LoyaltyTransactionInterface>>(`${this.basePath}`, { params });
  }

  public adjustPoints(params: { consumer_uuid: string, points: number, notes: string }): Observable<LoyaltyTransactionInterface> {
    return this.http.post<LoyaltyTransactionInterface>(`${this.basePath}/adjust-points`, params);
  }
}
