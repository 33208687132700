import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import {
  CreateMenuSectionRequest,
  MenuSectionDetailInterface,
  MenuSectionInterface,
  UpdateMenuSectionRequest
} from '../interface';

@Injectable({
  providedIn: 'root'
})
export class MenuSectionApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/v2/menu/section`;
  }

  public all(): Observable<MenuSectionInterface[]> {
    return this.http.get<MenuSectionInterface[]>(`${this.basePath}/all`);
  }

  public setOrder(uuids: string[]): Observable<MenuSectionInterface[]> {
    return this.http.post<MenuSectionInterface[]>(`${this.basePath}/set-order`, { uuids });
  }

  public get<T>(uuid: string): Observable<MenuSectionDetailInterface<T>> {
    return this.http.get<MenuSectionDetailInterface<T>>(`${this.basePath}/${uuid}`);
  }

  public create<T>(request: CreateMenuSectionRequest): Observable<MenuSectionDetailInterface<T>> {
    return this.http.post<MenuSectionDetailInterface<T>>(`${this.basePath}/`, request);
  }

  public update<T>(request: UpdateMenuSectionRequest): Observable<MenuSectionDetailInterface<T>> {
    return this.http.patch<MenuSectionDetailInterface<T>>(`${this.basePath}/${request.uuid}`, request);
  }

  public upsert<T>(request: UpdateMenuSectionRequest): Observable<MenuSectionDetailInterface<T>> {
    if (request.uuid) {
      return this.update(request);
    }
    return this.create(request);
  }

  public delete(uuid: string): Observable<void> {
    return this.http.delete<void>(`${this.basePath}`, { params: { uuid: uuid } });
  }
}
