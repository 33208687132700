import { ConsumerInterestModel as LocationConsumerInterestModel } from '@shared/entity/consumer-interest/model';
import { QueryInterface } from '@frontend/state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, State } from './location-consumer-interest.reducer';

const getLocationConsumerInterestState = createFeatureSelector<State>('location-consumer-interest');

const { selectEntities } = adapter.getSelectors(getLocationConsumerInterestState);

const getLoading = createSelector(
  getLocationConsumerInterestState,
  (state: State) => state.loading
);

const getUpdating = createSelector(
  getLocationConsumerInterestState,
  (state: State) => state.updating
);

const getError = createSelector(
  getLocationConsumerInterestState,
  (state: State) => state.error
);

const getCurrentPage = createSelector(
  getLocationConsumerInterestState,
  (state: State) => state.currentPage
);

const getTotalPages = createSelector(
  getLocationConsumerInterestState,
  (state: State) => state.totalPages
);

const getTotalRecords = createSelector(
  getLocationConsumerInterestState,
  (state: State) => state.totalRecords
);

const getPagination = createSelector(
  getTotalPages,
  getCurrentPage,
  getTotalRecords,
  (total, current, records) => ({
    totalPages: total,
    currentPage: current,
    totalRecords: records
  })
);

const getCanLoadMore = createSelector(
  getCurrentPage,
  getTotalPages,
  (current, total) => current < total
);

const getSelectedId = createSelector(
  getLocationConsumerInterestState,
  (state: State) => state.selected
);

const getSelected = createSelector(
  selectEntities,
  getSelectedId,
  (entities, id) => {
    const result = entities[id];
    return result ? Object.assign({}, result) : undefined;
  }
);

export const locationConsumerInterestQuery: QueryInterface<LocationConsumerInterestModel> = {
  ...adapter.getSelectors(getLocationConsumerInterestState),
  getLoading,
  getUpdating,
  getError,
  getSelectedId,
  getSelected,
  getCurrentPage,
  getTotalPages,
  getTotalRecords,
  getPagination,
  getCanLoadMore
};
