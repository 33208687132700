import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@shared/model/config/api/api-config.model';
import { Observable } from 'rxjs';
import {
  LoyaltyMultiplierInterface,
  LoyaltyProgramInterface,
  PagyCollectionInterface,
  TypedEntityInterface
} from '../interface';

interface PageDataInterface {
  canEdit: boolean;
  loyalty_program: LoyaltyProgramInterface | null;
}

interface FormPageDataInterface {
  loyalty_multiplier: LoyaltyMultiplierInterface | null;
  locations: TypedEntityInterface[];
}

@Injectable({
  providedIn: 'root'
})
export class LoyaltyMultiplierApiService {
  private readonly basePath: string;

  constructor(private config: ApiConfig, private http: HttpClient) {
    this.basePath = `${this.config.baseUrl}/v2/loyalty-multipliers`;
  }

  public list(params: any): Observable<PagyCollectionInterface<LoyaltyMultiplierInterface>> {
    return this.http.get<PagyCollectionInterface<LoyaltyMultiplierInterface>>(`${this.basePath}`, { params });
  }

  pageData(): Observable<PageDataInterface> {
    return this.http.get<PageDataInterface>(`${this.basePath}/page-data`);
  }

  formData(uuid: string | null): Observable<FormPageDataInterface> {
    return this.http.get<FormPageDataInterface>(`${this.basePath}/form-data`, { params: { uuid } });
  }

  public upsert(params: any): Observable<LoyaltyMultiplierInterface> {
    return this.http.post<LoyaltyMultiplierInterface>(`${this.basePath}`, params);
  }

  public delete(uuid: string): Observable<void> {
    return this.http.delete<void>(`${this.basePath}/${uuid}`);
  }
}
